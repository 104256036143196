import React, { Component, createRef, useRef } from "react"
import Navbar from "../navbar/Navbar"
import DefaultMeta from "../seo/DefaultMeta"
import '../../pages/search/search.css'
import './professional.css'
import { Chip } from 'primereact/chip';
import AdSkeleton from "../../components/skeleton/AdSkeleton";
import { Paginator } from "primereact/paginator"
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { Link } from "react-router-dom"
import Card from "../card/Card"
import { Constant } from "../../helpers/const"
import mapboxgl from "mapbox-gl"
import '../../pages/item-details/item.css'
import { Rating } from "primereact/rating"
import { Toast } from 'primereact/toast';
import Avis from "../avis/Avis"

class Professional extends Component {
    constructor(props) {
        super(props)
        this.pageSize = 8;
        this.state = {
            title: null,
            address: null,
            position: null,
            subcategory: null,
            category: null,
            sortFilter: "creationDateDesc",
            categories: [],
            first: 0,
            page: 0,
            totalRecords: 0,
            elements: [],
            loading: false,
            value: '1',
            valeur: '1',
            activeSection: null,
            val: 3,
            contact: false,
            mail: '',
            commentaire: '',
            nom: '',
            shownum: false,
            showchat: false,
            follow: false

        };

        this.mapContainer = React.createRef();
        this.mapContainer = React.createRef();
        this.onPage = this.onPage.bind(this);
    }
    elemnt = [
        {
            slug: 'adf',
            images: [
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' }
            ],
            address: {
                city: 'maroua'
            },
            title: 'xcv',
            createdOn: new Date('12-01-2020')
        },
        {
            slug: 'ravii',
            images: [
                { url: 'https://picsum.photos/500/351' },
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' }
            ],
            address: {
                city: 'maroua'
            },
            title: 'rcv',
            createdOn: new Date('12-01-2010')
        },
        {
            slug: 'adf',
            images: [
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' }
            ],
            address: {
                city: 'maroua'
            },
            title: 'tcv',
            createdOn: new Date('12-01-2023')
        }
        , {
            slug: 'adf',
            images: [
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' }
            ],
            address: {
                city: 'maroua'
            },
            title: 'xrv',
            createdOn: new Date('12-01-2003')
        },
        {
            slug: 'adf',
            images: [
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' }
            ],
            address: {
                city: 'maroua'
            },
            title: 'gcv',
            createdOn: new Date('12-01-2004')
        },
        {
            slug: 'adf',
            images: [
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' }
            ],
            address: {
                city: 'maroua'
            },
            title: 'hcv',
            createdOn: new Date('12-01-2005')
        },
        {
            slug: 'adf',
            images: [
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' }
            ],
            address: {
                city: 'maroua'
            },
            title: 'jcv',
            createdOn: new Date('12-01-2011')
        },
        {
            slug: 'adf',
            images: [
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' }
            ],
            address: {
                city: 'maroua'
            },
            title: 'bcv',
            createdOn: new Date('11-01-2010')
        }
    ]
    items = [{
        label: 'Infos',
        to: 'infos'
    },
    {
        label: 'Annonces',
        to: 'annonces'
    },
    {
        label: 'Photos',
        to: 'photos'
    }, {
        label: 'Avis',
        to: 'avis'
    }
    ]
    exple = [{
        label: 'Reprise de véhicules'
    },
    {
        label: 'Dépôt-Vente'
    },
    {
        label: 'Vente de pièces détachées'
    },
    {
        label: 'Atelier d’entretien'
    },
    {
        label: 'Vente de véhicules'
    },
    {
        label: 'Livraison à domicile'
    },
    {
        label: 'Ventes d’accessoires'
    }
    ]
    showMail = () => {
        this.setState({ shownum: true })
        this.setState({ contact: false })
        this.setState({ showchat: false })
    }
    showContact = () => {
        this.setState({ shownum: false })
        this.setState({ contact: true })
        this.setState({ showchat: false })

    }
    showChat = () => {
        this.setState({ shownum: false })
        this.setState({ contact: false })
        this.setState({ showchat: true })
    }
    componentDidMount() {
        this.setState({ loading: true });
        let page = new URLSearchParams(this.props.location.search).get("page");
        let p = this.state.page;
        if (page != null && page > 0) {
            this.setState({
                first: (page - 1) * this.pageSize,
                page: (page - 1),
            });
            p = page - 1;
        }
        const params = new URLSearchParams({
            start: p,
            size: this.pageSize,
            sortFilter: this.state.sortFilter
        });
        this.setState({
            elements: this.elemnt,
            loading: false,
            totalRecords: this.elemnt.length,
        })
        window.addEventListener('scroll', function () {
            var header = document.getElementById('re');
            var section = document.getElementById('banner');
            var headerHeight = header.clientHeight;
            var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

            if (scrollPosition > headerHeight) {
                header.style.top = '-' + headerHeight + 'px';
                section.style.display = 'flex';
            } else {
                header.style.top = '0';
                section.style.display = 'none';
            }
        })

        const map = new mapboxgl.Map({
            accessToken: Constant.MAPBOX_KEY,
            container: this.mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [40.7128, -74.0060],
            zoom: 9,
            projection: 'globe',
            cooperativeGestes: true,
            dragPan: false,
            dragRotate: false,
            interactive: false
        });;
        map.on('style.load', () => {
            map.setFog({});
        });
        const marker = new mapboxgl.Marker({ color: '#ed6d5f' })
            .setLngLat([40.7128, -74.0060])
            .addTo(map);
        //map.addControl(new mapboxgl.NavigationControl())

        function allPhotos() {
            const overlay = document.querySelector(".overlay")
            const left = document.querySelector("#popup-left")
            const right = document.querySelector("#popup-right")

            right.addEventListener("click", () => {
                document.querySelector(".photos-container .images").scrollLeft +=
                    document.querySelector(".photos-container .images").scrollWidth /
                    document.querySelector(".images").childElementCount
            })
            left.addEventListener("click", () => {
                document.querySelector(".photos-container .images").scrollLeft -=
                    document.querySelector(".photos-container .images").scrollWidth /
                    document.querySelector(".images").childElementCount
            })
            document
                .querySelector(".photos-container .images")
                .addEventListener("scroll", (e) => {
                    for (
                        let i = 0;
                        i < document.querySelector(".images").childElementCount;
                        i++
                    ) {
                        if (
                            e.target.scrollLeft >=
                            (document.querySelector(".photos-container .images").scrollWidth /
                                document.querySelector(".images").childElementCount) *
                            i
                        ) {
                            document
                                .querySelector(".feedback")
                                .querySelectorAll("div")
                                .forEach((el) => {
                                    el.classList.remove("active")
                                })
                            document
                                .querySelector(".feedback")
                                .querySelectorAll("div")
                            [i].classList.add("active")
                        }
                    }
                })

            // Affiche les images
            document.querySelector("#all-photos").addEventListener("click", () => {
                overlay.style.display = null
            })
            document.querySelector("#all-photos-res").addEventListener("click", () => {
                overlay.style.display = null
            })

            // Temoins
            for (
                let i = 0;
                i < document.querySelector(".images").childElementCount;
                i++
            ) {
                document
                    .querySelector(".feedback")
                    .appendChild(document.createElement("div"))
                if (i === 0) {
                    document.querySelector(".feedback").querySelector("div").classList =
                        "active"
                }
            }

            // Ferme la popup
            document.querySelector("#close-all-photos").addEventListener("click", () => {
                overlay.style.display = "none"
            })
            overlay.addEventListener("click", (e) => {
                if (e.target === overlay) {
                    document.querySelector(".overlay").style.display = "none"
                }
            })
        }
        allPhotos()

        this.changeActiveSection()
        window.addEventListener('scroll', this.changeActiveSection);
    }


    changeActiveSection = () => {

        window.addEventListener('load', () => {
            const changeActiveSection = () => {
                const sections = document.querySelectorAll('section');
                let maxVisibleArea = 0;
                let activeSection = null;

                sections.forEach((section) => {
                    const rect = section.getBoundingClientRect();

                    // Calculer la superficie visible de la section
                    const visibleArea = Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);

                    if (visibleArea > maxVisibleArea) {
                        maxVisibleArea = visibleArea;
                        activeSection = section;
                    }
                });

                if (activeSection) {
                    // Faites quelque chose avec la section active, par exemple :
                    console.log('La section active est', activeSection.id);
                    this.setState({ activeSection: activeSection.id });
                }
            };

            window.addEventListener('scroll', changeActiveSection);
        });
    };

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };
    handleButtonClick(event, targetSectionId) {
        event.preventDefault();
        const targetSection = document.querySelector(`#${targetSectionId}`);
        this.setState({ activeSection: targetSectionId })
        targetSection.scrollIntoView({ behavior: 'smooth' });
    }



    onPage(event) {
        let start = event.first;
        let page = event.page;
        this.setState({ loading: true });
        const params = new URLSearchParams({
            start: page,
            size: this.pageSize,
            sortFilter: this.state.sortFilter
        });
        this.itemService.getPageParams(params)
            .then(response => {
                this.setState({
                    first: start,
                    elements: this.elemnt,
                    loading: false,
                    totalRecords: this.elemnt.length,
                })

                this.props.history.push({
                    pathname: '/',
                    search: "?" + new URLSearchParams({ page: page + 1 }).toString()
                })
            })
            .catch(error => {
                this.setState({ loading: false });
            });
    }
    showSuccess() {
        this.toast.show({ severity: 'info', summary: 'Desormais vous suivez', detail: 'xywr', life: 300 });
        this.setState({ follow: true })
    }

    render() {
        const skeletonList = [];
        for (let i = 0; i < this.pageSize; i++) {
            skeletonList.push(<AdSkeleton key={i} />);
        }
        const paginatorTemplate = {
            layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
            'CurrentPageReport': (options) => {
                return (
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {options.first} - {options.last} sur {options.totalRecords}
                    </span>
                )
            }
        }
        const toast = createRef(null)
        const ban = 'https://picsum.photos/1500/1350'
        const logo = 'https://picsum.photos/500/350'

        return (
            <>
                <DefaultMeta />
                <Navbar />
                <Toast ref={(el) => this.toast = el} />
                <div className="overlay" style={{ display: " none" }}>
                    <div className="photos-container">
                        <svg id="close-all-photos" viewBox="0 0 24 24" width="48" height="48" stroke="currentColor"
                            strokeWidth="2"
                            fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                        <svg id="popup-left" className="arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round">
                            <polyline points="15 18 9 12 15 6" transform="translate(-1 0)" />
                        </svg>
                        <div className="images">

                            {this.elemnt[0].images.map((image, index) => <img key={index} src={image.url}
                                alt={image.name} />)}
                        </div>
                        <svg id="popup-right" className="arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round">
                            <polyline points="9 18 15 12 9 6" transform="translate(1 0)" />
                        </svg>
                        <div className="feedback"></div>
                    </div>
                </div>
                <main className="container pro search ">
                    <div id="re"></div>
                    <div className="pro__banniere" style={{ backgroundImage: `url(${ban})` }}>
                        <div className="pro__banniere__logo">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="banniere" id="banniere">
                            <h3>ANYCARS</h3>
                            <Rating value={this.state.val} onChange={(e) => this.setState({ val: e.target.value })} cancel={false} stars={5} style={{ color: ' #FFD700' }} />


                        </div>

                        <div className="b__contact col-md-4">
                            <p>SIREN:EEOEOEEOEOE</p>
                            <button className={!this.state.contact ? "c_btn" : "c_btn_a"}
                                onClick={() => this.showContact()}
                            ><i className="pi pi-phone pr-2"></i>Afficher le numero</button>
                            {this.state.contact ?
                                <div className="py-2">
                                    <p>+336-172-97639</p>
                                    <button className='c_btna'
                                    ><i className="pi pi-phone pr-2"></i> appeler </button>
                                </div>

                                : <div className="py-2"></div>}

                            <button className={this.state.shownum ? "c_btn_a" : "c_btn"}
                                onClick={() => this.showMail()}
                            > <i className="pi pi-envelope pr-2"></i>Envoyer un mail</button>
                            <div className="py-2"></div>
                            <button className={this.state.showchat ? "c_btn_a" : "c_btn"}
                                onClick={() => this.showChat()}
                            > <i className="pi pi-comments pr-2"></i>Demarrer une discussion</button>



                        </div>
                    </div>

                    <div className="b__link" >
                        <div className="bannieres" id="banniere">
                            <h3>ANYCARS</h3>
                            <Rating value={this.state.val} onChange={(e) => this.setState({ val: e.target.value })} cancel={false} stars={5} style={{ color: ' #FFD700' }} />
                        </div>

                        <div className="nav">
                            {this.items.map(
                                (i) => (

                                    <Link to={i.to} onClick={(e) => this.handleButtonClick(e, i.to)}
                                        className={`nav-item ${this.state.activeSection === i.to ? 'nav-active' : ''}`}
                                    >
                                        <div>{i.label}</div>
                                    </Link>

                                )
                            )}
                            <div className="suivre d-flex align-items-center">
                                <button className={this.state.follow ? "suivi" : "bttn"}
                                    onClick={() => this.showSuccess()}>{this.state.follow ? 'suivi' : 'suivre'}</button>
                            </div>
                        </div>



                    </div>

                    <div className="b__link__responsive" >
                        <div className="bannieres" id="banniere">
                            <h3>ANYCARS</h3>
                            <Rating value={this.state.val} onChange={(e) => this.setState({ val: e.target.value })} cancel={false} stars={5} style={{ color: ' #FFD700' }} />
                        </div>

                        <div className="nav">
                            {this.items.map(
                                (i) => (

                                    <Link to={i.to} onClick={(e) => this.handleButtonClick(e, i.to)}
                                        className={`nav-item ${this.state.activeSection === i.to ? 'nav-active' : ''}`}
                                    >
                                        <div>{i.label}</div>
                                    </Link>

                                )
                            )}
                            <div className="suivre d-flex align-items-center">
                                <button className={this.state.follow ? "suivi" : "bttn"}
                                    onClick={() => this.showSuccess()}>{this.state.follow ? 'suivi' : 'suivre'}</button>
                            </div>
                        </div>



                    </div>


                    <div id="banner" className="banniere-t">

                        <h3>ANYCARS</h3>

                        <div className="nav">
                            {this.items.map(
                                (i) => (

                                    <Link to={i.to} onClick={(e) => this.handleButtonClick(e, i.to)}
                                        className={`nav-item ${this.state.activeSection === i.to ? 'nav-active' : ''}`}
                                    >
                                        <div>{i.label}</div>
                                    </Link>

                                )
                            )}


                        </div>


                    </div>
                    <section id="infos" className="col-md-8">
                        <div className="pro__desctiption__title">
                            <h2>Les spécialistes de la pièce automobile</h2>
                        </div>
                        <div className="row">
                            <div className="pro__description col-md-12" >

                                <div className="pro__description__desc">
                                    <p>ANYCARS, entreprise spécialisée dans la vente de pièces
                                        détachées automobiles neuves et d’occasion du Groupe VAG
                                    </p>
                                    <p>(VOLKSWAGEN, AUDI, SEAT, SKODA), vous propose une large</p>
                                    <Inplace>
                                        <InplaceDisplay> <i>Voir plus</i> </InplaceDisplay>
                                        <InplaceContent>
                                            <p className="m-0">
                                                gamme de produits à des prix attractifs.
                                                De l’élément de carrosserie à la pièce mécanique en passant
                                            </p>
                                            <p className="m-0">
                                                par les accessoires d’intérieur, les arrivages réguliers de
                                                pièces d’occasion et le stock neuf nous permettent de
                                                répondre rapidement à vos besoins.
                                            </p>
                                            <p className="m-0">
                                                En plus de la vente de pièces détachées, nous proposons les
                                            </p>

                                        </InplaceContent>
                                    </Inplace>
                                </div>
                            </div>

                        </div>
                        <div className="pro__services" >
                            <div class="pro__desctiption__title" >
                                <h2> Services de l’entreprise</h2>
                            </div>

                            <div className="pro__services__item">
                                {
                                    this.exple.map((e) =>
                                        <div className="one-chip">
                                            <Chip label={e.label} style={{ fontSize: 10, fontWeight: 'bold', margin: 2 }} />

                                        </div>
                                    )
                                }


                            </div>

                            <div className="pro__service__other" >
                                <Inplace>
                                    <InplaceDisplay>Voir les horaires <i className="pi pi-angle-down"></i> </InplaceDisplay>
                                    <InplaceContent>
                                        <p className="m-0">
                                            Horaires :
                                        </p>
                                        <p className="m-0">
                                            Magasin :
                                        </p>
                                        <p className="m-0">
                                            10h00 à 18h30 du lundi au samedi sans interruption
                                            Standart

                                        </p>
                                        <p className="m-0">

                                            : 10 h à 19h du lundi au dimanche sans interruption
                                        </p>

                                    </InplaceContent>
                                </Inplace>
                            </div>
                            <div className="row">
                                <div ref={this.mapContainer} id="map" className="col-12 col-lg-8 pr-4">

                                </div>
                                <div className="col-lg-4">
                                    <p>address</p>
                                    <p>47 rue Jean Jacques Rousseau</p>

                                    <p>94200 Ivry-sur-Seine</p>
                                </div>
                            </div>

                        </div>
                    </section>
                    <div className="responsive-contact">
                        <div className="pro__contact__ctn col-md-8">
                            <div className="d-flex justify-content-start align-items-center w-100" style={{ marginBlock: 15 }}>
                                <h2 className="h2">Contacter</h2>
                            </div>


                            <div className="b__contact__r">
                                <p>SIREN:EEOEOEEOEOE</p>
                                <button className={!this.state.contact ? "c_btn" : "c_btn_a"}
                                    onClick={() => this.showContact()}
                                ><i className="pi pi-phone pr-2"></i>Afficher le numero</button>
                                {this.state.contact ?
                                    <div className="py-2">
                                        <p>+336-172-97639</p>
                                        <button className='c_btna'
                                        ><i className="pi pi-phone pr-2"></i> appeler </button>
                                    </div>

                                    : <div className="py-2"></div>}

                                <button className={this.state.shownum ? "c_btn_a" : "c_btn"}
                                    onClick={() => this.showMail()}
                                > <i className="pi pi-envelope pr-2"></i>Envoyer un mail</button>
                                <div className="py-2"></div>
                                <button className={this.state.showchat ? "c_btn_a" : "c_btn"}
                                    onClick={() => this.showChat()}
                                > <i className="pi pi-comments pr-2"></i>Demarrer une discussion</button>



                            </div>


                        </div>
                    </div>
                    <section id="annonces" class="result">
                        <div class="title">
                            <h2> annonce(s)</h2>
                            <hr />
                            <div class="filter-input btn btn-secondary17"
                                style={{ boxShadow: "inset 0 0 0 2px #ed6d5f" }}>
                                <select name="filter">
                                    <option value="creationDateDesc">Plus récentes</option>
                                    <option value="creationDateAsc">Plus anciennes</option>
                                    <option value="priceAsc">Prix croissant</option>
                                    <option value="priceDec">Prix décroissant</option>
                                    <option value="distanceAsc">Plus proche</option>
                                    <option value="distanceDesc">Plus loin</option>
                                </select>
                            </div>
                        </div>
                        <div className="cards row row-cols-md-4 row-cols-1">
                            {this.state.loading ?
                                skeletonList :
                                this.state.elements?.map((item, index) => <Card key={index} item={item} />)
                            }
                        </div>


                        <Paginator template={paginatorTemplate} first={this.state.first} rows={this.pageSize}
                            totalRecords={this.state.totalRecords} onPageChange={this.onPage}
                            className="justify-content-start my-3" />
                    </section>
                    <section id="photos" className="pro__desctiption__title" >
                        <h2>Photos de l’entreprisee</h2>

                        <div className="notice">
                            <section className="spotlight row">
                                <div className="spotlight-left col-12 col-lg-12">
                                    <div className="images">
                                        {this.elemnt[0].images.slice(0, 2).map((image, index) => <img key={index} src={image.url}
                                            alt={image.name} />)}


                                    </div>
                                    <div className='more-option mos'>

                                        <button id="all-photos" className="more">Voir les {this.elemnt[0].images.length} photos </button>

                                    </div>
                                </div>
                            </section>
                            <button id="all-photos-res" className="more-responsive">Voir
                                les {this.state.item?.images?.length} photos
                            </button>
                        </div>
                    </section>
                    <section id="avis" className="col-md-12 ">
                        <div class="pro__desctiption__title row align-items-center justify-content-between" >
                            <h2> Avis des clients</h2>
                            <div className="">
                                <h3>4.5/5</h3>
                                <p className="span">Moyenne des avis</p>
                            </div>
                        </div>
                        <Avis value='3' name='calviniste' date='12/10/2010' description='J’ai acheté une aile avant droit prêt à peindre pour ma Clio 4 à 40€. C’était le meilleur rapport qualité prix que j’ai pu avoir car j’ai passé ma matinée a appeler des casses autos et des garages à la recherches d’un prix abordable.' />
                        <Avis value='3' name='calviniste' date='12/10/2010' description='J’ai acheté une aile avant droit prêt à peindre pour ma Clio 4 à 40€. C’était le meilleur rapport qualité prix que j’ai pu avoir car j’ai passé ma matinée a appeler des casses autos et des garages à la recherches d’un prix abordable.' />

                    </section>

                </main>
            </>
        )
    }
}

export default Professional