import React, { Fragment } from 'react';

import "./cgu.css"
import Footer from '../../components/footer/Footer';
import {Link} from "react-router-dom"
import ScrollTop from '../../components/scroll-top/ScrollTop';
import Navbar from '../../components/navbar/Navbar';
import DefaultMeta from "../../components/seo/DefaultMeta";
import {Helmet} from "react-helmet";


class Cgu extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        return <Fragment>
            <DefaultMeta/>
            <Helmet>
                <title>Conditions générales de vente | Trouvare</title>
                <meta name="description" content="Conditions générales de vente"/>
                <meta property="og:url" content="https://trouvare.com/cgu"/>
                <link rel="canonical" href="https://trouvare.com/cgu"/>
            </Helmet>
            <Navbar />
            <main class="container cgu">
            <section className="navigation">
                    <Link to="/" className="back"> {"<"} Retour</Link>
                    <span id="notice_number"></span>
                </section>
           
                <h1>Conditions g&eacute;n&eacute;rales de vente du Service TROUVARE 20/01/2023</h1>
                <div>
                    <p>I DEFINITIONS <br />Chacun des termes mentionn&eacute;s ci-dessous aura dans les pr&eacute;sentes Conditions G&eacute;n&eacute;rales de Vente du Service Trouvare ( ci-apr&egrave;s d&eacute;nomm&eacute;es les &laquo; CGV &raquo; ) la signification suivante :<br />Annonce : d&eacute;signe l'ensemble des &eacute;l&eacute;ments et donn&eacute;es (visuelles, textuelles, sonores, photographies, dessins), d&eacute;pos&eacute; par un Annonceur sous sa responsabilit&eacute; &eacute;ditoriale exclusive, en vue d'acheter, de louer ou de vendre un bien ou un service et diffus&eacute; sur le Site Internet et les Applications.<br />Annonceur : d&eacute;signe toute personne physique, majeure, agissant exclusivement &agrave; des fins priv&eacute;es et non professionnelles (tout Annonceur professionnel est dans l'obligation d'ouvrir un Compte Pro et de respecter les CGV applicables aux d&eacute;tenteurs d'un &laquo; Compte Pro &raquo;), &eacute;tablie au Cameroun, titulaire d&rsquo;un Compte Personnel et ayant d&eacute;pos&eacute; une Annonce, &agrave; partir de celui-ci, sur le Site Internet, et/ou sur les Applications. Tout Annonceur doit imp&eacute;rativement &ecirc;tre connect&eacute; &agrave; son Compte Personnel pour d&eacute;poser et g&eacute;rer sa ou ses Annonces. Tout premier d&eacute;p&ocirc;t d&rsquo;annonce entra&icirc;ne automatiquement la cr&eacute;ation d&rsquo;un Compte Personnel propre &agrave; l&rsquo;Annonceur.</p>
                    <p>Compte Personnel : d&eacute;signe l'espace gratuit que tout Annonceur doit se cr&eacute;er et auquel il doit se connecter depuis le Site Internet et les Applications, afin de diffuser, g&eacute;rer, visualiser ses annonces.</p>
                    <p>TROUVARE CAMEROUN : d&eacute;signe la soci&eacute;t&eacute; qui &eacute;dite et exploite le Site Internet et les Applications TROUVARE CAMEROUN, SAS au capital de 1000 euros, immatricul&eacute;e au registre du commerce et des soci&eacute;t&eacute;s de Paris dont le si&egrave;ge social est situ&eacute; 10 Rue Emile Reynaud 75019 Paris.</p>
                    <p>Service client : d&eacute;signe le service de TROUVARE CAMEROUN aupr&egrave;s duquel l'Annonceur peut obtenir toute information compl&eacute;mentaire. Ce service peut &ecirc;tre contact&eacute; par e-mail en cliquant sur le lien pr&eacute;sent sur le Site Internet et les Applications.</p>
                    <p>Service Trouvare : d&eacute;signe les services Trouvare mis &agrave; la disposition des Utilisateurs et des Annonceurs sur le Site Internet et les Applications.</p>
                    <p>Site Internet : d&eacute;signe le site internet exploit&eacute; par TROUVARE CAMEROUN accessible principalement depuis l'URL www.trouvare.com et permettant aux Utilisateurs et aux Annonceurs d'acc&eacute;der via internet au Service Trouvare.</p>
                    <p>Utilisateur : d&eacute;signe tout visiteur, ayant acc&egrave;s au Service Trouvare via le Site Internet et/ou les Applications et consultant le Service Trouvare accessible depuis les diff&eacute;rents supports.</p>
                    <p>2 OBJET<br />Les pr&eacute;sentes Conditions G&eacute;n&eacute;rales de Vente (CGV) &eacute;tablissent les conditions contractuelles applicables &agrave; toute souscription, par un Annonceur connect&eacute; &agrave; son Compte Personnel, depuis le Site Internet et les Applications.</p>
                    <p>3 ACCEPTATION<br />Toute souscription par un Annonceur vaut acceptation pleine et enti&egrave;re des CGV en vigueur.</p>
                    <p>4 MODIFICATIONS DES CGV <br />Les pr&eacute;sentes CGV sont applicables &agrave; partir du 20 janvier 2023.<br />TROUVARE CAMEROUN se r&eacute;serve la possibilit&eacute;, &agrave; tout moment, de modifier en tout ou partie les CGV.<br />Les Annonceurs sont invit&eacute;s &agrave; consulter r&eacute;guli&egrave;rement les CGV afin de prendre connaissance des changements apport&eacute;s.</p>
                    <p>5 DISPOSITIONS DIVERSES <br />Tout traitement de donn&eacute;es personnelles dans le cadre des pr&eacute;sentes est soumis aux dispositions de notre politique de confidentialit&eacute;, qui fait partie int&eacute;grante des pr&eacute;sentes CGV.<br />Si une partie des CGV devait s'av&eacute;rer ill&eacute;gale, invalide ou inapplicable, pour quelque raison que ce soit, les dispositions en question seraient r&eacute;put&eacute;es non &eacute;crites, sans remettre en cause la validit&eacute; des autres dispositions qui continueront de s'appliquer entre les Annonceurs et TROUVARE CAMEROUN.<br />Toute r&eacute;clamation doit &ecirc;tre adress&eacute;e au Service Client de TROUVARE CAMEROUN.<br />Les CGV sont soumises au droit international.</p>
                    <p>&nbsp;</p>
                </div>
            </main>

            <Footer />
            <ScrollTop/>
        </Fragment>;
    }
}


export default Cgu;
