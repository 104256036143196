import React, {Component} from 'react';
import "./adSkeleton.css"
import moment from "moment";
import {Link} from "react-router-dom";
import { Skeleton } from 'primereact/skeleton';

class AdSkeleton extends Component {



    render() {
        return (
            <Link className={this.props.class ? "favorite-card card col-lg-3" : "card col-lg-3"}
                  onClick={e => e.preventDefault()}
                  id="card">

                <div className="image">
                    <Skeleton  className="skeletonImage"/>
                </div>
                <div className="meta">
                    <div className="line-top">
                        <div className="name">
                            <h2 title=""> <Skeleton className="mb-2" width="5rem" height="1rem"/> </h2>
                            <span className="price" id="price"> <Skeleton className="mb-2" width="4rem" height="1rem"/> </span>
                        </div>
                        <div className="date">
                            <Skeleton className="mb-2" width="4rem" height="1rem"/>
                            <Skeleton width="4rem" height="1rem"/>
                        </div>
                    </div>
                    <div className="line-bottom">
                        <div className="infos">
                            {/* 2012 <br/>
                                Diesel <br/>
                                156 000 km*/}
                        </div>
                        <div className="location">
                            {/*<span className="pro-label">PRO</span>*/}
                            <Skeleton width="4rem" height="1rem"/>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default AdSkeleton;
