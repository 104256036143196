import React, { Component } from "react"
import Navbar from "../../components/navbar/Navbar"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './profile.css'
import DefaultMeta from "../../components/seo/DefaultMeta";
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Checkbox} from 'primereact/checkbox';
import { Password } from 'primereact/password';
import TabPanel from "./TabPanel";
import logo from '../../assets/img/profile-illustration.svg'


class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0,
            value: 0,
            nom: '',
            username: '',
            prenom: '',
            dateDeNaissance: '',
            addresse: '',
            mail: '',
            numero: '',
            txtPresentation: '',
            nomEtreprise: '',
            siret: '',
            numeroEtreprise: '',
            mailEtreprise: '',
            value3: '',
            mdpa: 'mdpa',
            mdpc: '',
            mdp: '',
            img: ""
        }
    }
    inputRef = React.createRef(null)
    a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }
    handleChange = (event, newValue) => {
        console.log('nouvelle val', newValue)
        this.setState({ value: newValue });
    };
    changeImage = (e) => {
        console.log(e.target.files[0])
        this.setState({ img: e.target.files[0] })
    }
    imageClick = () => {
        this.inputRef.current.click()
    }
    render() {
        return (
            <>
                <DefaultMeta />
                <Navbar />
                <main className="container profil">

                    <div className="p-item col-lg-12 p-r">
                        <div className='p-menu'>
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={this.state.value}
                                onChange={this.handleChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                            >

                                <Tab label="personal" {...this.a11yProps(0)} sx={{ textAlign: 'start' }} />
                                <Tab label="entreprise" {...this.a11yProps(1)} />
                                <Tab label="settings" {...this.a11yProps(2)} />


                            </Tabs>
                        </div>
                        <div className="p-menu-resp">
                            <Tabs
                                orientation="horizontal"
                                variant="scrollable"
                                value={this.state.value}
                                onChange={this.handleChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderBottom: 1, borderColor: 'divider' }}
                            >

                                <Tab label="personal" {...this.a11yProps(0)} sx={{ textAlign: 'start' }} />
                                <Tab label="entreprise" {...this.a11yProps(1)} />
                                <Tab label="settings" {...this.a11yProps(2)} />


                            </Tabs>
                        </div>
                        <div className="col-lg-10">
                            <TabPanel value={this.state.value} index={0}>
                                <div className="col profil-header">
                                    <div className="row ">
                                        <div className="personnal-info row align-items-center col-md-12 col-lg-6">

                                            <div onClick={this.imageClick} className="wrapper mr-2 ">
                                                {this.state.img ? <img src={URL.createObjectURL(this.state.img)} /> : <img src={logo} />}
                                                <input type="file"
                                                    ref={this.inputRef}
                                                    onChange={this.changeImage}
                                                    style={{ display: 'none' }}
                                                />
                                                <div className="my-file">
                                                    <i className="pi pi-camera"></i>
                                                   
                                                </div>
                                            </div>

                                            <div >
                                                <h4>John</h4>
                                                
                                            </div>
                                        </div>
                                        <div className="p-infos col-md-12 col-lg-6">
                                            <p className="role">administrateur</p>
                                            <p>date de cration du compte</p>
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="row ">
                                            <div class="col-md-12 col-lg-6 my-3">
                                                <div class="form-group ">
                                                    <label>Nom</label>
                                                    <InputText value={this.state.nom} onChange={(e) => this.setState({ nom: e.target.value })} />
                                                </div>
                                            </div>

                                            <div className="col-md-12 col-lg-6 my-3">
                                                <div className="form-group">
                                                    <label>Prenom</label>
                                                    <InputText value={this.state.prenom} onChange={(e) => this.setState({ prenom: e.target.value })} />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col my-3">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <InputText value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value })} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 col-lg-6 my-3">
                                                <div class="form-group ">
                                                    <label>Username</label>
                                                    <InputText value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} />
                                                </div>
                                            </div>

                                            <div className="col-md-12 col-lg-6 my-3">
                                                <div className="form-group">
                                                    <label>Telephone</label>
                                                    <InputText value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col my-3">
                                                <div className="form-group">
                                                    <label>Date de naissance</label>
                                                    <Calendar className="form-control" value={this.state.dateDeNaissance} onChange={(e) => this.setState({ dateDeNaissance: e.value })}></Calendar>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col my-3">
                                                <div className="form-group">
                                                    <label>Texte de presentation</label>
                                                    <InputTextarea rows={5} className="form-control" value={this.state.txtPresentation} onChange={(e) => this.setState({ txtPresentation: e.target.value })} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col d-flex justify-content-center my-4 subtn">
                                        <button class="btn btn-primary" type="submit" style={{ color: '#fff' }}>Enregistrer</button>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={1}>
                                <div className="row">
                                    <div className="col my-3">
                                        <div className="form-group">
                                            <label>Nom de l'entreprise</label>
                                            <InputText value={this.state.nomEtreprise} onChange={(e) => this.setState({ nomEtreprise: e.target.value })} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col my-3">
                                        <div className="form-group">
                                            <label>Siret entreprise</label>
                                            <InputText value={this.state.siret} onChange={(e) => this.setState({ siret: e.target.value })} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col my-3">
                                        <div className="form-group">
                                            <label>Numero</label>
                                            <InputText value={this.state.nomEtreprise} onChange={(e) => this.setState({ numeroEtreprise: e.target.value })} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col my-3">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <InputText value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value })} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col d-flex justify-content-center my-4 subtn">
                                        <button class="btn btn-primary" type="submit" style={{ color: '#fff' }}>Enregistrer</button>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={2}>
                                <div class="row">
                                    <div class="col-12 col-sm-6 mb-3">
                                        <div class="mb-2"><b>Changer le mot de passe</b></div>
                                        <div class="row my-2">
                                            <div class="col">
                                                <div class="form-group">
                                                    <label>mot de passe actuel</label>
                                                    <Password className="form-control" value={this.state.mdpa} onChange={(e) => this.setState({ mdpa: e.target.value })} toggleMask />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row my-2">
                                            <div class="col">
                                                <div class="form-group">
                                                    <label>New Password</label>
                                                    <Password className="form-control" value={this.state.mdp} onChange={(e) => this.setState({ mdp: e.target.value })} toggleMask />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row my-2">
                                            <div class="col">
                                                <div class="form-group">
                                                    <label>Confirm <span class="d-none d-xl-inline">Password</span></label>
                                                    <Password className="form-control" value={this.state.mdpc} onChange={(e) => this.setState({ mdpc: e.target.value })} toggleMask />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-5 offset-sm-1 mb-3">
                                        <div class="mb-2"><b>Notifications</b></div>
                                        <div class="row">
                                        <div class="col">
                                                <div class="custom-controls-stacked px-2">

                                                    <div class="custom-control custom-checkbox my-2">
                                                        <Checkbox onChange={e => this.setState({ checked: !this.state.checked })} checked={this.state.checked}></Checkbox>

                                                        <label htmlFor="city1" className="ml-2">SMS</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox my-2">

                                                        <Checkbox onChange={e => this.setState({ cmail: !this.state.cmail })} checked={this.state.cmail}></Checkbox>
                                                        <label htmlFor="city1" className="ml-2">E-mail</label>
                                                    </div>
                                                </div>
                                            </div> 
                                           
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col d-flex justify-content-center my-4 subtn">
                                        <button class="btn btn-primary" type="submit" style={{ color: '#fff' }}>Enregistrer</button>
                                    </div>
                                </div>
                            </TabPanel>

                        </div>


                    </div>
                </main >
            </>
        )
    }
}
export default Profile