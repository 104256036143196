import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

// Common Layout
import {Provider} from "react-redux";
import store from "./store";

import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import AppWrapper from "./AppWrapper";

//primefaces
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import 'react-phone-input-2/lib/high-res.css'
import {history} from "./helpers/history";
import {getTranslations} from "./actions/i18n";
import {getSettings} from "./actions/setting";

store.dispatch(getTranslations());
store.dispatch(getSettings());

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter history={history}>
            <AppWrapper/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
