import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import reduxWebsocket from '@giantmachines/redux-websocket';
import {Constant} from './helpers/const';
import {syncTranslationWithStore,} from "react-redux-i18n";

const middleware = [thunk];
// Create the middleware instance.
const reduxWebsocketChatMiddleware = reduxWebsocket({prefix: Constant.WS_CHAT_PREFIX, reconnectOnClose: true});
const reduxWebsocketMiddleware = reduxWebsocket({prefix: Constant.WS_PREFIX, reconnectOnClose: true});

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(reduxWebsocketChatMiddleware, reduxWebsocketMiddleware, ...middleware))
);

syncTranslationWithStore(store);
export default store;
