import React, {Component} from 'react'

import './search.css'

import Footer from '../../components/footer/Footer';
import {Link} from "react-router-dom"
import ScrollTop from '../../components/scroll-top/ScrollTop';
import Navbar from '../../components/navbar/Navbar';
import DefaultMeta from "../../components/seo/DefaultMeta";
import {Helmet} from "react-helmet";
import AdSkeleton from "../../components/skeleton/AdSkeleton";
import GooglePlacesAutocomplete, {geocodeByPlaceId} from "react-google-places-autocomplete";
import {Constant} from "../../helpers/const";
import {I18n} from "react-redux-i18n";
import {getAddressObject, getPosition} from "../../helpers/utils";
import {history} from "../../helpers/history";
import ItemService from "../../services/ItemService";
import Card from "../../components/card/Card";
import {Paginator} from "primereact/paginator";
import CategoryService from "../../services/CategoryService";
import {CascadeSelect} from "primereact/cascadeselect";

export default class Search extends Component {

    constructor(props) {
        super(props);
        this.pageSize = 8; //elements to load
        this.state = {
            title: null,
            address: null,
            position: null,
            subcategory: null,
            category: null,
            sortFilter: "creationDateDesc",
            categories: [],
            first: 0,
            page: 0,
            totalRecords: 0,
            elements: [],
            loading: false
        }

        this.handleMapAddressChange = this.handleMapAddressChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.onPage = this.onPage.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);

        this.itemService = new ItemService();
        this.categoryService = new CategoryService();
    }

    componentDidMount() {
        let params = new URLSearchParams(this.props.location.search);
        const params2 = new URLSearchParams({});

        if (params.get("title") != null) {
            params2.append("title", params.get("title"))
            this.setState({title: params.get("title")})
        }
        if (params.get("start") != null) {
            params2.append("start", params.get("start"))
            this.setState({page: params.get("start")})
        }
        if (params.get("size") != null) {
            params2.append("size", params.get("size"))
            this.pageSize = params.get("size")
        }
        if (params.get("sortFilter") != null) {
            params2.append("sortFilter", params.get("sortFilter"))
            this.setState({sortFilter: params.get("sortFilter")})
        }
        if (params.get("lat") != null && params.get("lon") != null) {
            params2.append("lat", params.get("lat"))
            params2.append("lon", params.get("lon"))
            let position = {latitude: params.get("lat"), longitude: params.get("lon")}
            this.setState({position})
        }
        if (params.get("subcategory") != null) {
            params2.append("subcategory", params.get("subcategory"))
            this.setState({subcategory: params.get("subcategory")})
        }
        if (params.get("category") != null) {
            params2.append("category", params.get("category"))
            this.setState({category: params.get("category")})
        }
        this.loadCategories();

        [...params2.entries()].forEach(([key, value]) => {
            if (!!!value || value === "undefined" || value === "null") {
                params2.delete(key);
            }
        });

        this.setState({loading: true});

        this.itemService.getPageParams(params2)
            .then(response => {
                this.setState({
                    elements: response.data.content.list,
                    loading: false,
                    totalRecords: response.data.content.totalCount,
                })
            })
            .catch(error => {
                this.setState({loading: false});
            });
    }

    handleFilterChange(e) {
        e.preventDefault();
        this.setState({sortFilter: e.target.value})
        if (!!this.state.title) {
            const params = new URLSearchParams({
                title: this.state.title,
                start: this.state.page,
                size: this.pageSize,
                sortFilter: e.target.value,
                lat: this.state.position?.latitude,
                lon: this.state.position?.longitude,
                subcategory: !this.state.subcategory?.category ? this.state.subcategory?.id : undefined,
                category: this.state.subcategory?.category ? this.state.subcategory?.id : undefined,
            });

            [...params.entries()].forEach(([key, value]) => {
                if (!!!value || value === "undefined" || value === "null") {
                    params.delete(key);
                }
            });

            history.push({
                pathname: '/search',
                search: "?" + params.toString()
            })

            this.setState({loading: true});

            this.itemService.getPageParams(params)
                .then(response => {
                    this.setState({
                        elements: response.data.content.list,
                        loading: false,
                        totalRecords: response.data.content.totalCount,
                    })
                })
                .catch(error => {
                    this.setState({loading: false});
                });
        }
    }

    loadCategories() {
        this.categoryService.getAllWithSubcategories().then(
            response => {
                let categories = []
                for (let i = 0; i < response.data.content.length; i++) {
                    let categoryData = response.data.content[i];
                    let subcategoriesData = categoryData.subcategories;
                    if (subcategoriesData === undefined) {
                        subcategoriesData = []
                    }
                    let subcategories = []
                    subcategories.push({
                        label: categoryData.nameFr,
                        id: categoryData.identifier,
                        category: true
                    })
                    for (let i = 0; i < subcategoriesData.length; i++) {
                        let subcategoryData = subcategoriesData[i];
                        subcategories.push({
                            label: subcategoryData.nameFr,
                            id: subcategoryData.identifier,
                            minImages: subcategoryData.minImages,
                            maxImages: subcategoryData.maxImages,
                            elements: subcategoryData.elements
                        })
                    }
                    categories.push({label: categoryData.nameFr, id: categoryData.identifier, subcategories})
                }
                this.setState({categories})
            }
        );
    }

    handleSearch(e) {
        e.preventDefault();
        const params = new URLSearchParams({
            title: this.state.title,
            start: this.state.page,
            size: this.pageSize,
            sortFilter: this.state.sortFilter,
            lat: this.state.position?.latitude,
            lon: this.state.position?.longitude,
            subcategory: !this.state.subcategory?.category ? this.state.subcategory?.id : undefined,
            category: this.state.subcategory?.category ? this.state.subcategory?.id : undefined,
        });

        [...params.entries()].forEach(([key, value]) => {
            if (!!!value || value === "undefined" || value === "null") {
                params.delete(key);
            }
        });

        history.push({
            pathname: '/search',
            search: "?" + params.toString()
        })

        this.setState({loading: true});

        this.itemService.getPageParams(params)
            .then(response => {
                this.setState({
                    elements: response.data.content.list,
                    loading: false,
                    totalRecords: response.data.content.totalCount,
                })
            })
            .catch(error => {
                this.setState({loading: false});
            });
    }

    onPage(event) {
        let start = event.first;
        let page = event.page;
        this.setState({loading: true});
        const params = new URLSearchParams({
            title: this.state.title,
            start: page,
            size: this.pageSize,
            sortFilter: this.state.sortFilter,
            lat: this.state.lat,
            lon: this.state.lon,
        });

        [...params.entries()].forEach(([key, value]) => {
            if (!!!value || value === "undefined" || value === "null") {
                params.delete(key);
            }
        });

        this.itemService.getPageParams(params)
            .then(response => {
                this.setState({
                    first: start,
                    elements: response.data.content.list,
                    loading: false,
                    totalRecords: response.data.content.totalCount,
                })

                history.push({
                    pathname: '/search',
                    search: "?" + params.toString()
                })
            })
            .catch(error => {
                this.setState({loading: false});
            });
    }

    handleMapAddressChange = (initialAddress) => {
        if (initialAddress) {
            let placeId = initialAddress["value"]["place_id"];
            geocodeByPlaceId(placeId)
                .then(results => {
                    if (results[0]) {
                        let address = getAddressObject(results[0].address_components);
                        address.label = initialAddress.label
                        address.value.place_id = initialAddress.value.place_id
                        let position = getPosition(results[0].geometry.location);
                        this.setState({position})
                        this.setState({address})
                    }
                })
                .catch(error => console.error("error found " + error));
        }
    }

    render() {
        const skeletonList = [];
        for (let i = 0; i < this.pageSize; i++) {
            skeletonList.push(<AdSkeleton key={i}/>);
        }

        const paginatorTemplate = {
            layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
            'CurrentPageReport': (options) => {
                return (
                    <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} sur {options.totalRecords}
                    </span>
                )
            }
        }

        return (
            <>
                <DefaultMeta/>
                <Helmet>
                    <title>Recherche | Trouvare</title>
                    <meta name="description"
                          content="Toutes nos annonces gratuites. Consultez nos les annonces de particuliers et professionnels sur trouvare"/>
                    <meta property="og:url" content="https://trouvare.com/search"/>
                    <link rel="canonical" href="https://trouvare.com/search"/>
                </Helmet>
                <Navbar/>
                <main class="container search">
                    <section className="navigation">
                        <Link to="/" className="back"> {"<"} Retour</Link>
                        <span id="notice_number"></span>
                    </section>

                    <section class="fields">
                        <form onSubmit={this.handleSearch} class="row">
                            <div class="col-md-4">
                                <CascadeSelect
                                    options={this.state.categories}
                                    name="subcategory"
                                    value={this.state.subcategory}
                                    id="subcategory"
                                    onChange={(event) => this.setState({subcategory: event.value})}
                                    optionLabel="label"
                                    optionGroupLabel="label"
                                    optionGroupChildren={['subcategories']}
                                    className={this.state.subcategory ? "p-cascadeselectRed" : "r"}
                                    style={{width: '100%'}} placeholder={"Catégories"}/>
                            </div>
                            <div class="col-md-4">
                                <GooglePlacesAutocomplete
                                    name="address"
                                    apiKey={Constant.GMAPS_KEY}
                                    apiOptions={{language: 'fr', region: 'fr'}}
                                    autocompletionRequest={{
                                        componentRestrictions: {
                                            country: ['cm', 'fr', 'us'],
                                        }
                                    }}
                                    selectProps={{
                                        onChange: (data) => {
                                            this.handleMapAddressChange(data)
                                        },
                                        value: this.state.address,
                                        placeholder: I18n.t('ENTER_CITY_OR_NEIGHBORHOOD'),
                                        styles: {
                                            input: (provided) => ({
                                                ...provided,
                                                color: "#222222",
                                            }),
                                            option: (provided) => ({
                                                ...provided,
                                                color: "#222222",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: "#222222",
                                            }),
                                        },
                                    }}
                                />
                            </div>
                            <div class="col-md-4">
                                <div class="btn btn-secondary">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    </svg>
                                    <input
                                        value={this.state.title}
                                        onChange={(e) => {
                                            this.setState({title: e.target.value})
                                        }}
                                        placeholder="Que recherchez-vous ?"
                                        type="search"
                                        class="inputs"
                                        name="query"
                                    />
                                </div>
                            </div>
                            <div class="col-md-4 type">
                                <div class="individual">
                                    <input
                                        type="checkbox"
                                        value="checked"
                                        name="individual"
                                        id="individual"
                                    />
                                    <label for="individual">Annonces particulier ({this.state.totalRecords})</label>
                                </div>
                                <div class="pro">
                                    <input type="checkbox" value="checked" name="pro" id="pro"/>
                                    <label for="pro">Annonces pro (0)</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <button type="submit" class="btn btn-primary submit" style={{color: "white"}}>
                                    Lancer la recherche
                                </button>
                            </div>
                        </form>
                    </section>

                    <section class="result">
                        <div class="title">
                            <h2>{this.state.totalRecords} annonce(s)</h2>
                            <hr/>
                            <div class="filter-input btn btn-secondary17"
                                 style={{boxShadow: "inset 0 0 0 2px #ed6d5f"}}>
                                <select value={this.state.sortFilter} onChange={this.handleFilterChange} name="filter">
                                    <option value="creationDateDesc">Plus récentes</option>
                                    <option value="creationDateAsc">Plus anciennes</option>
                                    <option value="priceAsc">Prix croissant</option>
                                    <option value="priceDec">Prix décroissant</option>
                                    <option value="distanceAsc">Plus proche</option>
                                    <option value="distanceDesc">Plus loin</option>
                                </select>
                            </div>
                        </div>
                        <div className="cards row row-cols-md-4 row-cols-1">
                            {this.state.loading ?
                                skeletonList :
                                this.state.elements?.map((item, index) => <Card key={index} item={item}/>)
                            }
                        </div>
                        <Paginator template={paginatorTemplate} first={this.state.first} rows={this.pageSize}
                                   totalRecords={this.state.totalRecords} onPageChange={this.onPage}
                                   className="justify-content-start my-3"/>
                    </section>
                </main>
                <Footer/>
                <ScrollTop/>
            </>
        )
    }
}
