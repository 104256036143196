import React, {Fragment} from 'react';

import Footer from '../../components/footer/Footer';
import {Link, Redirect} from 'react-router-dom'
import "./signup.css"
import Navbar from '../../components/navbar/Navbar';
import {getFormErrorMessage} from "../../helpers/utils";
import * as Yup from "yup";
import {I18n} from "react-redux-i18n";
import UserService from "../../services/UserService";
import {Formik} from 'formik';
import DefaultMeta from "../../components/seo/DefaultMeta";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {Messages} from "primereact/messages";
import AuthService from "../../services/AuthService";

class PasswordReset extends React.Component {

    emptyElement = {
        password: '',
        password2: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            hasErrorMessage: false,
            loading: false,
            key: null,
            passwordChanged: false
        };

        this.formikProps = {
            initialValues: this.emptyElement,
            validationSchema: Yup.object().shape({
                password: Yup
                    .string()
                    .required(I18n.t('FIELD_IS_REQUIRED'))
                    .min(6, I18n.t('PASSWORD_SHORT')),
                password2: Yup
                    .string()
                    .required(I18n.t('FIELD_IS_REQUIRED'))
                    .oneOf([Yup.ref('password'), null], I18n.t('PASSWORD_MUST_MATCH'))
            })
        };
        this.elementService = new UserService();
        this.changePasswordRequest = this.changePasswordRequest.bind(this);
    }

    componentDidMount() {
        const {match: {params}} = this.props;
        this.setState({key: params.key});
        AuthService.checkResetPasswordKey(params.key)
            .then(response => {
                let message = response.data.code
            })
            .catch(error => {
                this.setState({hasErrorMessage: true});
                this.msgs.show([
                    {severity: 'error', detail: I18n.t(error.data.code), sticky: true, closable: false}
                ]);
            });
    }

    changePasswordRequest = (element, {setStatus, setErrors}) => {
        this.setState({loading: true});
        AuthService.changePasswordRequest(this.state.key, element.password)
            .then(response => {
                this.setState({
                    passwordChanged: true,
                    loading: false
                });
            })
            .catch(error => {
                this.setState({
                    loading: false
                });

                this.setState({hasErrorMessage: true});
                this.msgs.show([
                    {severity: 'error', detail: I18n.t(error.data.code), sticky: true}
                ]);
            });
    }

    render() {
        if (this.state.passwordChanged) {
            return <Redirect to="/login"/>
        }
        return <Fragment>
            <DefaultMeta/>
            <Navbar/>
            <main className="container signup-container">
                <section className="navigation">
                    <Link to="/" className="back"> {"<"} Retour</Link>
                    <span id="notice_number"></span>
                </section>

                <h1>Changement de mot de passe</h1>
                <br/>
                {this.state.hasErrorMessage ?
                    <Messages ref={(el) => this.msgs = el}/>
                    : <div className="login-container col-md-8 col-lg-6 card">
                        <Formik
                            enableReinitialize
                            initialValues={this.formikProps.initialValues}
                            validationSchema={this.formikProps.validationSchema}
                            onSubmit={this.changePasswordRequest}
                        >
                            {props => {
                                console.log(props)
                                return <form onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                             onSubmit={props.handleSubmit} className="col-10">
                                    <div className="field">
                                    <span className="p-float-label">
                                       <Password
                                           style={{width: '100%'}}
                                           toggleMask
                                           name="password"
                                           value={props.values.password}
                                           onChange={props.handleChange}
                                           id="password"
                                           feedback={false}
                                       />
                                        <label htmlFor="password">Nouveau mot de passe<b>*</b></label>
                                    </span>
                                        {getFormErrorMessage(props, 'password')}
                                    </div>

                                    <div className="field">
                                    <span className="p-float-label">
                                       <Password
                                           style={{width: '100%'}}
                                           toggleMask
                                           name="password2"
                                           value={props.values.password2}
                                           onChange={props.handleChange}
                                           id="password2"
                                           feedback={false}
                                       />
                                         <label htmlFor="password2">Confirmer le nouveau mot de passe<b>*</b></label>
                                    </span>
                                        {getFormErrorMessage(props, 'password2')}
                                    </div>
                                    <Button loading={this.state.loading} type="submit" label="Continuer"
                                            className="tButton"/>
                                </form>
                            }}
                        </Formik>
                    </div>}
            </main>
            <Footer/>
        </Fragment>;
    }
}


export default PasswordReset;