export const Constant = {
    WS_CHAT_PREFIX: 'WS_CHAT',
    WS_PREFIX: 'WS',
    WS_NOTIFICATION_PREFIX: 'WS_NOTIFICATION',
    ACCOUNT: '_trouvareeO0ZAAAAAl_koojj',
    API_BASE: getApiBaseUrl(),
    WS_API_BASE: "ws://localhost:9000",
    RECAPTCHA_SITE_KEY: "6LdPiO0ZAAAAAIrvraJPYSeKYbxL50jjCq_w6p2b",
    RECAPTCHA_NOT_SET: "RECAPTCHA_NOT_SET",
    USER_PROFILE: '_trouvareO0ZAAAAAlell_jj',
    MAPBOX_KEY: "pk.eyJ1IjoibWVrb21vdSIsImEiOiJjbDE1YnNub3kwajAzM3FrYWg5N3lwMnZpIn0.OZ2bxQUoUiT4KMo7jBoEuw",
    GMAPS_KEY: "AIzaSyCu2q7yqjVC1QzLJsMKbt6R5SzIXjwAxdM",
    fallbackLocale: "fr-FR",
    supportedLocales: {
        'en-US': "English",
        'fr-FR': "Français",
    }
}

function getApiBaseUrl() {
    if (process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_API_ENDPOINT;
    }
    return 'API_BASE_URL';
}

