import React, {Component} from 'react';
import "./card.css"
import moment from "moment";
import {Link} from "react-router-dom";

class Card extends Component {

    constructor(props) {
        super(props)
        this.item = props.item;
        this.creationDate = new Date(this.item?.createdOn);
    }

    componentDidMount() {
        function card_interaction_enter(e) {
            e.path[0].getElementsByTagName("h2")[0].style.color = "#ed6d5f"
        }

        function card_interaction_leave(e) {
            e.path[0].getElementsByTagName("h2")[0].style.color = null
        }

        document
            .querySelectorAll(".card")
            .forEach((el) =>
                el.addEventListener("mouseenter", (event) => card_interaction_enter(event))
            )
        document
            .querySelectorAll(".card")
            .forEach((el) =>
                el.addEventListener("mouseleave", (event) => card_interaction_leave(event))
            )
        //modifier l'annonce
        document.querySelectorAll(".favorite-card").forEach((el) => {
            const div = document.createElement("div")
            div.className = "actions"
            div.innerHTML = `
            <a role="button"   className="btn btn-secondary cursos">Voir l'annonce</a>
           <a  className="btn btn-secondary cursos">Modifier l'annonce</a>
           <a className="btn btn-primary">Supprimer l'annonce</a>
          `
            el.addEventListener("mouseenter", () => {
                el.querySelector(".image").prepend(div)
            })
            el.addEventListener("mouseleave", () => {
                el.querySelector(".image").removeChild(div)
            })
        })
    }
    handleClick = () => {
        this.props.changeState(this.props.item)
    }
    render() {
        return (
            <Link to={`/items/${this.item.slug}`}
                  onClick={() =>this.handleClick()}
                  className={this.props.class ? "favorite-card card col-lg-3" : "card col-lg-3"}
                  id="card">

                <div className="image"><img src={this.item?.images[0].url} className="__fav-img" alt="Chargement..."/></div>
                <div className="meta">
                    <div className="line-top">
                        <div className="name">
                            <h2 title=""> {this.item?.title} </h2>
                            {this.item?.subcategoryData?.priceRequired === "true" && <span className="price" id="price"> {this.item?.price.etPrice?.toLocaleString()} CFA</span>}
                        </div>
                        <div className="date">
                            {moment(this.creationDate).locale("fr").format("HH[h]mm")}<br/>
                            {moment(this.creationDate).locale("fr").format("DD MMMM YYYY")}
                        </div>
                    </div>
                    <div className="line-bottom">
                        <div className="infos">
                            {/* 2012 <br/>
                                Diesel <br/>
                                156 000 km*/}
                        </div>
                        <div className="location">
                            {/*<span className="pro-label">PRO</span>*/}
                            {this.item?.address.city}
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default Card;
