import React, {Fragment} from 'react';

import './message.css'
import datas from "../../assets/data/discussion.json"
import Footer from '../../components/footer/Footer';
import imgProfil from "../../assets/img/categories_job.jpg"
import useProflImg from "../../assets/img/categories_vehicle.jpg"
import ScrollTop from '../../components/scroll-top/ScrollTop';
import Navbar from '../../components/navbar/Navbar';


class Message extends React.Component {

    constructor(props) {
        super(props);
        this.state = { moreInfo: false };
    }

    componentDidMount() {

        //popup more 
        document.querySelectorAll(".more").forEach((el) => {
            el.addEventListener("click", () => {
                el.parentElement.querySelector(".more-container").style.display = null
                el.parentElement
                    .querySelector("#close-more")
                    .addEventListener("click", () => {
                        el.parentElement.querySelector(".more-container").style.display = "none"
                    })
            })
        })


        //affiche la conversation
        let once = true

        document.querySelectorAll(".msg-item").forEach((el) => {
            el.addEventListener("click", () => {
                //init
                document.querySelector(".top-bar").style.display = "flex"

                //conversation
                document.querySelector(".msg-content").innerHTML = `
      <span class="date">12 juillet</span>
      <p class="bubble bubble-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate purus in mauris blandit velit pulvinar diam. Faucibus montes, sit sit tincidunt ut urna a porttitor. Sodales iaculis euismod etiam erat velit eu mauris. Dui ullamcorper sed vulputate viverra.<span>13:18</span></p>
      <p class="bubble bubble-right">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate purus in mauris blandit velit pulvinar diam. Faucibus montes, sit sit tincidunt ut urna a porttitor. Sodales iaculis euismod etiam erat velit eu mauris. Dui ullamcorper sed vulputate viverra.<span>13:18</span></p>
      <p class="bubble bubble-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate purus in mauris blandit velit pulvinar diam. Faucibus montes, sit sit tincidunt ut urna a porttitor. Sodales iaculis euismod etiam erat velit eu mauris. Dui ullamcorper sed vulputate viverra.<span>13:18</span></p>
      <p class="bubble bubble-right">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate purus in mauris blandit velit pulvinar diam. Faucibus montes, sit sit tincidunt ut urna a porttitor. Sodales iaculis euismod etiam erat velit eu mauris. Dui ullamcorper sed vulputate viverra.<span>13:18</span></p>
      <span class="date">12 juillet</span>
      <p class="bubble bubble-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate purus in mauris blandit velit pulvinar diam. Faucibus montes, sit sit tincidunt ut urna a porttitor. Sodales iaculis euismod etiam erat velit eu mauris. Dui ullamcorper sed vulputate viverra.<span>13:18</span></p>
      <p class="bubble bubble-right">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate purus in mauris blandit velit pulvinar diam. Faucibus montes, sit sit tincidunt ut urna a porttitor. Sodales iaculis euismod etiam erat velit eu mauris. Dui ullamcorper sed vulputate viverra.<span>13:18</span></p>
      <p class="bubble bubble-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate purus in mauris blandit velit pulvinar diam. Faucibus montes, sit sit tincidunt ut urna a porttitor. Sodales iaculis euismod etiam erat velit eu mauris. Dui ullamcorper sed vulputate viverra.<span>13:18</span></p>
      <div class="img img-right">
        <img src="https://via.placeholder.com/150x450">
        <img src="https://via.placeholder.com/300">
        <img src="https://via.placeholder.com/300">
        <img src="https://via.placeholder.com/300">
        <img src="https://via.placeholder.com/300">
        <img src="https://via.placeholder.com/300">
        <img src="https://via.placeholder.com/300">
      </div>
      <p class="bubble bubble-right send seen">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate purus in mauris blandit velit pulvinar diam. Faucibus montes, sit sit tincidunt ut urna a porttitor. Sodales iaculis euismod etiam erat velit eu mauris. Dui ullamcorper sed vulputate viverra. <span>13:18</span></p>
      <span class="writing">Est en train d'écrire ...</span>
    `
                //scroll en bas
                document.querySelector(".msg-content").scrollTop =
                    document.querySelector(".msg-content").scrollHeight

                //previsualiser les images
                document
                    .querySelector(".send-bar")
                    .querySelector("input[type=file]")
                    .addEventListener("change", inputFile)
                function inputFile(e) {
                    once = true
                    const reader = new FileReader()
                    reader.addEventListener("load", () => {
                        const img = document.createElement("div")
                        img.innerHTML = `<img src="${reader.result}">`
                        if (once) {
                            document
                                .querySelector(".send-bar")
                                .querySelector(".preview")
                                .appendChild(img)
                            once = false
                        }
                        document
                            .querySelector(".send-bar")
                            .querySelector(".preview").style.display = null
                        document.querySelector(".msg-content").style.height = "44.5rem"

                        //suppimer la previsualisation
                        img.addEventListener("click", () => {
                            img.remove()
                            if (document.querySelector(".preview").children.length === 0) {
                                document
                                    .querySelector(".send-bar")
                                    .querySelector(".preview").style.display = "none"
                                document.querySelector(".msg-content").style.height = null
                            }
                        })
                    })
                    reader.readAsDataURL(e.target.files[0])
                }

                //ajouter un message
                document
                    .querySelector(".send-bar")
                    .querySelector("button")
                    .addEventListener("click", sendFunction)
                function sendFunction() {
                    document.querySelector(".send-bar").querySelector(".preview").innerHTML =
                        null
                    document
                        .querySelector(".send-bar")
                        .querySelector(".preview").style.display = "none"
                    document.querySelector(".msg-content").style.height = null

                    //ajouter du texte
                    if (
                        document.querySelector(".send-bar").querySelector("input[type=text]")
                            .value != ""
                    ) {
                        const el = document.createElement("p")
                        el.classList = "bubble bubble-right"
                        el.innerText = document
                            .querySelector(".send-bar")
                            .querySelector("input[type=text]").value
                        document.querySelector(".msg-content").appendChild(el)
                        document
                            .querySelector(".send-bar")
                            .querySelector("input[type=text]").value = null
                        document.querySelector(".msg-content").scrollTop =
                            document.querySelector(".msg-content").scrollHeight
                    }
                    //ajouter une image
                    if (
                        document.querySelector(".send-bar").querySelector("input[type=file]")
                            .value != ""
                    ) {
                        const el = document.createElement("div")
                        el.classList = "img img-right"
                        el.innerHTML = `
        <img src="${document.querySelector(".send-bar").querySelector("input[type=file]")
                                .value
                            }">
        `
                        document.querySelector(".msg-content").appendChild(el)
                        document
                            .querySelector(".send-bar")
                            .querySelector("input[type=file]").value = null

                        document.querySelector(".msg-content").scrollTop =
                            document.querySelector(".msg-content").scrollHeight
                    }
                }
            })
        })


        
function report() {
    //open overlay
    document.querySelector(".report").addEventListener("click", () => {
      document.querySelector(".report-filter").style.display = null
    })
  
    //passer à l'étape suivante
    let checked = false
    document
      .querySelector(".step1")
      .querySelector("button")
      .addEventListener("click", (e) => {
        e.preventDefault()
  
        for (
          let i = 0;
          i < document.querySelectorAll("input[type='radio']").length;
          i++
        ) {
          const el = document.querySelectorAll("input[type='radio']")[i]
          if (el.checked) {
            checked = true
            break
          } else {
            checked = false
          }
        }
  
        if (checked) {
          document.querySelector(".step1").style.display = "none"
          document.querySelector(".step2").style.display = null
          document.querySelector(".error-msg").style.display = "none"
        } else {
          document.querySelector(".error-msg").style.display = null
        }
      })
  
    //ajax
    document
      .querySelector(".report-filter")
      .querySelector("button[type='submit']")
      .addEventListener("click", (e) => {
        e.preventDefault()
        const ajax = new XMLHttpRequest()
        ajax.onreadystatechange = () => {
          if (ajax.readyState === 1) {
            document.querySelector(".step2").style.display = "none"
            document.querySelector(".loading").style.display = null
          }
          if (ajax.readyState === 4) {
            if (ajax.status === 200) {
              document.querySelector(".loading").style.display = "none"
              document.querySelector(".success").style.display = null
            } else {
              document.querySelector(".loading").style.display = "none"
              document.querySelector(".success").style.display = "none"
              document.querySelector(".error").style.display = null
            }
          }
        }
        ajax.open("GET", "", true)
        ajax.send()
      })
    //retourner à l'étape précédente
    document
      .querySelector(".step2")
      .querySelector(".step-back")
      .addEventListener("click", (e) => {
        e.preventDefault()
        document.querySelector(".step2").style.display = "none"
        document.querySelector(".step1").style.display = null
      })
  
    //close overlay
    document.querySelector("#close-report").addEventListener("click", () => {
      document.querySelector(".report-filter").style.display = "none"
      document
        .querySelector(".report-filter")
        .querySelectorAll("form>*")
        .forEach((el) => {
          el.style.display = "none"
        })
      document.querySelector(".step1").style.display = null
    })
    document.querySelector(".report-filter").addEventListener("click", (e) => {
      if (e.target === document.querySelector(".report-filter")) {
        document.querySelector(".report-filter").style.display = "none"
      }
    })
  }
  report()
  
  //mobile script
  document.querySelectorAll(".msg-item").forEach((el) => {
    el.addEventListener("click", (e) => {
      if (
        window.innerWidth <= 768 &&
        e.target === el ||
        e.target === el.querySelector("img")
      ) {
        document.querySelector(".msg-left").style.display = "none"
        document.querySelector(".msg-right").style.display = "block"
        document.querySelector(".msg-content").scrollTop =
          document.querySelector(".msg-content").scrollHeight
      }
    })
  })
  
  document.querySelector(".back-arrow").addEventListener("click", () => {
    document.querySelector(".top-bar").style.display = "none"
    if (window.innerWidth <= 768) {
      document.querySelector(".msg-left").style.display = "block"
      document.querySelector(".msg-right").style.display = "none"
    }
  
    document.querySelector(".msg-content").innerHTML = `
    <div class="empty">
    <svg fill="none" viewBox="0 0 268 223" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
    <path d="m134.48 149.76c0.669 0.199 1.334 0.363 2 0.51-1.862 3.108-2.763 6.698-2.591 10.318 0.173 3.62 1.412 7.108 3.561 10.023 2.149 2.916 5.112 5.129 8.515 6.36s7.094 1.426 10.607 0.559c0.954 1.901 2.273 3.596 3.882 4.986 1.608 1.391 3.475 2.451 5.492 3.119s4.147 0.932 6.266 0.776 4.187-0.729 6.085-1.686c1.165-0.585 2.255-1.312 3.244-2.163 2.481 4.1 6.419 7.107 11.022 8.418 4.604 1.312 9.532 0.83 13.796-1.348 4.263-2.178 7.546-5.892 9.189-10.394 1.643-4.503 1.524-9.461-0.333-13.88 0.561 0.212 1.132 0.411 1.728 0.589 3.787 1.137 7.78 1.412 11.687 0.805s7.63-2.082 10.895-4.315c3.265-2.234 5.99-5.17 7.976-8.595 1.986-3.424 3.183-7.25 3.503-11.197s-0.245-7.916-1.654-11.617c-1.408-3.7-3.625-7.038-6.487-9.77-2.863-2.732-6.299-4.789-10.057-6.019-3.758-1.231-7.743-1.605-11.664-1.094-0.51-3.998-2.055-7.792-4.482-11.006-2.428-3.2143-5.653-5.736-9.355-7.3146s-7.752-2.1591-11.748-1.6838c-3.995 0.4752-7.797 1.9898-11.027 4.393-4.322-2.9936-9.581-4.317-14.802-3.7251-5.222 0.5919-10.052 3.0589-13.597 6.9445-3.21-1.995-6.809-3.2801-10.554-3.7697-3.746-0.4896-7.554-0.1724-11.167 0.9305-3.614 1.1032-6.951 2.9662-9.788 5.4652-2.837 2.498-5.108 5.575-6.662 9.024-1.555 3.449-2.355 7.19-2.35 10.974 6e-3 3.784 0.819 7.523 2.383 10.967 1.565 3.444 3.846 6.513 6.691 9.003 2.844 2.49 6.187 4.343 9.804 5.434l-8e-3 -0.021z" fill="#367CFF" opacity=".2"/>
    <path d="m136.41 25.308c-0.695 0-1.378 0.0346-2.06 0.0865 0.893-3.5122 0.728-7.2108-0.475-10.629-1.203-3.4184-3.389-6.4034-6.284-8.5787-2.894-2.1752-6.367-3.4433-9.98-3.6441-3.613-0.20087-7.204 0.67442-10.321 2.5155-1.685-1.788-3.754-3.1683-6.051-4.0362-2.297-0.86789-4.7609-1.2006-7.2051-0.97293-2.4442 0.22768-4.8046 1.0098-6.9022 2.287s-3.8773 3.016-5.2042 5.0846c-3.5496-3.2245-8.1871-4.982-12.979-4.9188-4.7916 0.06328-9.3813 1.9427-12.845 5.2598-3.4634 3.3171-5.5434 7.8254-5.821 12.617-0.2775 4.7916 1.2679 9.5106 4.325 13.207-0.6004-0.039-1.2052-0.0649-1.8143-0.0649-3.9484 0.0094-7.845 0.9014-11.405 2.611-3.5604 1.7097-6.6949 4.1938-9.1748 7.2712-2.48 3.0774-4.2428 6.6704-5.16 10.517-0.9171 3.8466-0.9654 7.8498-0.1414 11.718s2.4995 7.5024 4.9045 10.639c2.4049 3.1365 5.4785 5.6959 8.9965 7.4912 3.5181 1.7954 7.392 2.7816 11.339 2.8866 3.947 0.1051 7.8677-0.6737 11.476-2.2794 1.6358 3.6815 4.2048 6.8704 7.4518 9.2497 3.247 2.3794 7.059 3.8664 11.057 4.3135s8.044-0.1614 11.735-1.765 6.8986-4.1465 9.3046-7.3761c4.997 1.6246 10.413 1.38 15.244-0.6885s8.75-5.8207 11.03-10.562c3.644 0.9772 7.453 1.1648 11.175 0.5505 3.722-0.6142 7.27-2.0164 10.408-4.1129 3.138-2.0964 5.793-4.839 7.789-8.0447 1.996-3.2058 3.286-6.801 3.785-10.546s0.195-7.5531-0.892-11.171c-1.087-3.6178-2.931-6.9618-5.411-9.809-2.479-2.8472-5.536-5.1322-8.966-6.7024-3.431-1.5702-7.157-2.3896-10.929-2.4036z" fill="#367CFF" opacity=".2"/>
    <path d="m153.45 160.26c0 5.718-2.268 11.202-6.305 15.246-4.037 4.043-9.512 6.315-15.221 6.315s-11.185-2.272-15.222-6.315c-4.037-4.044-6.305-9.528-6.305-15.246h43.053z" fill="#fff" stroke="#292A2E" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <path d="m153.44 160.26c-0.081 5.665-2.383 11.07-6.411 15.048s-9.457 6.207-15.113 6.207c-5.657 0-11.086-2.229-15.114-6.207-4.027-3.978-6.33-9.383-6.411-15.048" fill="#fff"/>
    <path d="m153.44 160.26c-0.081 5.665-2.383 11.07-6.411 15.048s-9.457 6.207-15.113 6.207c-5.657 0-11.086-2.229-15.114-6.207-4.027-3.978-6.33-9.383-6.411-15.048" stroke="#292A2E" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <path d="m227.71 160.26v53.788c-1e-3 2.26-0.898 4.427-2.494 6.024-1.596 1.598-3.76 2.495-6.016 2.495h-174.56c-2.2563 0-4.4203-0.897-6.0161-2.495-1.5959-1.597-2.493-3.764-2.4942-6.024v-53.788h74.264c0 5.718 2.268 11.202 6.305 15.246 4.037 4.043 9.512 6.315 15.221 6.315s11.184-2.272 15.221-6.315c4.037-4.044 6.305-9.528 6.305-15.246h74.264z" fill="#fff"/>
    <path d="m219.2 223h-174.56c-2.3699-3e-3 -4.6417-0.948-6.3179-2.626-1.6761-1.678-2.6198-3.953-2.6244-6.326v-53.788c0-0.115 0.0455-0.225 0.1265-0.306s0.1909-0.127 0.3055-0.127h74.264c0.115 0 0.225 0.046 0.306 0.127s0.126 0.191 0.126 0.306c0 5.603 2.223 10.977 6.179 14.94 3.955 3.962 9.321 6.188 14.915 6.188 5.595 0 10.96-2.226 14.916-6.188 3.956-3.963 6.179-9.337 6.179-14.94 0-0.115 0.045-0.225 0.126-0.306s0.191-0.127 0.306-0.127h74.264c0.114 0 0.224 0.046 0.305 0.127s0.127 0.191 0.127 0.306v53.788c-5e-3 2.373-0.949 4.648-2.625 6.326s-3.948 2.623-6.318 2.626zm-182.64-62.307v53.355c0.0023 2.144 0.8542 4.201 2.3688 5.717s3.5681 2.368 5.7095 2.37h174.56c2.142-2e-3 4.195-0.854 5.71-2.37 1.514-1.516 2.366-3.573 2.369-5.717v-53.355h-73.405c-0.114 5.756-2.477 11.238-6.582 15.269-4.104 4.03-9.623 6.288-15.372 6.288-5.748 0-11.267-2.258-15.372-6.288-4.104-4.031-6.467-9.513-6.582-15.269h-73.404z" fill="#292A2E"/>
    <path d="m227.71 160.26h-74.264c0 5.718-2.268 11.202-6.305 15.246-4.037 4.043-9.512 6.315-15.221 6.315s-11.184-2.272-15.221-6.315c-4.037-4.044-6.305-9.528-6.305-15.246h-74.264l54.293-34.209h85.167l52.12 34.209z" fill="#367CFF"/>
    <path d="m227.71 160.26h-74.264c0 5.718-2.268 11.202-6.305 15.246-4.037 4.043-9.512 6.315-15.221 6.315s-11.184-2.272-15.221-6.315c-4.037-4.044-6.305-9.528-6.305-15.246h-74.264l54.293-34.209h85.167l52.12 34.209z" fill="#fff" opacity=".6"/>
    <path d="m131.92 182.25c-5.747-7e-3 -11.263-2.268-15.366-6.297-4.104-4.03-6.468-9.51-6.588-15.264h-73.836c-0.094 0-0.1856-0.031-0.2608-0.087-0.0752-0.057-0.1299-0.136-0.1559-0.227-0.0259-0.091-0.0215-0.187 0.0123-0.275 0.0339-0.088 0.0955-0.162 0.1755-0.212l54.293-34.208c0.0687-0.043 0.1481-0.066 0.2289-0.065h85.168c0.084-1e-3 0.167 0.023 0.238 0.069l52.12 34.208c0.047 0.032 0.088 0.072 0.12 0.12 0.032 0.047 0.054 0.1 0.065 0.156s0.011 0.113 0 0.169-0.033 0.109-0.064 0.157c-0.04 0.059-0.093 0.108-0.155 0.142-0.063 0.034-0.133 0.052-0.204 0.053h-73.836c-0.12 5.754-2.485 11.234-6.588 15.264-4.104 4.029-9.619 6.29-15.366 6.297zm-94.291-22.427h72.765c0.114 0 0.224 0.046 0.305 0.127s0.127 0.191 0.127 0.306c0 5.603 2.222 10.977 6.178 14.94 3.956 3.962 9.321 6.188 14.916 6.188 5.594 0 10.96-2.226 14.916-6.188 3.956-3.963 6.178-9.337 6.178-14.94 0-0.115 0.046-0.225 0.127-0.306s0.19-0.127 0.305-0.127h72.817l-50.803-33.343h-84.912l-52.919 33.343z" fill="#292A2E"/>
    <path d="m61.149 128.83c-0.1145 0-0.2244-0.046-0.3054-0.127-0.0811-0.081-0.1266-0.191-0.1266-0.306 2e-4 -0.088 0.0274-0.174 0.0778-0.247 0.0821-0.112 7.8882-11.418-0.8251-21.725-1.5426-1.835-3.4672-3.309-5.6388-4.319-2.1716-1.011-4.5377-1.533-6.9322-1.531h-0.1167c0.8295 2.882 0.6783 5.443-0.4924 7.408-0.4994 0.888-1.2362 1.618-2.1276 2.11-0.8914 0.491-1.9019 0.724-2.9181 0.672-0.8385-0.075-1.6373-0.391-2.2992-0.912s-1.1583-1.223-1.4289-2.022c-0.959-2.501 0.0432-4.933 2.6827-6.49 1.6718-0.926 3.5311-1.4618 5.4388-1.5665-0.6186-1.6478-1.4246-3.2187-2.4019-4.6817-3.1493-4.8159-8.8861-8.1476-15.738-9.1428-5.8104-0.8654-11.374 0.1471-14.187 2.5312-0.0425 0.0381-0.0922 0.0674-0.146 0.0863-0.0539 0.0188-0.1109 0.0269-0.1679 0.0237-0.0569-0.0032-0.1127-0.0177-0.1641-0.0425s-0.0974-0.0595-0.1354-0.1021-0.0673-0.0923-0.0861-0.1462c-0.0189-0.054-0.0269-0.1111-0.0237-0.1682 0.0032-0.057 0.0176-0.1129 0.0424-0.1644 0.0247-0.0514 0.0594-0.0975 0.1019-0.1356 2.9894-2.5356 8.8257-3.6086 14.865-2.7259 7.102 1.0341 13.055 4.5043 16.338 9.5191 1.0687 1.5912 1.9392 3.3073 2.592 5.1101 2.5825-0.0614 5.1459 0.4575 7.5016 1.5195s4.4437 2.639 6.1105 4.616c9.1539 10.817 0.9547 22.669 0.864 22.786-0.0411 0.054-0.0947 0.099-0.1563 0.129s-0.1295 0.045-0.198 0.044zm-14.752-28.225c-1.8495 0.079-3.656 0.584-5.279 1.476-2.2766 1.35-3.1233 3.336-2.3155 5.443 0.2198 0.647 0.6228 1.215 1.1598 1.636 0.5369 0.421 1.1847 0.677 1.8642 0.735 0.8542 0.038 1.7021-0.164 2.4489-0.581s1.3632-1.034 1.7803-1.781c1.0713-1.805 1.1707-4.202 0.3413-6.928z" fill="#292A2E"/>
    <path d="m6.3376 95.456c-0.7312 0.2154-1.5174 0.1364-2.1913-0.2203-0.67389-0.3566-1.1822-0.9626-1.4166-1.689-0.23442-0.7264-0.17648-1.5157 0.16148-2.2 0.33797-0.6842 0.92926-1.2092 1.648-1.4633s1.508-0.2171 2.1999 0.1031c0.69189 0.3201 1.2317 0.8982 1.5044 1.611 0.27271 0.7129 0.25683 1.5042-0.04428 2.2055s-0.86364 1.2571-1.5678 1.5492c-0.09504 0.0389-0.1944 0.0735-0.29376 0.1038zm-1.4385-4.8072c-0.39176 0.1197-0.73936 0.3529-0.99897 0.6701-0.25961 0.3173-0.4196 0.7045-0.45979 1.1127-0.04018 0.4083 0.04123 0.8193 0.23397 1.1812 0.19274 0.362 0.48818 0.6587 0.84906 0.8527 0.36088 0.1939 0.77102 0.2765 1.1787 0.2373 0.40768-0.0392 0.79465-0.1984 1.1121-0.4576 0.31744-0.2592 0.55112-0.6068 0.67159-0.9989 0.12046-0.3921 0.12231-0.8111 0.0053-1.2042-0.02159-0.0692-0.04751-0.1385-0.07343-0.2077-0.19751-0.4759-0.56492-0.861-1.0306-1.0802-0.46566-0.2192-0.99614-0.2567-1.488-0.1054z" fill="#292A2E"/>
    <path d="m6.3329 88.676c-0.69493 0.208-1.4421 0.1512-2.0977-0.1596-0.6556-0.3107-1.1732-0.8534-1.4533-1.5236-0.22403-0.5368-0.28412-1.1282-0.17267-1.6992 0.11146-0.5711 0.38945-1.0962 0.79884-1.509s0.93179-0.6948 1.5012-0.8103c0.56937-0.1154 1.1601-0.0592 1.6976 0.1615 0.53748 0.2208 0.99754 0.5963 1.322 1.0789 0.32447 0.4827 0.49878 1.0508 0.5009 1.6327 0.00212 0.5818-0.16805 1.1513-0.489 1.6362-0.32094 0.485-0.77825 0.8638-1.3141 1.0885-0.09504 0.039-0.19439 0.0736-0.29375 0.1039zm-1.4342-4.8072c-0.07207 0.0199-0.14278 0.0445-0.21168 0.0736-0.50607 0.2139-0.90696 0.6199-1.115 1.1291s-0.20633 1.0802 0.00479 1.5882c0.15467 0.3704 0.41322 0.6879 0.74435 0.9142s0.72065 0.3516 1.1214 0.3607c0.40075 0.0092 0.79555-0.0982 1.1366-0.3092 0.34108-0.2109 0.6138-0.5163 0.78516-0.8793s0.234-0.7679 0.18036-1.1658-0.22128-0.7717-0.48262-1.0761c-0.26134-0.3045-0.60518-0.5265-0.9899-0.6393-0.38472-0.1127-0.79383-0.1114-1.1778 0.0039h0.00432z" fill="#292A2E"/>
    <path d="m5.4947 91.739c2.121 0 3.8404-1.1507 3.8404-2.5702s-1.7194-2.5702-3.8404-2.5702c-2.121 0-3.8404 1.1507-3.8404 2.5702s1.7194 2.5702 3.8404 2.5702z" fill="#fff"/>
    <path d="m7.1922 91.925c-0.55081 0.1645-1.123 0.2462-1.6977 0.2423-2.3976 0-4.2724-1.2981-4.2767-2.9986-0.00432-1.7004 1.8748-3.0028 4.2724-3.0288 1.0435-0.017 2.0696 0.2685 2.9548 0.8221 0.39746 0.2079 0.73045 0.5208 0.96282 0.9049s0.35523 0.8246 0.35523 1.2737-0.12286 0.8896-0.35523 1.2737-0.56536 0.697-0.96282 0.9049c-0.38519 0.2632-0.80743 0.4674-1.2528 0.6058zm-3.1363-4.6904c-1.1534 0.3462-1.9742 1.0947-1.9742 1.9341 0 1.1596 1.5638 2.1375 3.4127 2.1332 0.87503 0.0208 1.737-0.2153 2.4796-0.6793 0.27832-0.1284 0.51409-0.3339 0.67935-0.5924 0.16526-0.2584 0.2531-0.5589 0.2531-0.8658s-0.08784-0.6074-0.2531-0.8658-0.40103-0.464-0.67935-0.5924c-0.74167-0.4621-1.6021-0.6967-2.4753-0.675-0.48837-0.0045-0.97468 0.0641-1.4428 0.2034z" fill="#292A2E"/>
    <path d="m6.4369 91.657-0.09072-4.9975-1.2441-0.0476-0.0864 5.1057 1.4213-0.0606z" fill="#292A2E"/>
    <path d="m0.39743 90.255c0.2195 0 0.39743-0.1782 0.39743-0.3981 0-0.2198-0.17794-0.3981-0.39743-0.3981-0.2195 0-0.39743 0.1783-0.39743 0.3981 0 0.2199 0.17794 0.3981 0.39743 0.3981z" fill="#292A2E"/>
    <path d="m0.70407 88.13c0.2195 0 0.39744-0.1783 0.39744-0.3981s-0.17794-0.3981-0.39744-0.3981c-0.2195 0-0.39743 0.1783-0.39743 0.3981s0.17794 0.3981 0.39743 0.3981z" fill="#292A2E"/>
    <path d="m102.03 42.867 86.399 39.461-26.274 57.699c-0.408 0.895-1.154 1.591-2.074 1.936-0.92 0.344-1.939 0.31-2.833-0.097l-79.651-36.376c-0.4437-0.202-0.8433-0.49-1.1758-0.847s-0.5915-0.776-0.762-1.234c-0.1705-0.457-0.2493-0.944-0.2319-1.431 0.0175-0.488 0.1309-0.968 0.3337-1.412l26.269-57.699z" fill="#367CFF"/>
    <path d="m102.03 42.867 86.399 39.461-26.274 57.699c-0.408 0.895-1.154 1.591-2.074 1.936-0.92 0.344-1.939 0.31-2.833-0.097l-79.651-36.376c-0.4437-0.202-0.8433-0.49-1.1758-0.847s-0.5915-0.776-0.762-1.234c-0.1705-0.457-0.2493-0.944-0.2319-1.431 0.0175-0.488 0.1309-0.968 0.3337-1.412l26.269-57.699z" fill="#fff"/>
    <path d="m158.78 142.64c-0.597 1e-3 -1.186-0.127-1.728-0.376l-79.629-36.376c-0.9995-0.457-1.7771-1.292-2.1619-2.322-0.3848-1.031-0.3453-2.172 0.1099-3.173l26.269-57.7c0.047-0.1043 0.134-0.1856 0.241-0.2262 0.106-0.0405 0.225-0.037 0.329 0.0098l86.399 39.457c0.052 0.0233 0.099 0.0568 0.138 0.0984 0.039 0.0417 0.07 0.0907 0.09 0.1442s0.029 0.1105 0.027 0.1676-0.015 0.1133-0.039 0.1652l-26.274 57.687c-0.329 0.726-0.86 1.342-1.528 1.775-0.669 0.433-1.447 0.666-2.243 0.669zm-56.535-99.194-26.088 57.306c-0.3601 0.791-0.3933 1.692-0.0922 2.508 0.301 0.815 0.9119 1.478 1.6992 1.845l79.655 36.389c0.392 0.179 0.815 0.279 1.246 0.294 0.431 0.016 0.86-0.054 1.264-0.205 0.403-0.152 0.773-0.381 1.088-0.675 0.315-0.295 0.569-0.648 0.748-1.041l26.088-57.306-85.608-39.115z" fill="#292A2E"/>
    <path d="m188.8 82.12-27.017-50.365c-0.393-0.7295-1.014-1.3104-1.767-1.654-0.754-0.3437-1.598-0.4314-2.406-0.2498l-55.662 12.596c-0.056 0.0123-0.109 0.0355-0.156 0.0683-0.046 0.0328-0.086 0.0745-0.117 0.1228-0.03 0.0483-0.051 0.1021-0.06 0.1584-0.01 0.0564-8e-3 0.114 4e-3 0.1697 0.017 0.0642 0.047 0.1244 0.087 0.1774l30.481 37.142c0.882 1.1022 2 1.9924 3.271 2.6049s2.663 0.9319 4.073 0.9345h0.147l48.737-1.0644c0.074-0.0017 0.146-0.0222 0.21-0.0597 0.063-0.0375 0.116-0.0906 0.153-0.1544 0.038-0.0638 0.058-0.136 0.06-0.2099 1e-3 -0.0739-0.016-0.1469-0.051-0.2121l0.013-0.0043z" fill="#292A2E"/>
    <path d="m194.66 116.12c-0.096 4e-3 -0.19-0.024-0.268-0.079-0.079-0.056-0.136-0.136-0.164-0.228-1.432-5.356-1.814-10.94-1.123-16.442 1.114-8.0481 5.547-18.606 20.304-23.236 2.076-0.6702 4.207-1.1596 6.367-1.4625 0.696-4.3529 3.344-9.0865 9.266-12.332 14.066-7.7408 23.57-0.7961 23.661-0.7269 0.046 0.0341 0.085 0.0771 0.114 0.1263 0.029 0.0493 0.048 0.1039 0.056 0.1607 8e-3 0.0567 5e-3 0.1145-9e-3 0.17-0.015 0.0555-0.04 0.1075-0.075 0.1531-0.069 0.0918-0.171 0.1525-0.284 0.1687-0.114 0.0163-0.229-0.0133-0.321-0.0822-0.09-0.0692-9.167-6.672-22.727 0.7875-5.529 3.0289-8.065 7.4164-8.782 11.475 6.514-0.6447 9.633 1.5317 11.033 3.1759 0.866 0.9886 1.423 2.2109 1.601 3.5144 0.177 1.3035-0.033 2.6305-0.603 3.8154-0.45 0.9577-1.179 1.7562-2.092 2.29-0.912 0.5338-1.965 0.7779-3.019 0.6999-3.637-0.2163-6.648-3.2798-7.668-7.8101-0.337-1.5334-0.427-3.111-0.267-4.673-2.033 0.2926-4.037 0.7542-5.992 1.3803-11.513 3.6043-18.144 11.185-19.721 22.53-0.676 5.3798-0.304 10.841 1.098 16.079 0.016 0.054 0.022 0.112 0.016 0.169-5e-3 0.056-0.022 0.111-0.049 0.162-0.027 0.05-0.063 0.094-0.107 0.13-0.045 0.036-0.095 0.063-0.15 0.08-0.041 6e-3 -0.084 6e-3 -0.125 0l0.03 4e-3zm25.92-40.673c-0.182 1.5323-0.104 3.0843 0.229 4.5909 0.933 4.1451 3.633 6.923 6.877 7.135 0.884 0.0645 1.766-0.1409 2.531-0.5889s1.377-1.1176 1.754-1.9207c0.496-1.0308 0.679-2.185 0.525-3.319-0.153-1.1339-0.636-2.1976-1.389-3.0588-2.047-2.3582-5.732-3.3188-10.536-2.8212l9e-3 -0.0173z" fill="#292A2E"/>
    <path d="m262.8 65.981c-0.583-1e-3 -1.154-0.167-1.646-0.4789-0.493-0.3119-0.887-0.7571-1.138-1.284s-0.347-1.114-0.279-1.6937c0.068-0.5796 0.299-1.1281 0.665-1.582 0.367-0.4539 0.854-0.7948 1.405-0.9832 0.552-0.1885 1.145-0.2168 1.712-0.0818 0.567 0.1351 1.084 0.428 1.492 0.845 0.408 0.4169 0.689 0.9409 0.813 1.5115 0.171 0.802 0.019 1.6396-0.425 2.3291s-1.142 1.1745-1.943 1.3487c-0.216 0.0447-0.436 0.0679-0.656 0.0693zm0-5.3178c-0.59 0.0011-1.154 0.2364-1.571 0.6542-0.416 0.4178-0.65 0.9839-0.65 1.5741 0 0.1573 0.017 0.314 0.052 0.4673 0.089 0.4106 0.292 0.7875 0.586 1.0871 0.294 0.2997 0.667 0.5098 1.075 0.6061s0.835 0.0748 1.232-0.0619c0.396-0.1368 0.746-0.3832 1.009-0.7109 0.262-0.3276 0.427-0.7229 0.474-1.1403 0.048-0.4174-0.024-0.8397-0.206-1.2181-0.182-0.3783-0.468-0.6972-0.824-0.9198-0.355-0.2226-0.767-0.3397-1.186-0.3378h9e-3z" fill="#292A2E"/>
    <path d="m261.5 73.042c-0.797 0.0039-1.564-0.2981-2.145-0.844-0.58-0.5459-0.929-1.294-0.976-2.0902-0.046-0.7962 0.214-1.5799 0.728-2.1896 0.513-0.6097 1.24-0.999 2.032-1.0876 0.791-0.0887 1.586 0.1301 2.221 0.6111s1.062 1.1877 1.193 1.9745c0.13 0.7868-0.046 1.5937-0.491 2.2546-0.446 0.6609-1.128 1.1253-1.906 1.2976-0.215 0.0482-0.435 0.0729-0.656 0.0736zm0-5.3178c-0.457 0.0011-0.903 0.1442-1.275 0.4096s-0.653 0.6399-0.804 1.072-0.164 0.9004-0.038 1.3403c0.127 0.44 0.386 0.8298 0.743 1.1157s0.793 0.4539 1.25 0.4807c0.456 0.0268 0.909-0.0888 1.297-0.331s0.691-0.5989 0.868-1.021c0.176-0.4221 0.218-0.8887 0.119-1.3355-0.107-0.4915-0.379-0.9315-0.77-1.2464-0.392-0.315-0.879-0.486-1.382-0.4844h-8e-3z" fill="#292A2E"/>
    <path d="m266.16 67.257c0.272-1.4781-1.298-3.0077-3.509-3.4164-2.21-0.4086-4.222 0.4583-4.495 1.9364-0.272 1.4782 1.299 3.0077 3.509 3.4164s4.222-0.4583 4.495-1.9364z" fill="#fff"/>
    <path d="m262.74 69.715c-0.391-0.0013-0.782-0.0375-1.166-0.1082-1.087-0.1819-2.097-0.6765-2.908-1.4236-0.363-0.2937-0.641-0.6805-0.803-1.1194-0.163-0.4389-0.204-0.9136-0.119-1.3739 0.085-0.4602 0.293-0.8888 0.601-1.2406 0.309-0.3517 0.706-0.6134 1.151-0.7574 1.027-0.3883 2.141-0.4879 3.22-0.2881 1.08 0.1998 2.085 0.6914 2.905 1.4218 0.266 0.2086 0.488 0.4676 0.653 0.7621 0.166 0.2946 0.272 0.619 0.312 0.9546s0.013 0.6758-0.078 1.0012-0.246 0.6295-0.455 0.895c-0.327 0.4111-0.771 0.7129-1.274 0.8654-0.645 0.2736-1.339 0.4134-2.039 0.4111zm-1.192-5.5558c-0.593-0.011-1.181 0.101-1.728 0.3289-0.441 0.1142-0.82 0.3984-1.053 0.7906s-0.302 0.8607-0.191 1.3036c0.101 0.397 0.34 0.745 0.674 0.9822 0.699 0.6091 1.549 1.0191 2.461 1.1873s1.852 0.0884 2.722-0.2311c0.308-0.1022 0.585-0.2795 0.807-0.516s0.381-0.5246 0.464-0.8383c0.035-0.324-0.01-0.6517-0.133-0.9538-0.122-0.302-0.317-0.569-0.567-0.7769-0.689-0.6272-1.544-1.0406-2.463-1.1899-0.335-0.0619-0.674-0.0938-1.015-0.0952l0.022 0.0086z" fill="#292A2E"/>
    <path d="m261.64 63.731-0.869 5.2182 1.283 0.2942 1.076-5.3004-1.49-0.212z" fill="#292A2E"/>
    <path d="m266.85 69.347c0.231 0 0.419-0.188 0.419-0.4198s-0.188-0.4197-0.419-0.4197c-0.232 0-0.419 0.1879-0.419 0.4197s0.187 0.4198 0.419 0.4198z" fill="#292A2E"/>
    <path d="m267.58 67.193c0.232 0 0.419-0.1879 0.419-0.4197s-0.187-0.4198-0.419-0.4198c-0.231 0-0.419 0.188-0.419 0.4198s0.188 0.4197 0.419 0.4197z" fill="#292A2E"/>
    </g>
    <defs>
    <clipPath id="a">
    <rect width="268" height="223" fill="#fff"/>
    </clipPath>
    </defs>
    </svg>
    Bienvenue sur l'espace messagerie
    </div>`
  })
  

    }

    moreDiscution() {
        this.state.setState({ moreInfo: true });
    }

    render() {
        return <Fragment>
            <Navbar />
            <main class="container-lg mailbox">
                <div class="report-filter " style={{ display: "none" }}>
                    <div class="report-overlay">
                        <div class="report-head">
                            Signaler cet utilisateur
                            <svg
                                id="close-report"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </div>
                        <form action="" method="POST">
                            <div class="step1">
                                Pourquoi signalez-vous cet utilisateur ?
                                <div class="report-input">
                                    <label for="field1">Attitude discriminatoire</label>
                                    <input
                                        type="radio"
                                        name="reason"
                                        id="field1"
                                        value="Attitude discriminatoire"
                                        required
                                    />
                                </div>
                                <div class="report-input">
                                    <label for="field2">Comportement innaproprié</label>
                                    <input
                                        type="radio"
                                        name="reason"
                                        id="field2"
                                        value="Comportement innaproprié"
                                    />
                                </div>
                                <div class="report-input">
                                    <label for="field3">Menace</label>
                                    <input type="radio" name="reason" id="field3" value="Menace" />
                                </div>
                                <div class="report-input">
                                    <label for="field4">Compte piraté</label>
                                    <input
                                        type="radio"
                                        name="reason"
                                        id="field4"
                                        value="Compte piraté"
                                    />
                                </div>
                                <div class="report-input">
                                    <label for="field5">Autre</label>
                                    <input type="radio" name="reason" id="field5" value="Autre" />
                                </div>
                                <span class="error-msg" style={{ display: "none" }}
                                >Veuillez selectionner une raison</span
                                >
                                <button class="btn btn-primary">Passer à l'étape suivante</button>
                            </div>
                            <div class="step2" style={{ display: "none" }}>
                                Souhaitez-vous donner des précisions ?
                                <textarea
                                    placeholder="Description..."
                                    name="comment"
                                    cols="30"
                                    rows="10"
                                ></textarea>
                                <button type="submit" class="btn btn-primary">
                                    Envoyer mon signalement
                                </button>
                                <button class="step-back">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="19" y1="12" x2="5" y2="12"></line>
                                        <polyline points="12 19 5 12 12 5"></polyline>
                                    </svg>
                                    Retourner à l'étape précédente
                                </button>
                            </div>
                            <div class="loading" style={{ display: "none" }}>Chargement en cours ...</div>
                            <div class="success" style={{ display: "none" }}>
                                <svg
                                    width="205"
                                    height="130"
                                    viewBox="0 0 205 130"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6.92626 56.5497C6.76573 56.5499 6.60674 56.5184 6.45843 56.4569C6.31013 56.3954 6.17543 56.3053 6.06208 56.1916L0.357784 50.4873C0.135123 50.2568 0.0119096 49.948 0.0146946 49.6275C0.0174797 49.307 0.146034 49.0004 0.372668 48.7738C0.599301 48.5472 0.905878 48.4186 1.22637 48.4158C1.54687 48.413 1.85564 48.5362 2.08617 48.7589L7.79047 54.4632C7.96136 54.6341 8.07774 54.8519 8.12489 55.089C8.17203 55.3261 8.14783 55.5718 8.05533 55.7951C7.96283 56.0185 7.8062 56.2093 7.60523 56.3437C7.40426 56.478 7.16798 56.5497 6.92626 56.5497Z"
                                        fill="#0CBC8B"
                                    />
                                    <path
                                        d="M1.22208 56.5502C0.980365 56.5502 0.744097 56.4785 0.543131 56.3442C0.342164 56.2098 0.185526 56.0189 0.0930313 55.7956C0.00053619 55.5723 -0.0236695 55.3266 0.0234735 55.0895C0.0706165 54.8524 0.187 54.6346 0.357896 54.4637L6.06219 48.7594C6.29273 48.5367 6.60149 48.4135 6.92199 48.4163C7.24248 48.4191 7.54906 48.5476 7.77569 48.7743C8.00233 49.0009 8.13088 49.3075 8.13367 49.628C8.13645 49.9485 8.01324 50.2572 7.79058 50.4878L2.08629 56.1921C1.97295 56.3058 1.83827 56.396 1.68995 56.4574C1.54164 56.5189 1.38263 56.5504 1.22208 56.5502Z"
                                        fill="#0CBC8B"
                                    />
                                    <path
                                        d="M170.252 111.071L168.624 114.806L178.644 119.174L180.272 115.439L170.252 111.071Z"
                                        fill="#0CBC8B"
                                    />
                                    <path
                                        d="M173.415 10.9363L163.99 16.4744L166.054 19.9873L175.479 14.4492L173.415 10.9363Z"
                                        fill="#0CBC8B"
                                    />
                                    <path
                                        d="M16.3638 20.4201L15.1016 24.2942L25.4956 27.6808L26.7579 23.8068L16.3638 20.4201Z"
                                        fill="#0CBC8B"
                                    />
                                    <path
                                        d="M28.532 85.3964L19.5791 91.6602L21.9138 94.9972L30.8667 88.7334L28.532 85.3964Z"
                                        fill="#0CBC8B"
                                    />
                                    <path
                                        d="M192.74 71.0384L192.366 75.0957L203.014 76.0773L203.388 72.02L192.74 71.0384Z"
                                        fill="#0CBC8B"
                                    />
                                    <path
                                        d="M203.583 41.8535C203.423 41.8537 203.264 41.8222 203.115 41.7607C202.967 41.6992 202.832 41.609 202.719 41.4953L197.015 35.791C196.898 35.6783 196.805 35.5434 196.741 35.3943C196.677 35.2451 196.643 35.0847 196.642 34.9224C196.64 34.7601 196.671 34.5992 196.733 34.4489C196.794 34.2987 196.885 34.1622 197 34.0475C197.114 33.9327 197.251 33.8419 197.401 33.7805C197.551 33.719 197.712 33.6881 197.875 33.6895C198.037 33.6909 198.197 33.7246 198.346 33.7887C198.496 33.8528 198.63 33.9459 198.743 34.0626L204.447 39.7669C204.618 39.9379 204.735 40.1556 204.782 40.3927C204.829 40.6298 204.805 40.8755 204.712 41.0989C204.62 41.3222 204.463 41.5131 204.262 41.6474C204.061 41.7817 203.825 41.8534 203.583 41.8535Z"
                                        fill="#0CBC8B"
                                    />
                                    <path
                                        d="M197.878 41.8532C197.637 41.8532 197.4 41.7815 197.199 41.6471C196.998 41.5128 196.842 41.3219 196.749 41.0986C196.657 40.8753 196.633 40.6296 196.68 40.3925C196.727 40.1554 196.843 39.9376 197.014 39.7667L202.718 34.0624C202.831 33.9456 202.966 33.8525 203.115 33.7885C203.264 33.7244 203.425 33.6907 203.587 33.6893C203.749 33.6878 203.91 33.7188 204.061 33.7802C204.211 33.8417 204.347 33.9325 204.462 34.0472C204.577 34.162 204.668 34.2985 204.729 34.4487C204.79 34.5989 204.821 34.7599 204.82 34.9222C204.819 35.0845 204.785 35.2449 204.721 35.394C204.657 35.5431 204.564 35.678 204.447 35.7908L198.743 41.4951C198.629 41.6087 198.494 41.6989 198.346 41.7604C198.198 41.8218 198.039 41.8534 197.878 41.8532Z"
                                        fill="#0CBC8B"
                                    />
                                    <path
                                        opacity="0.4"
                                        d="M103.027 130C138.925 130 168.027 100.899 168.027 65C168.027 29.1015 138.925 0 103.027 0C67.1284 0 38.0269 29.1015 38.0269 65C38.0269 100.899 67.1284 130 103.027 130Z"
                                        fill="#0CBC8B"
                                    />
                                    <path
                                        d="M103.027 110.544C128.181 110.544 148.571 90.1532 148.571 64.9998C148.571 39.8464 128.181 19.4556 103.027 19.4556C77.8738 19.4556 57.4829 39.8464 57.4829 64.9998C57.4829 90.1532 77.8738 110.544 103.027 110.544Z"
                                        fill="#0CBC8B"
                                    />
                                    <path
                                        d="M94.0541 84.898C93.6977 84.8982 93.3449 84.8255 93.017 84.6841C92.6892 84.5428 92.393 84.3357 92.1465 84.0755L77.6968 68.8771C77.4516 68.625 77.2584 68.3261 77.1282 67.998C76.9981 67.6698 76.9337 67.3188 76.9387 66.9653C76.9438 66.6118 77.0181 66.2629 77.1575 65.9386C77.2969 65.6143 77.4985 65.3213 77.7508 65.0763C78.003 64.8313 78.3008 64.6394 78.6269 64.5115C78.953 64.3837 79.301 64.3225 79.6507 64.3315C80.0004 64.3405 80.3449 64.4195 80.6641 64.564C80.9834 64.7084 81.2712 64.9155 81.5107 65.1731L94.1901 78.5089L127.339 48.4556C127.596 48.2173 127.899 48.0329 128.227 47.9133C128.556 47.7936 128.905 47.7409 129.254 47.7584C129.603 47.7758 129.945 47.863 130.261 48.0149C130.576 48.1667 130.859 48.3802 131.092 48.6431C131.326 48.906 131.505 49.213 131.62 49.5464C131.736 49.8798 131.784 50.233 131.764 50.5856C131.743 50.9381 131.654 51.2831 131.501 51.6005C131.347 51.9179 131.133 52.2014 130.871 52.4348L95.8201 84.2133C95.3355 84.6544 94.7063 84.8983 94.0541 84.898Z"
                                        fill="white"
                                    />
                                </svg>
                                <span>
                                    Nous avons bien reçu votre signalement. Il sera traité par notre
                                    équipe dans les meilleurs délais.
                                </span>
                                <span class="secondary">
                                    Les signalements sont anonymes et nous aident à améliorer la
                                    sécurité de Trouvare.
                                </span>
                            </div>
                            <div class="error" style={{ display: "none" }}>
                                <svg
                                    width="229"
                                    height="222"
                                    viewBox="0 0 229 222"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M102.825 8.9596C122.595 14.4384 134.423 32.8883 152.64 42.2761C175.973 54.3011 211.131 48.4487 223.807 71.3081C236.217 93.6883 224.369 123.89 209.431 144.694C196.191 163.133 168.182 161.071 149.761 174.43C131.253 187.851 125.748 221.889 102.825 222C79.934 222.111 71.4974 191.189 55.5556 174.886C43.1249 162.173 28.5598 152.525 19.7998 137.091C9.15462 118.335 -1.19198 98.6859 0.111445 77.1985C1.65775 51.7071 6.26871 21.8442 27.6524 7.63779C49.0006 -6.54508 78.0818 2.10228 102.825 8.9596Z"
                                        fill="#EBF2FF"
                                    />
                                    <path
                                        d="M114.604 168.775L67.5332 142.069L103.96 78.3818L136.508 96.8484L142.758 119.552L114.604 168.775Z"
                                        fill="white"
                                    />
                                    <path
                                        opacity="0.3"
                                        d="M122.239 137.053C123.788 137.053 125.043 135.802 125.043 134.26C125.043 132.718 123.788 131.468 122.239 131.468C120.691 131.468 119.436 132.718 119.436 134.26C119.436 135.802 120.691 137.053 122.239 137.053Z"
                                        fill="#367CFF"
                                    />
                                    <path
                                        opacity="0.3"
                                        d="M92.6514 122.345C94.1999 122.345 95.4552 121.094 95.4552 119.552C95.4552 118.01 94.1999 116.76 92.6514 116.76C91.103 116.76 89.8477 118.01 89.8477 119.552C89.8477 121.094 91.103 122.345 92.6514 122.345Z"
                                        fill="#367CFF"
                                    />
                                    <path
                                        d="M114.742 169.274L67.0332 142.206L103.823 77.8833L136.822 96.6073L143.152 119.603L114.742 169.274ZM68.0344 141.932L114.467 168.277L142.365 119.503L136.196 97.0913L104.098 78.8808L68.0344 141.932Z"
                                        fill="#262626"
                                    />
                                    <path
                                        opacity="0.3"
                                        d="M136.507 96.8484L128.234 111.313L142.757 119.552L136.507 96.8484Z"
                                        fill="#367CFF"
                                    />
                                    <path
                                        d="M142.759 119.917C142.695 119.917 142.632 119.901 142.577 119.87L128.054 111.63C127.97 111.582 127.908 111.503 127.882 111.409C127.856 111.315 127.869 111.216 127.917 111.131L136.19 96.6665C136.226 96.6036 136.28 96.5528 136.345 96.5204C136.41 96.488 136.483 96.4755 136.555 96.4845C136.627 96.4936 136.695 96.5237 136.749 96.5711C136.804 96.6185 136.844 96.681 136.863 96.7508L143.113 119.453C143.128 119.507 143.13 119.564 143.119 119.62C143.108 119.675 143.085 119.727 143.05 119.771C143.016 119.816 142.972 119.852 142.921 119.877C142.871 119.902 142.815 119.915 142.759 119.915V119.917ZM128.737 111.176L142.17 118.797L136.389 97.7962L128.737 111.176Z"
                                        fill="#292A2E"
                                    />
                                    <path
                                        d="M102.074 115.878C103.296 115.878 104.287 114.891 104.287 113.674C104.287 112.457 103.296 111.47 102.074 111.47C100.852 111.47 99.8613 112.457 99.8613 113.674C99.8613 114.891 100.852 115.878 102.074 115.878Z"
                                        fill="#292A2E"
                                    />
                                    <path
                                        d="M122.83 125.277C124.052 125.277 125.043 124.291 125.043 123.074C125.043 121.856 124.052 120.87 122.83 120.87C121.608 120.87 120.617 121.856 120.617 123.074C120.617 124.291 121.608 125.277 122.83 125.277Z"
                                        fill="#292A2E"
                                    />
                                    <path
                                        d="M113.425 138.795C113.33 138.795 113.238 138.758 113.169 138.692C113.101 138.625 113.061 138.535 113.058 138.44C113.012 136.677 112.515 134.956 111.614 133.438C110.713 131.921 109.438 130.657 107.909 129.768C106.381 128.878 104.65 128.392 102.88 128.356C101.11 128.319 99.3605 128.733 97.7962 129.558C97.7535 129.582 97.7064 129.597 97.6578 129.602C97.6092 129.608 97.5599 129.603 97.513 129.589C97.4661 129.576 97.4224 129.553 97.3846 129.522C97.3467 129.491 97.3154 129.453 97.2925 129.41C97.2697 129.367 97.2557 129.319 97.2514 129.271C97.2471 129.222 97.2526 129.173 97.2676 129.127C97.2826 129.081 97.3067 129.038 97.3386 129.001C97.3704 128.964 97.4094 128.933 97.4532 128.912C99.1279 128.029 101.001 127.586 102.896 127.626C104.79 127.665 106.643 128.186 108.279 129.138C109.915 130.09 111.28 131.442 112.245 133.066C113.21 134.691 113.742 136.533 113.792 138.42C113.795 138.517 113.759 138.611 113.692 138.681C113.625 138.751 113.533 138.792 113.435 138.795H113.425Z"
                                        fill="#292A2E"
                                    />
                                    <path
                                        d="M104.002 111.419C102.81 111.404 101.647 111.051 100.649 110.402C100.57 110.345 100.517 110.261 100.501 110.165C100.485 110.07 100.508 109.973 100.563 109.894C100.619 109.815 100.704 109.762 100.8 109.745C100.895 109.728 100.993 109.75 101.073 109.805C101.102 109.825 103.255 111.297 105.661 110.382C105.752 110.35 105.851 110.355 105.938 110.395C106.025 110.435 106.093 110.507 106.127 110.596C106.161 110.685 106.159 110.784 106.121 110.872C106.083 110.959 106.012 111.029 105.923 111.065C105.31 111.299 104.659 111.42 104.002 111.419Z"
                                        fill="#292A2E"
                                    />
                                    <path
                                        d="M126.666 121.927C126.625 121.926 126.584 121.92 126.545 121.906C123.451 120.833 123.099 118.456 123.085 118.355C123.072 118.258 123.098 118.161 123.157 118.084C123.217 118.006 123.304 117.956 123.401 117.943C123.497 117.93 123.595 117.956 123.673 118.014C123.751 118.073 123.801 118.161 123.814 118.257C123.827 118.343 124.14 120.296 126.789 121.216C126.87 121.244 126.939 121.301 126.983 121.375C127.027 121.449 127.044 121.537 127.029 121.622C127.015 121.707 126.971 121.784 126.904 121.84C126.838 121.896 126.754 121.926 126.668 121.927H126.666Z"
                                        fill="#292A2E"
                                    />
                                    <path
                                        d="M4.74586 36.9005C4.69767 36.9005 4.64995 36.8911 4.60542 36.8727C4.5609 36.8543 4.52045 36.8274 4.48639 36.7934C4.45232 36.7594 4.42531 36.7191 4.40689 36.6748C4.38848 36.6304 4.37902 36.5829 4.37906 36.5349V26.2196C4.37762 26.1707 4.38605 26.1221 4.40384 26.0765C4.42163 26.0309 4.44843 25.9893 4.48265 25.9542C4.51686 25.9191 4.55781 25.8912 4.60305 25.8721C4.64829 25.8531 4.6969 25.8433 4.74602 25.8433C4.79514 25.8433 4.84376 25.8531 4.889 25.8721C4.93424 25.8912 4.97518 25.9191 5.0094 25.9542C5.04362 25.9893 5.07042 26.0309 5.08821 26.0765C5.106 26.1221 5.11442 26.1707 5.11298 26.2196V36.5349C5.11302 36.5829 5.10355 36.6305 5.08512 36.6749C5.06669 36.7192 5.03965 36.7596 5.00555 36.7935C4.97145 36.8275 4.93096 36.8544 4.8864 36.8728C4.84184 36.8911 4.79409 36.9006 4.74586 36.9005Z"
                                        fill="#262626"
                                    />
                                    <path
                                        d="M8.1258 31.743H1.36712C1.26975 31.743 1.17637 31.7045 1.10753 31.6359C1.03868 31.5673 1 31.4743 1 31.3774C1 31.2804 1.03868 31.1874 1.10753 31.1188C1.17637 31.0502 1.26975 31.0117 1.36712 31.0117H8.1258C8.22317 31.0117 8.31654 31.0502 8.38539 31.1188C8.45424 31.1874 8.49292 31.2804 8.49292 31.3774C8.49292 31.4743 8.45424 31.5673 8.38539 31.6359C8.31654 31.7045 8.22317 31.743 8.1258 31.743Z"
                                        fill="#262626"
                                    />
                                    <path
                                        d="M110 60.8351C109.952 60.8351 109.904 60.8256 109.859 60.8073C109.815 60.7889 109.774 60.7619 109.74 60.728C109.706 60.694 109.679 60.6537 109.661 60.6094C109.642 60.565 109.633 60.5175 109.633 60.4695V50.1542C109.632 50.1053 109.64 50.0566 109.658 50.011C109.676 49.9654 109.702 49.9239 109.737 49.8888C109.771 49.8537 109.812 49.8258 109.857 49.8067C109.902 49.7877 109.951 49.7778 110 49.7778C110.049 49.7778 110.098 49.7877 110.143 49.8067C110.188 49.8258 110.229 49.8537 110.263 49.8888C110.298 49.9239 110.324 49.9654 110.342 50.011C110.36 50.0566 110.368 50.1053 110.367 50.1542V60.4695C110.367 60.5175 110.357 60.565 110.339 60.6094C110.321 60.6538 110.294 60.6941 110.259 60.7281C110.225 60.762 110.185 60.789 110.14 60.8073C110.096 60.8257 110.048 60.8351 110 60.8351Z"
                                        fill="#262626"
                                    />
                                    <path
                                        d="M113.38 55.6776H106.621C106.524 55.6776 106.43 55.639 106.361 55.5705C106.293 55.5019 106.254 55.4089 106.254 55.3119C106.254 55.215 106.293 55.122 106.361 55.0534C106.43 54.9848 106.524 54.9463 106.621 54.9463H113.38C113.477 54.9463 113.57 54.9848 113.639 55.0534C113.708 55.122 113.747 55.215 113.747 55.3119C113.747 55.4089 113.708 55.5019 113.639 55.5705C113.57 55.639 113.477 55.6776 113.38 55.6776Z"
                                        fill="#262626"
                                    />
                                    <path
                                        d="M181.039 94.3521C180.942 94.3521 180.848 94.3136 180.779 94.245C180.711 94.1765 180.672 94.0834 180.672 93.9865V78.2956C180.672 78.1986 180.711 78.1056 180.779 78.037C180.848 77.9685 180.942 77.9299 181.039 77.9299C181.136 77.9299 181.23 77.9685 181.299 78.037C181.367 78.1056 181.406 78.1986 181.406 78.2956V93.9865C181.406 94.0834 181.367 94.1764 181.299 94.2449C181.23 94.3135 181.136 94.352 181.039 94.3521Z"
                                        fill="#262626"
                                    />
                                    <path
                                        d="M186.18 86.5067H175.898C175.801 86.5067 175.708 86.4681 175.639 86.3996C175.57 86.331 175.531 86.238 175.531 86.141C175.531 86.0441 175.57 85.9511 175.639 85.8825C175.708 85.8139 175.801 85.7754 175.898 85.7754H186.18C186.277 85.7754 186.37 85.8139 186.439 85.8825C186.508 85.9511 186.547 86.0441 186.547 86.141C186.547 86.238 186.508 86.331 186.439 86.3996C186.37 86.4681 186.277 86.5067 186.18 86.5067Z"
                                        fill="#262626"
                                    />
                                    <path
                                        d="M206.062 44.7459C206.014 44.7459 205.966 44.7365 205.922 44.7182C205.877 44.6998 205.837 44.6729 205.803 44.6389C205.769 44.6049 205.742 44.5646 205.723 44.5202C205.705 44.4759 205.695 44.4283 205.695 44.3803V36.5348C205.695 36.4379 205.734 36.3449 205.803 36.2763C205.872 36.2077 205.965 36.1692 206.062 36.1692C206.16 36.1692 206.253 36.2077 206.322 36.2763C206.391 36.3449 206.43 36.4379 206.43 36.5348V44.3803C206.43 44.4283 206.42 44.4759 206.402 44.5202C206.383 44.5646 206.356 44.6049 206.322 44.6389C206.288 44.6729 206.248 44.6998 206.203 44.7182C206.158 44.7365 206.111 44.7459 206.062 44.7459Z"
                                        fill="#262626"
                                    />
                                    <path
                                        d="M208.633 40.8233H203.492C203.444 40.8233 203.396 40.8139 203.352 40.7955C203.307 40.7771 203.267 40.7502 203.233 40.7162C203.198 40.6823 203.171 40.642 203.153 40.5976C203.134 40.5532 203.125 40.5057 203.125 40.4577C203.125 40.4097 203.134 40.3621 203.153 40.3178C203.171 40.2734 203.198 40.2331 203.233 40.1991C203.267 40.1652 203.307 40.1382 203.352 40.1199C203.396 40.1015 203.444 40.092 203.492 40.092H208.633C208.73 40.092 208.823 40.1306 208.892 40.1991C208.961 40.2677 209 40.3607 209 40.4577C209 40.5546 208.961 40.6476 208.892 40.7162C208.823 40.7848 208.73 40.8233 208.633 40.8233Z"
                                        fill="#262626"
                                    />
                                    <path
                                        d="M35.9569 110.769C35.9087 110.769 35.861 110.759 35.8164 110.741C35.7718 110.723 35.7314 110.696 35.6973 110.662C35.6632 110.628 35.6361 110.588 35.6177 110.543C35.5993 110.499 35.5898 110.451 35.5898 110.403V102.558C35.5898 102.461 35.6285 102.368 35.6974 102.299C35.7662 102.231 35.8596 102.192 35.9569 102.192C36.0543 102.192 36.1477 102.231 36.2165 102.299C36.2854 102.368 36.3241 102.461 36.3241 102.558V110.403C36.3241 110.451 36.3146 110.499 36.2962 110.543C36.2778 110.588 36.2507 110.628 36.2166 110.662C36.1825 110.696 36.1421 110.723 36.0975 110.741C36.0529 110.759 36.0052 110.769 35.9569 110.769Z"
                                        fill="#262626"
                                    />
                                    <path
                                        d="M38.5272 106.846H33.3866C33.3384 106.846 33.2907 106.837 33.2462 106.818C33.2016 106.8 33.1611 106.773 33.1271 106.739C33.093 106.705 33.0659 106.665 33.0475 106.621C33.029 106.576 33.0195 106.529 33.0195 106.481C33.0195 106.433 33.029 106.385 33.0475 106.341C33.0659 106.296 33.093 106.256 33.1271 106.222C33.1611 106.188 33.2016 106.161 33.2462 106.143C33.2907 106.124 33.3384 106.115 33.3866 106.115H38.5272C38.6246 106.115 38.718 106.154 38.7868 106.222C38.8557 106.291 38.8943 106.384 38.8943 106.481C38.8943 106.578 38.8557 106.671 38.7868 106.739C38.718 106.808 38.6246 106.846 38.5272 106.846Z"
                                        fill="#262626"
                                    />
                                </svg>
                                <span>Oups ! Une erreur est survenue ! </span>
                                <span class="secondary"
                                >Malheureusement, une erreur s’est produite. Vérifiez votre réseau
                                    et essayez à nouveau. <br />
                                    <br />
                                    Si l'erreur persiste, merci de nous
                                    <a href="/src/pages/contact.html">contacter</a>.</span
                                >
                            </div>
                        </form>
                    </div>
                </div>
                <section class="navigation">
                    <a href="/" class="back"> Retour</a>
                    <span id="notice_number"></span>
                </section>
                <h1>Ma messagerie</h1>
                <div class="msg-container">
                    <div class="msg-left">
                        <div class="msg-header">Mes discussions</div>
                        <div class="msg-list">

                            {
                                datas.map((data,index) => <div key={index} class="msg-item ">
                                    <img src={useProflImg} alt="" />
                                    <div>
                                        {data.nom}
                                        <span class="state"> Annonce en ligne </span>
                                    </div>
                                    <svg
                                        class="more"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="12" cy="5" r="1"></circle>
                                        <circle cx="12" cy="19" r="1"></circle>
                                    </svg>
                                    <div class="more-container" style={{ display: "none" }}>
                                        <svg
                                            id="close-more"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                        <span class="report">Signaler cet utilisateur</span>
                                        <span>Bloquer cet utilisateur</span>
                                        <span onclick="this.parentElement.parentElement.remove()"
                                        >Supprimer la conversation</span
                                        >
                                        <hr />
                                        <span>Noter l'utilisateur :</span>
                                        <div class="stars">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <polygon
                                                    points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
                                                />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <polygon
                                                    points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
                                                />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <polygon
                                                    points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
                                                />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <polygon
                                                    points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
                                                />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <polygon
                                                    points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>)
                            }






                        </div>
                    </div>
                    <div class="msg-right">
                        <div class="top-bar">
                            <svg
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                class="back-arrow"
                            >
                                <line x1="19" y1="12" x2="5" y2="12"></line>
                                <polyline points="12 19 5 12 12 5"></polyline>
                            </svg>
                            <div class="user">
                                <img src={imgProfil} alt="photo de profil" />
                                Utilisateur 1
                            </div>
                        </div>
                        <div class="msg-content">
                            <div class="empty">
                                <svg
                                    width="268"
                                    height="223"
                                    viewBox="0 0 268 223"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0)">
                                        <path
                                            opacity="0.2"
                                            d="M134.48 149.763C135.149 149.962 135.814 150.126 136.48 150.273C134.618 153.381 133.717 156.971 133.889 160.591C134.062 164.211 135.301 167.699 137.45 170.614C139.599 173.53 142.562 175.743 145.965 176.974C149.368 178.205 153.059 178.4 156.572 177.533C157.526 179.434 158.845 181.129 160.454 182.519C162.062 183.91 163.929 184.97 165.946 185.638C167.963 186.306 170.093 186.57 172.212 186.414C174.331 186.258 176.399 185.685 178.297 184.728C179.462 184.143 180.552 183.416 181.541 182.565C184.022 186.665 187.96 189.672 192.563 190.983C197.167 192.295 202.095 191.813 206.359 189.635C210.622 187.457 213.905 183.743 215.548 179.241C217.191 174.738 217.072 169.78 215.215 165.361C215.776 165.573 216.347 165.772 216.943 165.95C220.73 167.087 224.723 167.362 228.63 166.755C232.537 166.148 236.26 164.673 239.525 162.44C242.79 160.206 245.515 157.27 247.501 153.845C249.487 150.421 250.684 146.595 251.004 142.648C251.324 138.701 250.759 134.732 249.35 131.031C247.942 127.331 245.725 123.993 242.863 121.261C240 118.529 236.564 116.472 232.806 115.242C229.048 114.011 225.063 113.637 221.142 114.148C220.632 110.15 219.087 106.356 216.66 103.142C214.232 99.9277 211.007 97.406 207.305 95.8274C203.603 94.2488 199.553 93.6683 195.557 94.1436C191.562 94.6188 187.76 96.1334 184.53 98.5366C180.208 95.543 174.949 94.2196 169.728 94.8115C164.506 95.4034 159.676 97.8704 156.131 101.756C152.921 99.761 149.322 98.4759 145.577 97.9863C141.831 97.4967 138.023 97.8139 134.41 98.9168C130.796 100.02 127.459 101.883 124.622 104.382C121.785 106.88 119.514 109.957 117.96 113.406C116.405 116.855 115.605 120.596 115.61 124.38C115.616 128.164 116.429 131.903 117.993 135.347C119.558 138.791 121.839 141.86 124.684 144.35C127.528 146.84 130.871 148.693 134.488 149.784L134.48 149.763Z"
                                            fill="#367CFF"
                                        />
                                        <path
                                            opacity="0.2"
                                            d="M136.41 25.308C135.715 25.308 135.032 25.3426 134.35 25.3945C135.243 21.8823 135.078 18.1837 133.875 14.7654C132.672 11.347 130.486 8.36199 127.591 6.18674C124.697 4.0115 121.224 2.74348 117.611 2.54262C113.998 2.34175 110.407 3.21704 107.29 5.05808C105.605 3.27007 103.536 1.88979 101.239 1.02189C98.942 0.154001 96.4781 -0.17872 94.0339 0.0489587C91.5897 0.276637 89.2293 1.05874 87.1317 2.33596C85.0341 3.61318 83.2544 5.352 81.9275 7.42057C78.3779 4.19611 73.7404 2.43853 68.9489 2.50181C64.1573 2.56509 59.5676 4.44452 56.1042 7.7616C52.6408 11.0787 50.5608 15.587 50.2832 20.3787C50.0057 25.1703 51.5511 29.8893 54.6082 33.5854C54.0078 33.5464 53.403 33.5205 52.7939 33.5205C48.8455 33.5299 44.9489 34.4219 41.3885 36.1315C37.8281 37.8412 34.6936 40.3253 32.2137 43.4027C29.7337 46.4801 27.9709 50.0731 27.0537 53.9197C26.1366 57.7663 26.0883 61.7695 26.9123 65.6372C27.7363 69.5049 29.4118 73.1396 31.8168 76.2762C34.2217 79.4127 37.2953 81.9721 40.8133 83.7674C44.3314 85.5628 48.2053 86.549 52.1523 86.654C56.0993 86.7591 60.02 85.9803 63.6283 84.3746C65.2641 88.0561 67.8331 91.245 71.0801 93.6243C74.3271 96.0037 78.1391 97.4907 82.1375 97.9378C86.1359 98.3849 90.1815 97.7764 93.8724 96.1728C97.5633 94.5692 100.771 92.0263 103.177 88.7967C108.174 90.4213 113.59 90.1767 118.421 88.1082C123.252 86.0397 127.171 82.2875 129.451 77.5467C133.095 78.5239 136.904 78.7115 140.626 78.0972C144.348 77.483 147.896 76.0808 151.034 73.9843C154.172 71.8879 156.827 69.1453 158.823 65.9396C160.819 62.7338 162.109 59.1386 162.608 55.3938C163.107 51.649 162.803 47.8407 161.716 44.223C160.629 40.6052 158.785 37.2612 156.305 34.414C153.826 31.5668 150.769 29.2818 147.339 27.7116C143.908 26.1414 140.182 25.322 136.41 25.308Z"
                                            fill="#367CFF"
                                        />
                                        <path
                                            d="M153.449 160.26C153.449 165.978 151.181 171.462 147.144 175.506C143.107 179.549 137.632 181.821 131.923 181.821C126.214 181.821 120.738 179.549 116.701 175.506C112.664 171.462 110.396 165.978 110.396 160.26H153.449Z"
                                            fill="white"
                                            stroke="#292A2E"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M153.445 160.26C153.364 165.925 151.062 171.33 147.034 175.308C143.006 179.286 137.577 181.515 131.921 181.515C126.264 181.515 120.835 179.286 116.807 175.308C112.78 171.33 110.477 165.925 110.396 160.26"
                                            fill="white"
                                        />
                                        <path
                                            d="M153.445 160.26C153.364 165.925 151.062 171.33 147.034 175.308C143.006 179.286 137.577 181.515 131.921 181.515C126.264 181.515 120.835 179.286 116.807 175.308C112.78 171.33 110.477 165.925 110.396 160.26"
                                            stroke="#292A2E"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M227.712 160.26V214.048C227.711 216.308 226.814 218.475 225.218 220.072C223.622 221.67 221.458 222.567 219.202 222.567H44.6421C42.3858 222.567 40.2218 221.67 38.626 220.072C37.0301 218.475 36.133 216.308 36.1318 214.048V160.26H110.396C110.396 165.978 112.664 171.462 116.701 175.506C120.738 179.549 126.213 181.821 131.922 181.821C137.631 181.821 143.106 179.549 147.143 175.506C151.18 171.462 153.448 165.978 153.448 160.26H227.712Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M219.202 223H44.6425C42.2726 222.997 40.0008 222.052 38.3246 220.374C36.6485 218.696 35.7048 216.421 35.7002 214.048V160.26C35.7002 160.145 35.7457 160.035 35.8267 159.954C35.9077 159.873 36.0176 159.827 36.1322 159.827H110.396C110.511 159.827 110.621 159.873 110.702 159.954C110.783 160.035 110.828 160.145 110.828 160.26C110.828 165.863 113.051 171.237 117.007 175.2C120.962 179.162 126.328 181.388 131.922 181.388C137.517 181.388 142.882 179.162 146.838 175.2C150.794 171.237 153.017 165.863 153.017 160.26C153.017 160.145 153.062 160.035 153.143 159.954C153.224 159.873 153.334 159.827 153.449 159.827H227.713C227.827 159.827 227.937 159.873 228.018 159.954C228.099 160.035 228.145 160.145 228.145 160.26V214.048C228.14 216.421 227.196 218.696 225.52 220.374C223.844 222.052 221.572 222.997 219.202 223ZM36.5642 160.693V214.048C36.5665 216.192 37.4184 218.249 38.933 219.765C40.4476 221.281 42.5011 222.133 44.6425 222.135H219.202C221.344 222.133 223.397 221.281 224.912 219.765C226.426 218.249 227.278 216.192 227.281 214.048V160.693H153.876C153.762 166.449 151.399 171.931 147.294 175.962C143.19 179.992 137.671 182.25 131.922 182.25C126.174 182.25 120.655 179.992 116.55 175.962C112.446 171.931 110.083 166.449 109.968 160.693H36.5642Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M227.712 160.26H153.448C153.448 165.978 151.18 171.462 147.143 175.506C143.106 179.549 137.631 181.821 131.922 181.821C126.213 181.821 120.738 179.549 116.701 175.506C112.664 171.462 110.396 165.978 110.396 160.26H36.1318L90.4248 126.051H175.592L227.712 160.26Z"
                                            fill="#367CFF"
                                        />
                                        <path
                                            opacity="0.6"
                                            d="M227.712 160.26H153.448C153.448 165.978 151.18 171.462 147.143 175.506C143.106 179.549 137.631 181.821 131.922 181.821C126.213 181.821 120.738 179.549 116.701 175.506C112.664 171.462 110.396 165.978 110.396 160.26H36.1318L90.4248 126.051H175.592L227.712 160.26Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M131.922 182.254C126.175 182.247 120.659 179.986 116.556 175.957C112.452 171.927 110.088 166.447 109.968 160.693H36.1316C36.0376 160.693 35.946 160.662 35.8708 160.606C35.7956 160.549 35.7409 160.47 35.7149 160.379C35.689 160.288 35.6934 160.192 35.7272 160.104C35.7611 160.016 35.8227 159.942 35.9027 159.892L90.1956 125.684C90.2643 125.641 90.3437 125.618 90.4245 125.619H175.592C175.676 125.618 175.759 125.642 175.83 125.688L227.95 159.896C227.997 159.928 228.038 159.968 228.07 160.016C228.102 160.063 228.124 160.116 228.135 160.172C228.146 160.228 228.146 160.285 228.135 160.341C228.124 160.397 228.102 160.45 228.071 160.498C228.031 160.557 227.978 160.606 227.916 160.64C227.853 160.674 227.783 160.692 227.712 160.693H153.876C153.756 166.447 151.391 171.927 147.288 175.957C143.184 179.986 137.669 182.247 131.922 182.254ZM37.6306 159.827H110.396C110.51 159.827 110.62 159.873 110.701 159.954C110.782 160.035 110.828 160.145 110.828 160.26C110.828 165.863 113.05 171.237 117.006 175.2C120.962 179.162 126.327 181.388 131.922 181.388C137.516 181.388 142.882 179.162 146.838 175.2C150.794 171.237 153.016 165.863 153.016 160.26C153.016 160.145 153.062 160.035 153.143 159.954C153.224 159.873 153.333 159.827 153.448 159.827H226.265L175.462 126.484H90.5498L37.6306 159.827Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M61.1486 128.834C61.0341 128.834 60.9242 128.788 60.8432 128.707C60.7621 128.626 60.7166 128.516 60.7166 128.401C60.7168 128.313 60.744 128.227 60.7944 128.154C60.8765 128.042 68.6826 116.736 59.9693 106.429C58.4267 104.594 56.5021 103.12 54.3305 102.11C52.1589 101.099 49.7928 100.577 47.3983 100.579H47.2816C48.1111 103.461 47.9599 106.022 46.7892 107.987C46.2898 108.875 45.553 109.605 44.6616 110.097C43.7702 110.588 42.7597 110.821 41.7435 110.769C40.905 110.694 40.1062 110.378 39.4443 109.857C38.7824 109.336 38.286 108.634 38.0154 107.835C37.0564 105.334 38.0586 102.902 40.6981 101.345C42.3699 100.419 44.2292 99.8832 46.1369 99.7785C45.5183 98.1307 44.7123 96.5598 43.735 95.0968C40.5857 90.2809 34.8489 86.9492 27.9975 85.954C22.1871 85.0886 16.6231 86.1011 13.8108 88.4852C13.7683 88.5233 13.7186 88.5526 13.6648 88.5715C13.6109 88.5903 13.5539 88.5984 13.4969 88.5952C13.44 88.592 13.3842 88.5775 13.3328 88.5527C13.2814 88.5279 13.2354 88.4932 13.1974 88.4506C13.1594 88.408 13.1301 88.3583 13.1113 88.3044C13.0924 88.2504 13.0844 88.1933 13.0876 88.1362C13.0908 88.0792 13.1052 88.0233 13.13 87.9718C13.1547 87.9204 13.1894 87.8743 13.2319 87.8362C16.2213 85.3006 22.0576 84.2276 28.0968 85.1103C35.1988 86.1444 41.1517 89.6146 44.4348 94.6294C45.5035 96.2206 46.374 97.9367 47.0268 99.7395C49.6093 99.6781 52.1727 100.197 54.5284 101.259C56.8841 102.321 58.9721 103.898 60.6389 105.875C69.7928 116.692 61.5936 128.544 61.5029 128.661C61.4618 128.715 61.4082 128.76 61.3466 128.79C61.285 128.82 61.2171 128.835 61.1486 128.834ZM46.3961 100.609C44.5466 100.688 42.7401 101.193 41.1171 102.085C38.8405 103.435 37.9938 105.421 38.8016 107.528C39.0214 108.175 39.4244 108.743 39.9614 109.164C40.4983 109.585 41.1461 109.841 41.8256 109.899C42.6798 109.937 43.5277 109.735 44.2745 109.318C45.0213 108.901 45.6377 108.284 46.0548 107.537C47.1261 105.732 47.2255 103.335 46.3961 100.609Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M6.33759 95.4558C5.60639 95.6712 4.82014 95.5922 4.14626 95.2355C3.47237 94.8789 2.9641 94.2729 2.72967 93.5465C2.49525 92.8201 2.55319 92.0308 2.89115 91.3465C3.22912 90.6623 3.82041 90.1373 4.53912 89.8832C5.25783 89.6291 6.04717 89.6661 6.73906 89.9863C7.43095 90.3064 7.97073 90.8845 8.24345 91.5973C8.51616 92.3102 8.50028 93.1015 8.19917 93.8028C7.89806 94.5041 7.33553 95.0599 6.63135 95.352C6.53631 95.3909 6.43695 95.4255 6.33759 95.4558ZM4.89906 90.6486C4.5073 90.7683 4.1597 91.0015 3.90009 91.3187C3.64048 91.636 3.48049 92.0232 3.4403 92.4314C3.40012 92.8397 3.48153 93.2507 3.67427 93.6126C3.86701 93.9746 4.16245 94.2713 4.52333 94.4653C4.88421 94.6592 5.29435 94.7418 5.70204 94.7026C6.10972 94.6634 6.49669 94.5042 6.81412 94.245C7.13156 93.9858 7.36524 93.6382 7.48571 93.2461C7.60617 92.854 7.60802 92.435 7.49101 92.0419C7.46942 91.9727 7.4435 91.9034 7.41758 91.8342C7.22007 91.3583 6.85266 90.9732 6.38701 90.754C5.92135 90.5348 5.39087 90.4973 4.89906 90.6486Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M6.33292 88.6756C5.63799 88.8836 4.89079 88.8268 4.23519 88.516C3.57959 88.2053 3.06195 87.6626 2.78193 86.9924C2.5579 86.4556 2.49781 85.8642 2.60926 85.2932C2.72072 84.7221 2.99871 84.197 3.4081 83.7842C3.81749 83.3714 4.33989 83.0894 4.90926 82.9739C5.47863 82.8585 6.0694 82.9147 6.60687 83.1354C7.14435 83.3562 7.60441 83.7317 7.92888 84.2143C8.25335 84.697 8.42766 85.2651 8.42978 85.847C8.4319 86.4288 8.26173 86.9983 7.94078 87.4832C7.61984 87.9682 7.16253 88.347 6.62667 88.5717C6.53163 88.6107 6.43228 88.6453 6.33292 88.6756ZM4.8987 83.8684C4.82663 83.8883 4.75592 83.9129 4.68702 83.942C4.18095 84.1559 3.78006 84.5619 3.57201 85.0711C3.36396 85.5803 3.36568 86.1513 3.5768 86.6593C3.73147 87.0297 3.99002 87.3472 4.32115 87.5735C4.65228 87.7998 5.0418 87.9251 5.44255 87.9342C5.8433 87.9434 6.2381 87.836 6.57918 87.625C6.92026 87.4141 7.19298 87.1087 7.36434 86.7457C7.5357 86.3827 7.59834 85.9778 7.5447 85.5799C7.49106 85.182 7.32342 84.8082 7.06208 84.5038C6.80074 84.1993 6.4569 83.9773 6.07218 83.8645C5.68746 83.7518 5.27835 83.7531 4.89438 83.8684H4.8987Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M5.49472 91.739C7.61572 91.739 9.33514 90.5883 9.33514 89.1688C9.33514 87.7493 7.61572 86.5986 5.49472 86.5986C3.37371 86.5986 1.6543 87.7493 1.6543 89.1688C1.6543 90.5883 3.37371 91.739 5.49472 91.739Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M7.19225 91.9251C6.64144 92.0896 6.06928 92.1713 5.49451 92.1674C3.09695 92.1674 1.2221 90.8693 1.21778 89.1688C1.21346 87.4684 3.09263 86.166 5.49019 86.14C6.53367 86.123 7.5598 86.4085 8.44503 86.9621C8.84249 87.17 9.17548 87.4829 9.40785 87.867C9.64022 88.2511 9.76308 88.6916 9.76308 89.1407C9.76308 89.5898 9.64022 90.0303 9.40785 90.4144C9.17548 90.7985 8.84249 91.1114 8.44503 91.3193C8.05984 91.5825 7.6376 91.7867 7.19225 91.9251ZM4.05598 87.2347C2.90255 87.5809 2.08177 88.3294 2.08177 89.1688C2.08177 90.3284 3.64558 91.3063 5.49451 91.302C6.36954 91.3228 7.23152 91.0867 7.97416 90.6227C8.25248 90.4943 8.48825 90.2888 8.65351 90.0303C8.81877 89.7719 8.90661 89.4714 8.90661 89.1645C8.90661 88.8576 8.81877 88.5571 8.65351 88.2987C8.48825 88.0403 8.25248 87.8347 7.97416 87.7063C7.23249 87.2442 6.37203 87.0096 5.49883 87.0313C5.01046 87.0268 4.52415 87.0954 4.05598 87.2347Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M6.43688 91.6567L6.34616 86.6592L5.10202 86.6116L5.01562 91.7173L6.43688 91.6567Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M0.397434 90.2549C0.61693 90.2549 0.794868 90.0767 0.794868 89.8568C0.794868 89.637 0.61693 89.4587 0.397434 89.4587C0.177937 89.4587 0 89.637 0 89.8568C0 90.0767 0.177937 90.2549 0.397434 90.2549Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M0.704074 88.1304C0.923571 88.1304 1.10151 87.9521 1.10151 87.7323C1.10151 87.5125 0.923571 87.3342 0.704074 87.3342C0.484578 87.3342 0.306641 87.5125 0.306641 87.7323C0.306641 87.9521 0.484578 88.1304 0.704074 88.1304Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M102.028 42.8667L188.427 82.3281L162.153 140.027C161.745 140.922 160.999 141.618 160.079 141.963C159.159 142.307 158.14 142.273 157.246 141.866L77.5949 105.49C77.1512 105.288 76.7516 105 76.4191 104.643C76.0866 104.286 75.8276 103.867 75.6571 103.409C75.4866 102.952 75.4078 102.465 75.4252 101.978C75.4427 101.49 75.5561 101.01 75.7589 100.566L102.028 42.8667Z"
                                            fill="#367CFF"
                                        />
                                        <path
                                            d="M102.028 42.8667L188.427 82.3281L162.153 140.027C161.745 140.922 160.999 141.618 160.079 141.963C159.159 142.307 158.14 142.273 157.246 141.866L77.5949 105.49C77.1512 105.288 76.7516 105 76.4191 104.643C76.0866 104.286 75.8276 103.867 75.6571 103.409C75.4866 102.952 75.4078 102.465 75.4252 101.978C75.4427 101.49 75.5561 101.01 75.7589 100.566L102.028 42.8667Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M158.775 142.636C158.178 142.637 157.589 142.509 157.047 142.26L77.4176 105.884C76.4181 105.427 75.6405 104.592 75.2557 103.562C74.8709 102.531 74.9104 101.39 75.3656 100.389L101.635 42.6893C101.682 42.585 101.769 42.5037 101.876 42.4631C101.982 42.4226 102.101 42.4261 102.205 42.4729L188.604 81.93C188.656 81.9533 188.703 81.9868 188.742 82.0284C188.781 82.0701 188.812 82.1191 188.832 82.1726C188.852 82.2261 188.861 82.2831 188.859 82.3402C188.857 82.3973 188.844 82.4535 188.82 82.5054L162.546 140.192C162.217 140.918 161.686 141.534 161.018 141.967C160.349 142.4 159.571 142.633 158.775 142.636ZM102.24 43.4421L76.1518 100.748C75.7917 101.539 75.7585 102.44 76.0596 103.256C76.3606 104.071 76.9715 104.734 77.7588 105.101L157.414 141.49C157.806 141.669 158.229 141.769 158.66 141.784C159.091 141.8 159.52 141.73 159.924 141.579C160.327 141.427 160.697 141.198 161.012 140.904C161.327 140.609 161.581 140.256 161.76 139.863L187.848 82.5574L102.24 43.4421Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M188.803 82.1203L161.786 31.7551C161.393 31.0256 160.772 30.4447 160.019 30.1011C159.265 29.7574 158.421 29.6697 157.613 29.8513L101.951 42.4469C101.895 42.4592 101.842 42.4824 101.795 42.5152C101.749 42.548 101.709 42.5897 101.678 42.638C101.648 42.6863 101.627 42.7401 101.618 42.7964C101.608 42.8528 101.61 42.9104 101.622 42.9661C101.639 43.0303 101.669 43.0905 101.709 43.1435L132.19 80.2857C133.072 81.3879 134.19 82.2781 135.461 82.8906C136.732 83.5031 138.124 83.8225 139.534 83.8251H139.681L188.418 82.7607C188.492 82.759 188.564 82.7385 188.628 82.701C188.691 82.6635 188.744 82.6104 188.781 82.5466C188.819 82.4828 188.839 82.4106 188.841 82.3367C188.842 82.2628 188.825 82.1898 188.79 82.1246L188.803 82.1203Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M194.664 116.121C194.568 116.125 194.474 116.097 194.396 116.042C194.317 115.986 194.26 115.906 194.232 115.814C192.8 110.458 192.418 104.874 193.109 99.3717C194.223 91.3236 198.656 80.766 213.413 76.1362C215.489 75.466 217.62 74.9766 219.78 74.6737C220.476 70.3208 223.124 65.5872 229.046 62.342C243.112 54.6012 252.616 61.5459 252.707 61.6151C252.753 61.6492 252.792 61.6922 252.821 61.7414C252.85 61.7907 252.869 61.8453 252.877 61.9021C252.885 61.9588 252.882 62.0166 252.868 62.0721C252.853 62.1276 252.828 62.1796 252.793 62.2252C252.724 62.317 252.622 62.3777 252.509 62.3939C252.395 62.4102 252.28 62.3806 252.188 62.3117C252.098 62.2425 243.021 55.6397 229.461 63.0992C223.932 66.1281 221.396 70.5156 220.679 74.5742C227.193 73.9295 230.312 76.1059 231.712 77.7501C232.578 78.7387 233.135 79.961 233.313 81.2645C233.49 82.568 233.28 83.895 232.71 85.0799C232.26 86.0376 231.531 86.8361 230.618 87.3699C229.706 87.9037 228.653 88.1478 227.599 88.0698C223.962 87.8535 220.951 84.79 219.931 80.2597C219.594 78.7263 219.504 77.1487 219.664 75.5867C217.631 75.8793 215.627 76.3409 213.672 76.967C202.159 80.5713 195.528 88.152 193.951 99.4972C193.275 104.877 193.647 110.338 195.049 115.576C195.065 115.63 195.071 115.688 195.065 115.745C195.06 115.801 195.043 115.856 195.016 115.907C194.989 115.957 194.953 116.001 194.909 116.037C194.864 116.073 194.814 116.1 194.759 116.117C194.718 116.123 194.675 116.123 194.634 116.117L194.664 116.121ZM220.584 75.4482C220.402 76.9805 220.48 78.5325 220.813 80.0391C221.746 84.1842 224.446 86.9621 227.69 87.1741C228.574 87.2386 229.456 87.0332 230.221 86.5852C230.986 86.1372 231.598 85.4676 231.975 84.6645C232.471 83.6337 232.654 82.4795 232.5 81.3455C232.347 80.2116 231.864 79.1479 231.111 78.2867C229.064 75.9285 225.379 74.9679 220.575 75.4655L220.584 75.4482Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M262.804 65.9811C262.221 65.9801 261.65 65.8141 261.158 65.5022C260.665 65.1903 260.271 64.7451 260.02 64.2182C259.769 63.6913 259.673 63.1042 259.741 62.5245C259.809 61.9449 260.04 61.3964 260.406 60.9425C260.773 60.4886 261.26 60.1477 261.811 59.9593C262.363 59.7708 262.956 59.7425 263.523 59.8775C264.09 60.0126 264.607 60.3055 265.015 60.7225C265.423 61.1394 265.704 61.6634 265.828 62.234C265.999 63.036 265.847 63.8736 265.403 64.5631C264.959 65.2526 264.261 65.7376 263.46 65.9118C263.244 65.9565 263.024 65.9797 262.804 65.9811ZM262.804 60.6633C262.214 60.6644 261.65 60.8997 261.233 61.3175C260.817 61.7353 260.583 62.3014 260.583 62.8916C260.583 63.0489 260.6 63.2056 260.635 63.3589C260.724 63.7695 260.927 64.1464 261.221 64.446C261.515 64.7457 261.888 64.9558 262.296 65.0521C262.704 65.1484 263.131 65.1269 263.528 64.9902C263.924 64.8534 264.274 64.607 264.537 64.2793C264.799 63.9517 264.964 63.5564 265.011 63.139C265.059 62.7216 264.987 62.2993 264.805 61.9209C264.623 61.5426 264.337 61.2237 263.981 61.0011C263.626 60.7785 263.214 60.6614 262.795 60.6633H262.804Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M261.503 73.0425C260.706 73.0464 259.939 72.7444 259.358 72.1985C258.778 71.6526 258.429 70.9045 258.382 70.1083C258.336 69.3121 258.596 68.5284 259.11 67.9187C259.623 67.309 260.35 66.9197 261.142 66.8311C261.933 66.7424 262.728 66.9612 263.363 67.4422C263.998 67.9232 264.425 68.6299 264.556 69.4167C264.686 70.2035 264.51 71.0104 264.065 71.6713C263.619 72.3322 262.937 72.7966 262.159 72.9689C261.944 73.0171 261.724 73.0418 261.503 73.0425ZM261.503 67.7247C261.046 67.7258 260.6 67.8689 260.228 68.1343C259.856 68.3997 259.575 68.7742 259.424 69.2063C259.273 69.6384 259.26 70.1067 259.386 70.5466C259.513 70.9866 259.772 71.3764 260.129 71.6623C260.486 71.9482 260.922 72.1162 261.379 72.143C261.835 72.1698 262.288 72.0542 262.676 71.812C263.064 71.5698 263.367 71.2131 263.544 70.791C263.72 70.3689 263.762 69.9023 263.663 69.4555C263.556 68.964 263.284 68.524 262.893 68.2091C262.501 67.8941 262.014 67.7231 261.511 67.7247H261.503Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M266.162 67.2567C266.434 65.7786 264.864 64.249 262.653 63.8403C260.443 63.4317 258.431 64.2986 258.158 65.7767C257.886 67.2549 259.457 68.7844 261.667 69.1931C263.877 69.6018 265.889 68.7348 266.162 67.2567Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M262.738 69.7151C262.347 69.7138 261.956 69.6776 261.572 69.6069C260.485 69.425 259.475 68.9304 258.664 68.1833C258.301 67.8896 258.023 67.5028 257.861 67.0639C257.698 66.625 257.657 66.1503 257.742 65.69C257.827 65.2298 258.035 64.8012 258.343 64.4494C258.652 64.0977 259.049 63.836 259.494 63.692C260.521 63.3037 261.635 63.2041 262.714 63.4039C263.794 63.6037 264.799 64.0953 265.619 64.8257C265.885 65.0343 266.107 65.2933 266.272 65.5878C266.438 65.8824 266.544 66.2068 266.584 66.5424C266.624 66.878 266.597 67.2182 266.506 67.5436C266.415 67.869 266.26 68.1731 266.051 68.4386C265.724 68.8497 265.28 69.1515 264.777 69.304C264.132 69.5776 263.438 69.7174 262.738 69.7151ZM261.546 64.1593C260.953 64.1483 260.365 64.2603 259.818 64.4882C259.377 64.6024 258.998 64.8866 258.765 65.2788C258.532 65.671 258.463 66.1395 258.574 66.5824C258.675 66.9794 258.914 67.3274 259.248 67.5646C259.947 68.1737 260.797 68.5837 261.709 68.7519C262.621 68.9201 263.561 68.8403 264.431 68.5208C264.739 68.4186 265.016 68.2413 265.238 68.0048C265.46 67.7683 265.619 67.4802 265.702 67.1665C265.737 66.8425 265.692 66.5148 265.569 66.2127C265.447 65.9107 265.252 65.6437 265.002 65.4358C264.313 64.8086 263.458 64.3952 262.539 64.2459C262.204 64.184 261.865 64.1521 261.524 64.1507L261.546 64.1593Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M261.641 63.731L260.772 68.9492L262.055 69.2434L263.131 63.943L261.641 63.731Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M266.851 69.3474C267.082 69.3474 267.27 69.1594 267.27 68.9276C267.27 68.6958 267.082 68.5079 266.851 68.5079C266.619 68.5079 266.432 68.6958 266.432 68.9276C266.432 69.1594 266.619 69.3474 266.851 69.3474Z"
                                            fill="#292A2E"
                                        />
                                        <path
                                            d="M267.581 67.1926C267.813 67.1926 268 67.0047 268 66.7729C268 66.5411 267.813 66.3531 267.581 66.3531C267.35 66.3531 267.162 66.5411 267.162 66.7729C267.162 67.0047 267.35 67.1926 267.581 67.1926Z"
                                            fill="#292A2E"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect width="268" height="223" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                Bienvenue sur l'espace messagerie
                            </div>
                        </div>
                        <div class="send-bar">
                            <div class="preview" style={{ display: "none" }}></div>
                            <div class="interaction">
                                <div class="file">
                                    <label for="file">
                                        <svg
                                            viewBox="0 0 24 24"
                                            width="32"
                                            height="32"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path
                                                d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"
                                            ></path>
                                        </svg>
                                    </label>
                                    <input type="file" multiple name="img" id="file" accept="image/*" />
                                </div>
                                <textarea placeholder="Votre message..." name="" id=""></textarea>
                                <button class="btn btn-primary">Envoyer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
            <ScrollTop/>
        </Fragment>;
    }
}


export default Message;
