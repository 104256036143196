import { Component } from "react";
import { Rating } from "primereact/rating"
import './avis.css'
class Avis extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="avis col-md-12 ">
                <div className="row align-items-center justify-content-between">
                    <div className="row p-4 align-items-center a-note">
                        <Rating value={this.props.value} readOnly cancel={false} stars={5} style={{ color: ' #FFD700' }} /> <p>{this.props.name}</p>
                    </div>
                    <div className="m-0">
                        <p className="a-date">
                            {this.props.date}
                        </p>
                    </div>
                </div>
                <div>
                    <p>{this.props.description}</p>
                </div>
            </div>
        )
    }
}

export default Avis