import React, {Fragment} from 'react';

import './forget-password-request.css'
import Footer from '../../components/footer/Footer';
import {Link} from "react-router-dom"
import ScrollTop from '../../components/scroll-top/ScrollTop';
import Navbar from '../../components/navbar/Navbar';
import DefaultMeta from "../../components/seo/DefaultMeta";
import * as Yup from "yup";
import {Formik} from 'formik';
import {getFormErrorMessage} from "../../helpers/utils";
import {Button} from "primereact/button";
import {I18n} from "react-redux-i18n";
import AuthService from "../../services/AuthService";
import {Messages} from "primereact/messages";

class ForgotPasswordRequest extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            requestSent: false
        };

        this.formikProps = {
            initialValues: this.emptyElement,
            validationSchema: Yup.object().shape({
                address: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')).email(I18n.t('INVALID_EMAIL'))
            })
        };

        this.handleForgetPassword = this.handleForgetPassword.bind(this);
    }

    emptyElement = {
        address: ''
    };

    handleForgetPassword = (element, {resetForm}) => {
        this.setState({loading: true});
        AuthService.forgotPasswordRequest(element.address)
            .then(() => {
                this.setState({loading: false, requestSent: true});
                this.msgs.show([
                    { severity: 'success', detail: I18n.t('FORGOT_PASSWORD_EMAIL_SENT',{email: element.address}), sticky: true }
                ]);
            })
            .catch(error => {
                this.setState({loading: false, requestSent: true});
            });
    }

    render() {
        return <Fragment>
            <DefaultMeta/>
            <Navbar/>
            <main class="container contact">
                <section className="navigation">
                    <Link to="/" className="back"> {"<"} Retour</Link>
                    <span id="notice_number"></span>
                </section>
                <h1>Mot de passe oublié</h1>
                {this.state.requestSent &&
                <Messages ref={(el) => this.msgs = el} />}
                <Formik
                    enableReinitialize
                    initialValues={this.formikProps.initialValues}
                    validationSchema={this.formikProps.validationSchema}
                    onSubmit={this.handleForgetPassword}
                >
                    {props => {
                        console.log(props)
                        return <form onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                     onSubmit={props.handleSubmit} className="contact-container">
                            Entrez l'adresse e-mail associée à votre compte
                            <div class="fields">
                                <div class="input">
                                    <label for="mail">
                                        Email* :
                                        <div class="btn btn-secondary">
                                            <input
                                                name="address"
                                                value={props.values.address}
                                                onChange={props.handleChange}
                                                id="address"
                                                type="text"
                                            />
                                        </div>
                                        {getFormErrorMessage(props, 'address')}
                                    </label>
                                </div>
                            </div>
                            <Button loading={this.state.loading} type="submit" label="Continuer" className="tButton" />
                        </form>
                    }}
                </Formik>
            </main>
            <Footer/>
            <ScrollTop/>
        </Fragment>;
    }
}


export default ForgotPasswordRequest;
