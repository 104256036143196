import React, {Fragment} from 'react';

import "./item.css"
import Footer from '../../components/footer/Footer';

import categories_job from "../../assets/img/categories_job.jpg"
import logo from "../../assets/img/logo.svg"
import {Link} from "react-router-dom"

import ScrollTop from '../../components/scroll-top/ScrollTop';
import Navbar from '../../components/navbar/Navbar';
import Card from '../../components/card/Card';
import ItemService from "../../services/ItemService";
import moment from "moment";
import {Constant} from "../../helpers/const";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import {I18n} from "react-redux-i18n";
import {getElementData} from "../../components/form/FormElements";
import DefaultMeta from "../../components/seo/DefaultMeta";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {Role} from "../../helpers/role";
import {formatPhoneNumber} from "../../helpers/utils";

import 'primeicons/primeicons.css';
import {Dialog} from 'primereact/dialog';
import {Button} from "primereact/button";
import {setMessage} from "../../actions/message";
import {Toast} from "primereact/toast";


class ItemDetail extends React.Component {

    constructor(props) {
        super(props);
        this.slug = this.props.match.params.slug;
        this.state = {
            similarItems: [],
            item: null,
            details: [],
            deleteItemDialog: false,
            loadingDialog: false
        };
        this.mapContainer = React.createRef();
        this.elementService = new ItemService();
        this.mapContainer = React.createRef();
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.elementService.findBySlug(this.slug).then(
            response => {
                let item = response.data.content
                item.og_description = this.truncate(item.description)
                this.setState({
                    item: item,
                    details: JSON.parse(response.data.content.details)
                })

                const map = new mapboxgl.Map({
                    accessToken: Constant.MAPBOX_KEY,
                    container: this.mapContainer.current,
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: [response.data.content.position.longitude, response.data.content.position.latitude],
                    zoom: 9,
                    projection: 'globe',
                    cooperativeGestes: true,
                    dragPan: false,
                    dragRotate: false,
                    interactive: false
                });
                map.on('style.load', () => {
                    map.setFog({}); // Set the default atmosphere style
                });

                const marker = new mapboxgl.Marker({color: '#ed6d5f'})
                    .setLngLat([response.data.content.position.longitude, response.data.content.position.latitude])
                    .addTo(map);
                //map.addControl(new mapboxgl.NavigationControl())
            }
        );

        this.elementService.getLatest()
            .then(response => {
                this.setState({
                    similarItems: response.data.content.list
                })
            })
            .catch(error => {
            });


        //close
        function starAnimation() {
            let star = 0

            document.querySelectorAll(".stars").forEach((el) => {
                let svg = el.querySelectorAll("svg")
                for (let i = 0; i < svg.length; i++) {
                    svg[i].addEventListener("click", () => {
                        if (star === i + 1 && svg[i].classList.contains("completed")) {
                            svg.forEach((el) => {
                                el.classList.remove("completed")
                            })
                        } else {
                            star = i + 1
                            switch (star) {
                                case 1:
                                    for (let i = 0; i <= 0; i++) {
                                        svg[i].classList.add("completed")
                                    }
                                    for (let i = 1; i > 2; i++) {
                                        if (svg[i].classList[0] === "completed") {
                                            svg[i].classList.remove("completed")
                                        }
                                    }


                                    break
                                case 2:
                                    for (let i = 0; i <= 1; i++) {
                                        svg[i].classList.add("completed")
                                    }
                                    for (let i = 2; i > 2; i++) {
                                        if (svg[i].classList[0] === "completed") {
                                            svg[i].classList.remove("completed")
                                        }

                                    }
                                    break
                                case 3:
                                    for (let i = 0; i <= 2; i++) {
                                        svg[i].classList.add("completed")
                                    }
                                    for (let i = 3; i > 3; i++) {
                                        if (svg[i].classList[0] === "completed") {
                                            svg[i].classList.remove("completed")
                                        }

                                    }
                                    break
                                case 4:
                                    for (let i = 0; i <= 3; i++) {
                                        svg[i].classList.add("completed")
                                    }
                                    for (let i = 4; i > 4; i++) {
                                        if (svg[i].classList[0] === "completed") {
                                            svg[i].classList.remove("completed")
                                        }

                                    }
                                    break
                                case 5:
                                    for (let i = 0; i <= 4; i++) {
                                        svg[i].classList.add("completed")
                                    }
                                    for (let i = 5; i > 5; i++) {
                                        if (svg[i].classList[0] === "completed") {
                                            svg[i].classList.remove("completed")
                                        }

                                    }
                                    break
                            }
                        }
                    })
                }
            })
        }

        starAnimation()


        ///

        function share() {
            document.querySelector("#share-icon").addEventListener("click", () => {
                document.querySelector(".share-filter").style.display = null
            })

            //close
            document.querySelector("#close-share").addEventListener("click", () => {
                document.querySelector(".share-filter").style.display = "none"
                console.log(document.querySelector(".share-filter").style)
            })
            document.querySelector(".share-filter").addEventListener("click", (e) => {
                if (e.target === document.querySelector(".share-filter")) {
                    document.querySelector(".share-filter").style.display = "none"
                }
            })
        }

        share()

        function allPhotos() {
            const overlay = document.querySelector(".overlay")
            const left = document.querySelector("#popup-left")
            const right = document.querySelector("#popup-right")

            right.addEventListener("click", () => {
                document.querySelector(".photos-container .images").scrollLeft +=
                    document.querySelector(".photos-container .images").scrollWidth /
                    document.querySelector(".images").childElementCount
            })
            left.addEventListener("click", () => {
                document.querySelector(".photos-container .images").scrollLeft -=
                    document.querySelector(".photos-container .images").scrollWidth /
                    document.querySelector(".images").childElementCount
            })
            document
                .querySelector(".photos-container .images")
                .addEventListener("scroll", (e) => {
                    for (
                        let i = 0;
                        i < document.querySelector(".images").childElementCount;
                        i++
                    ) {
                        if (
                            e.target.scrollLeft >=
                            (document.querySelector(".photos-container .images").scrollWidth /
                                document.querySelector(".images").childElementCount) *
                            i
                        ) {
                            document
                                .querySelector(".feedback")
                                .querySelectorAll("div")
                                .forEach((el) => {
                                    el.classList.remove("active")
                                })
                            document
                                .querySelector(".feedback")
                                .querySelectorAll("div")
                                [i].classList.add("active")
                        }
                    }
                })

            // Affiche les images
            document.querySelector("#all-photos").addEventListener("click", () => {
                overlay.style.display = null
            })
            document.querySelector("#all-photos-res").addEventListener("click", () => {
                overlay.style.display = null
            })

            // Temoins
            for (
                let i = 0;
                i < document.querySelector(".images").childElementCount;
                i++
            ) {
                document
                    .querySelector(".feedback")
                    .appendChild(document.createElement("div"))
                if (i === 0) {
                    document.querySelector(".feedback").querySelector("div").classList =
                        "active"
                }
            }

            // Ferme la popup
            document.querySelector("#close-all-photos").addEventListener("click", () => {
                overlay.style.display = "none"
            })
            overlay.addEventListener("click", (e) => {
                if (e.target === overlay) {
                    document.querySelector(".overlay").style.display = "none"
                }
            })
        }

        allPhotos()

        function contact() {
            //open overlay
            document.querySelectorAll(".contact-btn").forEach((el) => {
                el.addEventListener("click", () => {
                    document.querySelector(".contact-filter").style.display = null
                })
            })

            //confirmer l'envoi
            document
                .querySelector(".contact-container button[type=submit]")
                .addEventListener("click", () => {
                    document.querySelector(".contact-container form").style.display = "none"
                    document.querySelector(".contact-container .confirm-send").style.display =
                        null
                })

            //close overlay
            document.querySelector("#close-contact").addEventListener("click", () => {
                document.querySelector(".contact-filter").style.display = "none"
                document.querySelector(".contact-container form").style.display = null
                document.querySelector(".contact-container .confirm-send").style.display =
                    "none"
            })
            document.querySelector(".contact-filter").addEventListener("click", (e) => {
                if (e.target === document.querySelector(".contact-filter")) {
                    document.querySelector(".contact-filter").style.display = "none"
                    document.querySelector(".contact-container form").style.display = null
                    document.querySelector(".contact-container .confirm-send").style.display =
                        "none"
                }
            })
        }

        contact()

        function report() {
            //open overlay
            document.querySelector(".report").addEventListener("click", () => {
                document.querySelector(".report-filter").style.display = null
            })

            //passer à l'étape suivante
            let checked = false
            document
                .querySelector(".step1")
                .querySelector("button")
                .addEventListener("click", (e) => {
                    e.preventDefault()

                    for (
                        let i = 0;
                        i < document.querySelectorAll("input[type='radio']").length;
                        i++
                    ) {
                        const el = document.querySelectorAll("input[type='radio']")[i]
                        if (el.checked) {
                            checked = true
                            break
                        } else {
                            checked = false
                        }
                    }

                    if (checked) {
                        document.querySelector(".step1").style.display = "none"
                        document.querySelector(".step2").style.display = null
                        document.querySelector(".error-msg").style.display = "none"
                    } else {
                        document.querySelector(".error-msg").style.display = null
                    }
                })

            //ajax
            document
                .querySelector(".report-filter")
                .querySelector("button[type='submit']")
                .addEventListener("click", (e) => {
                    e.preventDefault()
                    const ajax = new XMLHttpRequest()
                    ajax.onreadystatechange = () => {
                        if (ajax.readyState === 1) {
                            document.querySelector(".step2").style.display = "none"
                            document.querySelector(".loading").style.display = null
                        }
                        if (ajax.readyState === 4) {
                            if (ajax.status === 200) {
                                document.querySelector(".loading").style.display = "none"
                                document.querySelector(".success").style.display = null
                            } else {
                                document.querySelector(".loading").style.display = "none"
                                document.querySelector(".success").style.display = "none"
                                document.querySelector(".error").style.display = null
                            }
                        }
                    }
                    ajax.open("GET", "", true)
                    ajax.send()
                })
            //retourner à l'étape précédente
            document
                .querySelector(".step2")
                .querySelector(".step-back")
                .addEventListener("click", (e) => {
                    e.preventDefault()
                    document.querySelector(".step2").style.display = "none"
                    document.querySelector(".step1").style.display = null
                })

            //close overlay
            document.querySelector("#close-report").addEventListener("click", () => {
                document.querySelector(".report-filter").style.display = "none"
                document
                    .querySelector(".report-filter")
                    .querySelectorAll("form>*")
                    .forEach((el) => {
                        el.style.display = "none"
                    })
                document.querySelector(".step1").style.display = null
            })
            document.querySelector(".report-filter").addEventListener("click", (e) => {
                if (e.target === document.querySelector(".report-filter")) {
                    document.querySelector(".report-filter").style.display = "none"
                }
            })
        }

        report()

        //responisive carousel
        function responsiveCarousel() {
            const carousel = document.querySelector(".spotlight-left .images")
            const indicators = document.querySelector(".spotlight-left .indicators")
            carousel.addEventListener("scroll", () => {
                if (carousel.scrollLeft / 476 <= 0) {
                    indicators.querySelectorAll("*")[0].classList.add("active")
                    indicators.querySelectorAll("*")[1].classList.remove("active")
                    indicators.querySelectorAll("*")[2].classList.remove("active")
                } else if (carousel.scrollLeft / 476 <= 1) {
                    indicators.querySelectorAll("*")[0].classList.remove("active")
                    indicators.querySelectorAll("*")[1].classList.add("active")
                    indicators.querySelectorAll("*")[2].classList.remove("active")
                } else if (carousel.scrollLeft / 476 >= 1.4) {
                    indicators.querySelectorAll("*")[0].classList.remove("active")
                    indicators.querySelectorAll("*")[1].classList.remove("active")
                    indicators.querySelectorAll("*")[2].classList.add("active")
                }
            })
        }

        if (window.matchMedia("(max-width: 576px)").matches) {
            responsiveCarousel()
        }


    }

    truncate(str) {
        return str.length > 60 ? str.substring(0, 60) : str;
    }

    changeState = (e) => {
        this.setState({
            item: e,
            details: JSON.parse(e.details)
        })
    }
    handleDelete = (id) => {
        this.setState({
            loadingDialog: true,
        });
        const {dispatch, history} = this.props;
        this.elementService.delete(id)
            .then(() => {
                this.setState({
                    loadingDialog: false
                });
                dispatch(setMessage("ITEM_SUCCESSFULLY_DELETED"))
                history.push({pathname: '/', state: {data: id, type: "ITEM_DELETE"}});
            })
            .catch(error => {
                this.setState({
                    loadingDialog: false
                });
                this.toast.show({
                    severity: 'error',
                    summary: I18n.t('DELETION'),
                    detail: I18n.t(error.response.data.code),
                    life: 6000
                });
            });
    }

    render() {
        return <Fragment>
            <DefaultMeta/>
            <Helmet>
                <title>{this.state.item?.title}</title>
                <meta name="robots" content="index, follow"/>
                <meta name="description" content={this.state.item?.description}/>
                <meta property="og:title" content={this.state.item?.title}/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content={this.state.item?.images[0].url}/>
                <meta property="og:url" content={"https://www.trouvare.com/items/" + this.state.item?.slug}/>
                <meta property="og:description" content={this.state.item?.description}/>
                <meta name="twitter:card" content={this.state.item?.images[0].url}/>
                <link rel="canonical" href={"https://www.trouvare.com/items/" + this.state.item?.slug}/>
            </Helmet>


            <Navbar/>
            <Toast ref={(el) => this.toast = el}/>
            <div className="overlay" style={{display: " none"}}>
                <div className="photos-container">
                    <svg id="close-all-photos" viewBox="0 0 24 24" width="48" height="48" stroke="currentColor"
                         strokeWidth="2"
                         fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                    <svg id="popup-left" className="arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                         viewBox="0 0 24 24"
                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                         strokeLinejoin="round">
                        <polyline points="15 18 9 12 15 6" transform="translate(-1 0)"/>
                    </svg>
                    <div className="images">
                        {
                            console.log(this.state.item?.images?.length)
                        }
                        {this.state.item?.images.map((image, index) => <img key={index} src={image.url}
                                                                            alt={image.name}/>)}
                    </div>
                    <svg id="popup-right" className="arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                         viewBox="0 0 24 24"
                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                         strokeLinejoin="round">
                        <polyline points="9 18 15 12 9 6" transform="translate(1 0)"/>
                    </svg>
                    <div className="feedback"></div>
                </div>
            </div>

            <div className="contact-filter" style={{display: "none"}}>
                <div className="contact-container">
                    <div className="contact-header">
                        Contacter l'annonceur
                        <svg id="close-contact" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                             strokeWidth="2"
                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </div>
                    <div className="seller-infos">
                        <div className="seller-name">
                            <img src={categories_job} alt=""/>
                            JohnDoe22
                        </div>
                        <div className="seller-right">
                            <div className="seller-date">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                     fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                    <line x1="16" y1="2" x2="16" y2="6"></line>
                                    <line x1="8" y1="2" x2="8" y2="6"></line>
                                    <line x1="3" y1="10" x2="21" y2="10"></line>
                                </svg>
                                Membre depuis 3 ans
                            </div>
                            <div className="stars">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" className="completed"
                                     strokeLinejoin="round">
                                    <polygon
                                        points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="completed">
                                    <polygon
                                        points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="completed">
                                    <polygon
                                        points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="completed">
                                    <polygon
                                        points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <polygon
                                        points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <form onSubmit="">
                        <textarea required name="msg" id="" cols="30" rows="10">
                            Bonjour ! Je serai intéressé par votre annonce. Votre bien, est-il toujours disponible ?</textarea>
                        <button type="submit" className="btn btn-primary">Envoyer un message</button>
                    </form>
                    <div className="confirm-send" style={{display: "none"}}>
                        <svg width="205" height="130" viewBox="0 0 205 130" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.92626 56.5497C6.76573 56.5499 6.60674 56.5184 6.45843 56.4569C6.31013 56.3954 6.17543 56.3053 6.06208 56.1916L0.357784 50.4873C0.135123 50.2568 0.0119096 49.948 0.0146946 49.6275C0.0174797 49.307 0.146034 49.0004 0.372668 48.7738C0.599301 48.5472 0.905878 48.4186 1.22637 48.4158C1.54687 48.413 1.85564 48.5362 2.08617 48.7589L7.79047 54.4632C7.96136 54.6341 8.07774 54.8519 8.12489 55.089C8.17203 55.3261 8.14783 55.5718 8.05533 55.7951C7.96283 56.0185 7.8062 56.2093 7.60523 56.3437C7.40426 56.478 7.16798 56.5497 6.92626 56.5497Z"
                                fill="#0CBC8B"/>
                            <path
                                d="M1.22208 56.5502C0.980365 56.5502 0.744097 56.4785 0.543131 56.3442C0.342164 56.2098 0.185526 56.0189 0.0930313 55.7956C0.00053619 55.5723 -0.0236695 55.3266 0.0234735 55.0895C0.0706165 54.8524 0.187 54.6346 0.357896 54.4637L6.06219 48.7594C6.29273 48.5367 6.60149 48.4135 6.92199 48.4163C7.24248 48.4191 7.54906 48.5476 7.77569 48.7743C8.00233 49.0009 8.13088 49.3075 8.13367 49.628C8.13645 49.9485 8.01324 50.2572 7.79058 50.4878L2.08629 56.1921C1.97295 56.3058 1.83827 56.396 1.68995 56.4574C1.54164 56.5189 1.38263 56.5504 1.22208 56.5502Z"
                                fill="#0CBC8B"/>
                            <path d="M170.252 111.071L168.624 114.806L178.644 119.174L180.272 115.439L170.252 111.071Z"
                                  fill="#0CBC8B"/>
                            <path d="M173.415 10.9363L163.99 16.4744L166.054 19.9873L175.479 14.4492L173.415 10.9363Z"
                                  fill="#0CBC8B"/>
                            <path d="M16.3638 20.4201L15.1016 24.2942L25.4956 27.6808L26.7579 23.8068L16.3638 20.4201Z"
                                  fill="#0CBC8B"/>
                            <path d="M28.532 85.3964L19.5791 91.6602L21.9138 94.9972L30.8667 88.7334L28.532 85.3964Z"
                                  fill="#0CBC8B"/>
                            <path d="M192.74 71.0384L192.366 75.0957L203.014 76.0773L203.388 72.02L192.74 71.0384Z"
                                  fill="#0CBC8B"/>
                            <path
                                d="M203.583 41.8535C203.423 41.8537 203.264 41.8222 203.115 41.7607C202.967 41.6992 202.832 41.609 202.719 41.4953L197.015 35.791C196.898 35.6783 196.805 35.5434 196.741 35.3943C196.677 35.2451 196.643 35.0847 196.642 34.9224C196.64 34.7601 196.671 34.5992 196.733 34.4489C196.794 34.2987 196.885 34.1622 197 34.0475C197.114 33.9327 197.251 33.8419 197.401 33.7805C197.551 33.719 197.712 33.6881 197.875 33.6895C198.037 33.6909 198.197 33.7246 198.346 33.7887C198.496 33.8528 198.63 33.9459 198.743 34.0626L204.447 39.7669C204.618 39.9379 204.735 40.1556 204.782 40.3927C204.829 40.6298 204.805 40.8755 204.712 41.0989C204.62 41.3222 204.463 41.5131 204.262 41.6474C204.061 41.7817 203.825 41.8534 203.583 41.8535Z"
                                fill="#0CBC8B"/>
                            <path
                                d="M197.878 41.8532C197.637 41.8532 197.4 41.7815 197.199 41.6471C196.998 41.5128 196.842 41.3219 196.749 41.0986C196.657 40.8753 196.633 40.6296 196.68 40.3925C196.727 40.1554 196.843 39.9376 197.014 39.7667L202.718 34.0624C202.831 33.9456 202.966 33.8525 203.115 33.7885C203.264 33.7244 203.425 33.6907 203.587 33.6893C203.749 33.6878 203.91 33.7188 204.061 33.7802C204.211 33.8417 204.347 33.9325 204.462 34.0472C204.577 34.162 204.668 34.2985 204.729 34.4487C204.79 34.5989 204.821 34.7599 204.82 34.9222C204.819 35.0845 204.785 35.2449 204.721 35.394C204.657 35.5431 204.564 35.678 204.447 35.7908L198.743 41.4951C198.629 41.6087 198.494 41.6989 198.346 41.7604C198.198 41.8218 198.039 41.8534 197.878 41.8532Z"
                                fill="#0CBC8B"/>
                            <path opacity="0.4"
                                  d="M103.027 130C138.925 130 168.027 100.899 168.027 65C168.027 29.1015 138.925 0 103.027 0C67.1284 0 38.0269 29.1015 38.0269 65C38.0269 100.899 67.1284 130 103.027 130Z"
                                  fill="#0CBC8B"/>
                            <path
                                d="M103.027 110.544C128.181 110.544 148.571 90.1532 148.571 64.9998C148.571 39.8464 128.181 19.4556 103.027 19.4556C77.8738 19.4556 57.4829 39.8464 57.4829 64.9998C57.4829 90.1532 77.8738 110.544 103.027 110.544Z"
                                fill="#0CBC8B"/>
                            <path
                                d="M94.0541 84.898C93.6977 84.8982 93.3449 84.8255 93.017 84.6841C92.6892 84.5428 92.393 84.3357 92.1465 84.0755L77.6968 68.8771C77.4516 68.625 77.2584 68.3261 77.1282 67.998C76.9981 67.6698 76.9337 67.3188 76.9387 66.9653C76.9438 66.6118 77.0181 66.2629 77.1575 65.9386C77.2969 65.6143 77.4985 65.3213 77.7508 65.0763C78.003 64.8313 78.3008 64.6394 78.6269 64.5115C78.953 64.3837 79.301 64.3225 79.6507 64.3315C80.0004 64.3405 80.3449 64.4195 80.6641 64.564C80.9834 64.7084 81.2712 64.9155 81.5107 65.1731L94.1901 78.5089L127.339 48.4556C127.596 48.2173 127.899 48.0329 128.227 47.9133C128.556 47.7936 128.905 47.7409 129.254 47.7584C129.603 47.7758 129.945 47.863 130.261 48.0149C130.576 48.1667 130.859 48.3802 131.092 48.6431C131.326 48.906 131.505 49.213 131.62 49.5464C131.736 49.8798 131.784 50.233 131.764 50.5856C131.743 50.9381 131.654 51.2831 131.501 51.6005C131.347 51.9179 131.133 52.2014 130.871 52.4348L95.8201 84.2133C95.3355 84.6544 94.7063 84.8983 94.0541 84.898Z"
                                fill="white"/>
                        </svg>
                        <span>Le message à bien été envoyé</span>
                        <a className="btn btn-primary">Acceder à la messagerie</a>
                    </div>
                </div>
            </div>

            <main className="container notice">
                <section className="navigation">
                    <Link to="/" className="back">
                        {"<"} Retour
                    </Link>
                    <span className="trail">
                        <Link to="/">Accueil </Link> {">"} <Link
                        to={`/search?category=${this.state.item?.categoryIdentifier}`}>
                            {this.state.item?.subcategory.label}</Link> {"> "}
                        <b>{this.state.item?.title}</b></span>
                </section>
                <section className="spotlight row">
                    <div className="spotlight-left col-12 col-lg-8">
                        <div className="images">
                            {this.state.item?.images?.slice(0, 2).map((image, index) => <img key={index} src={image.url}
                                                                                             alt={image.name}/>)}


                        </div>
                        <div className='more-option'>
                            <div className='more-option-left'>

                                {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                                    <button className='btn-edit'
                                            onClick={() => this.props.history.push(`/place-ed/${this.state.item.slug}`)}
                                    ><i className="pi pi-pencil"></i>modifier</button>}

                                {this.props.account && this.props.account.data.id === this.state.item?.whoCreatedId &&
                                    <button className='btn-delete'
                                            onClick={() => this.setState({deleteItemDialog: true})}><i
                                        className="pi pi-trash"></i>supprimer</button>}

                            </div>
                            <div className='more-option-rigth'>
                                <button id="all-photos" className="more">Voir
                                    les {this.state.item?.images?.length} photos
                                </button>
                            </div>
                        </div>

                        <Dialog visible={this.state.deleteItemDialog} style={{width: '35vw'}} className='dialog-b'
                                closable={false}>
                            <div className='dialog-confirm'>
                                <p className="m-0">
                                    <i className='pi pi-exclamation-triangle'></i> voulez vous vraiment suprimer ?
                                </p>
                                <div className='dialog-confirm-img'>
                                    <img src={this.state.item?.images[0]?.url}
                                         alt={this.state.item?.images[0]?.name}/>
                                </div>
                                <div className='dialog-confirm-btn'>
                                    <Button loading={this.state.loadingDialog}
                                            onClick={() => this.setState({deleteItemDialog: false})}>non</Button>
                                    <Button loading={this.state.loadingDialog} onClick={
                                        () => this.handleDelete(this.state.item?.id)
                                    }>oui</Button>
                                </div>

                            </div>

                        </Dialog>
                        <div className="indicators">
                        </div>
                        <div className="meta">
                            <div className="seller-responsive">
                                <div className="infos">
                                    <div className="left">
                                        <div className="name">
                                            {this.state.item?.account &&
                                                <span>{this.state.item.account.username}</span>}
                                            {/*<span className="pro-label">PRO</span>*/}
                                        </div>
                                        <span className="phone">
                                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                                 strokeWidth="2" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                                <path
                                                    d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                                </path>
                                            </svg>
                                            {this.state.item?.account?.contact?.phones &&
                                                formatPhoneNumber(this.state.item.account.contact.phones[0].formattedNumber)}
                                        </span>
                                    </div>
                                    <img src={logo} alt="" style={{borderRadius: " unset", objectFit: "contain"}}/>
                                </div>
                                {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                                    <button className="btn btn-primary contact-btn">Envoyer un message</button>}
                            </div>
                            <button id="all-photos-res" className="more-responsive">Voir
                                les {this.state.item?.images?.length} photos
                            </button>
                            <h1>{this.state.item?.title}</h1>
                            {this.state.item?.status && this.state.item?.subcategoryData?.statusRequired === "true" &&
                                <span className="status">
                                    État: {I18n.t(this.state.item?.status)}
                                </span>}
                            <span
                                className="date">{moment(this.state.item?.createdOn).locale("fr").format("DD/MM/YYYY à HH[h]mm")}
                            </span>
                            <p>{this.state.item?.address.city}, {this.state.item?.address.country}</p>
                            {this.state.item?.subcategoryData?.priceRequired === "true" &&
                                <span className="price">{this.state.item?.price.etPrice?.toLocaleString()} FCFA</span>}
                            <div className="interaction">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className={this.state.like && "active"}
                                     onClick={() => this.setState({like: !this.state.like})}>
                                    <path
                                        d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z">
                                    </path>
                                </svg>
                                <div>
                                    <svg id="share-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                         viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round">
                                        <circle cx="18" cy="5" r="3"></circle>
                                        <circle cx="6" cy="12" r="3"></circle>
                                        <circle cx="18" cy="19" r="3"></circle>
                                        <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                                        <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
                                    </svg>
                                    <div className="share-filter " style={{display: "none"}}>
                                        <div className="share-container">
                                            <div className="share-header">
                                                Partager via
                                                <svg id="close-share" viewBox="0 0 24 24" width="24" height="24"
                                                     stroke="currentColor"
                                                     strokeWidth="2" fill="none" strokeLinecap="round"
                                                     strokeLinejoin="round">
                                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                                </svg>
                                            </div>
                                            <div className="share-body">
                                                <a href="https://facebook.com">
                                                    <svg viewBox="0 0 24 24" width="24" height="24"
                                                         stroke="currentColor"
                                                         strokeWidth="2" fill="none" strokeLinecap="round"
                                                         strokeLinejoin="round">
                                                        <path
                                                            d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                                                    </svg>
                                                    Facebook</a>
                                                <hr/>
                                                <a href="https://twitter.com">
                                                    <svg viewBox="0 0 24 24" width="24" height="24"
                                                         stroke="currentColor" strokeWidth="2" fill="none"
                                                         strokeLinecap="round" strokeLinejoin="round">
                                                        <path
                                                            d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z">
                                                        </path>
                                                    </svg>
                                                    Twitter</a>
                                                <hr/>
                                                <a href="https://instagram.com">
                                                    <svg viewBox="0 0 24 24" width="24" height="24"
                                                         stroke="currentColor"
                                                         strokeWidth="2" fill="none" strokeLinecap="round"
                                                         strokeLinejoin="round">
                                                        <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                                                        <path
                                                            d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                                                        <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                                                    </svg>
                                                    Instagram</a>
                                                <hr/>
                                                <a href="mailto:">
                                                    <svg viewBox="0 0 24 24" width="24" height="24"
                                                         stroke="currentColor" strokeWidth="2" fill="none"
                                                         strokeLinecap="round" strokeLinejoin="round">
                                                        <path
                                                            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                                        <polyline points="22,6 12,13 2,6"></polyline>
                                                    </svg>
                                                    Mail
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="spotlight-right col-4">
                        <div className="seller">
                            <div className="infos">
                                <div className="left">
                                    <div className="name">
                                        {this.state.item?.account && <span>{this.state.item.account.username}</span>}
                                        {/*<span className="pro-label">PRO</span>*/}
                                    </div>
                                    <span className="phone">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                             strokeWidth="2" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path
                                                d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                            </path>
                                        </svg>
                                        {this.state.item?.account?.contact?.phones &&
                                            formatPhoneNumber(this.state.item.account.contact.phones[0].formattedNumber)}
                                    </span>
                                </div>
                                <img src={logo} alt="" style={{borderRadius: " unset", objectFit: "contain"}}/>
                            </div>
                            {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                                <button className="btn btn-primary contact-btn">Envoyer un message</button>}
                        </div>
                    </div>
                </section>
                <section className="description">
                    <div className="title">
                        <h1>Description</h1>
                        <hr/>
                    </div>
                    <div className="row content">
                        <div className="description-left col-12 col-lg-7">
                            <p>{this.state.item?.description}</p>
                            <button className="report">Signaler l'offre</button>
                            <div className="report-filter " style={{display: "none"}}>
                                <div className="report-overlay">
                                    <div className="report-head">
                                        Signaler l'offre
                                        <svg id="close-report" viewBox="0 0 24 24" width="24" height="24"
                                             stroke="currentColor" strokeWidth="2"
                                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    </div>
                                    <form action="" method="POST">
                                        <div className="step1">
                                            Pourquoi signalez-vous cette annonce ?
                                            <div className="report-input">
                                                <label htmlFor="field1">Les photos sont trompeuses</label>
                                                <input type="radio" name="reason" id="field1"
                                                       value="Les photos sont trompeuses" required/>
                                            </div>
                                            <div className="report-input">
                                                <label htmlFor="field2">La localisation est fausse</label>
                                                <input type="radio" name="reason" id="field2"
                                                       value="La localisation est fausse"/>
                                            </div>
                                            <div className="report-input">
                                                <label htmlFor="field3">C'est une escroquerie</label>
                                                <input type="radio" name="reason" id="field3"
                                                       value="C'est une escroquerie"/>
                                            </div>
                                            <div className="report-input">
                                                <label htmlFor="field4">Autre problème</label>
                                                <input type="radio" name="reason" id="field4" value="Autre problème"/>
                                            </div>
                                            <span className="error-msg " style={{display: "none"}}>Veuillez selectionner une raison</span>
                                            <button className="btn btn-primary">
                                                Passer à l'étape suivante
                                            </button>
                                        </div>
                                        <div className="step2 " style={{display: "none"}}>
                                            Souhaitez-vous donner des précisions ?
                                            <textarea placeholder="Description..." name="comment" cols="30"
                                                      rows="10"></textarea>
                                            <button type="submit" className="btn btn-primary">
                                                Envoyer mon signalement
                                            </button>
                                            <button className="step-back">
                                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                                     strokeWidth="2" fill="none"
                                                     strokeLinecap="round" strokeLinejoin="round">
                                                    <line x1="19" y1="12" x2="5" y2="12"></line>
                                                    <polyline points="12 19 5 12 12 5"></polyline>
                                                </svg>
                                                Retourner à l'étape précédente
                                            </button>
                                        </div>
                                        <div className="loading" style={{display: "none"}}>
                                            Chargement en cours ...
                                        </div>
                                        <div className="success" style={{display: "none"}}>
                                            <svg width="205" height="130" viewBox="0 0 205 130" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M6.92626 56.5497C6.76573 56.5499 6.60674 56.5184 6.45843 56.4569C6.31013 56.3954 6.17543 56.3053 6.06208 56.1916L0.357784 50.4873C0.135123 50.2568 0.0119096 49.948 0.0146946 49.6275C0.0174797 49.307 0.146034 49.0004 0.372668 48.7738C0.599301 48.5472 0.905878 48.4186 1.22637 48.4158C1.54687 48.413 1.85564 48.5362 2.08617 48.7589L7.79047 54.4632C7.96136 54.6341 8.07774 54.8519 8.12489 55.089C8.17203 55.3261 8.14783 55.5718 8.05533 55.7951C7.96283 56.0185 7.8062 56.2093 7.60523 56.3437C7.40426 56.478 7.16798 56.5497 6.92626 56.5497Z"
                                                    fill="#0CBC8B"/>
                                                <path
                                                    d="M1.22208 56.5502C0.980365 56.5502 0.744097 56.4785 0.543131 56.3442C0.342164 56.2098 0.185526 56.0189 0.0930313 55.7956C0.00053619 55.5723 -0.0236695 55.3266 0.0234735 55.0895C0.0706165 54.8524 0.187 54.6346 0.357896 54.4637L6.06219 48.7594C6.29273 48.5367 6.60149 48.4135 6.92199 48.4163C7.24248 48.4191 7.54906 48.5476 7.77569 48.7743C8.00233 49.0009 8.13088 49.3075 8.13367 49.628C8.13645 49.9485 8.01324 50.2572 7.79058 50.4878L2.08629 56.1921C1.97295 56.3058 1.83827 56.396 1.68995 56.4574C1.54164 56.5189 1.38263 56.5504 1.22208 56.5502Z"
                                                    fill="#0CBC8B"/>
                                                <path
                                                    d="M170.252 111.071L168.624 114.806L178.644 119.174L180.272 115.439L170.252 111.071Z"
                                                    fill="#0CBC8B"/>
                                                <path
                                                    d="M173.415 10.9363L163.99 16.4744L166.054 19.9873L175.479 14.4492L173.415 10.9363Z"
                                                    fill="#0CBC8B"/>
                                                <path
                                                    d="M16.3638 20.4201L15.1016 24.2942L25.4956 27.6808L26.7579 23.8068L16.3638 20.4201Z"
                                                    fill="#0CBC8B"/>
                                                <path
                                                    d="M28.532 85.3964L19.5791 91.6602L21.9138 94.9972L30.8667 88.7334L28.532 85.3964Z"
                                                    fill="#0CBC8B"/>
                                                <path
                                                    d="M192.74 71.0384L192.366 75.0957L203.014 76.0773L203.388 72.02L192.74 71.0384Z"
                                                    fill="#0CBC8B"/>
                                                <path
                                                    d="M203.583 41.8535C203.423 41.8537 203.264 41.8222 203.115 41.7607C202.967 41.6992 202.832 41.609 202.719 41.4953L197.015 35.791C196.898 35.6783 196.805 35.5434 196.741 35.3943C196.677 35.2451 196.643 35.0847 196.642 34.9224C196.64 34.7601 196.671 34.5992 196.733 34.4489C196.794 34.2987 196.885 34.1622 197 34.0475C197.114 33.9327 197.251 33.8419 197.401 33.7805C197.551 33.719 197.712 33.6881 197.875 33.6895C198.037 33.6909 198.197 33.7246 198.346 33.7887C198.496 33.8528 198.63 33.9459 198.743 34.0626L204.447 39.7669C204.618 39.9379 204.735 40.1556 204.782 40.3927C204.829 40.6298 204.805 40.8755 204.712 41.0989C204.62 41.3222 204.463 41.5131 204.262 41.6474C204.061 41.7817 203.825 41.8534 203.583 41.8535Z"
                                                    fill="#0CBC8B"/>
                                                <path
                                                    d="M197.878 41.8532C197.637 41.8532 197.4 41.7815 197.199 41.6471C196.998 41.5128 196.842 41.3219 196.749 41.0986C196.657 40.8753 196.633 40.6296 196.68 40.3925C196.727 40.1554 196.843 39.9376 197.014 39.7667L202.718 34.0624C202.831 33.9456 202.966 33.8525 203.115 33.7885C203.264 33.7244 203.425 33.6907 203.587 33.6893C203.749 33.6878 203.91 33.7188 204.061 33.7802C204.211 33.8417 204.347 33.9325 204.462 34.0472C204.577 34.162 204.668 34.2985 204.729 34.4487C204.79 34.5989 204.821 34.7599 204.82 34.9222C204.819 35.0845 204.785 35.2449 204.721 35.394C204.657 35.5431 204.564 35.678 204.447 35.7908L198.743 41.4951C198.629 41.6087 198.494 41.6989 198.346 41.7604C198.198 41.8218 198.039 41.8534 197.878 41.8532Z"
                                                    fill="#0CBC8B"/>
                                                <path opacity="0.4"
                                                      d="M103.027 130C138.925 130 168.027 100.899 168.027 65C168.027 29.1015 138.925 0 103.027 0C67.1284 0 38.0269 29.1015 38.0269 65C38.0269 100.899 67.1284 130 103.027 130Z"
                                                      fill="#0CBC8B"/>
                                                <path
                                                    d="M103.027 110.544C128.181 110.544 148.571 90.1532 148.571 64.9998C148.571 39.8464 128.181 19.4556 103.027 19.4556C77.8738 19.4556 57.4829 39.8464 57.4829 64.9998C57.4829 90.1532 77.8738 110.544 103.027 110.544Z"
                                                    fill="#0CBC8B"/>
                                                <path
                                                    d="M94.0541 84.898C93.6977 84.8982 93.3449 84.8255 93.017 84.6841C92.6892 84.5428 92.393 84.3357 92.1465 84.0755L77.6968 68.8771C77.4516 68.625 77.2584 68.3261 77.1282 67.998C76.9981 67.6698 76.9337 67.3188 76.9387 66.9653C76.9438 66.6118 77.0181 66.2629 77.1575 65.9386C77.2969 65.6143 77.4985 65.3213 77.7508 65.0763C78.003 64.8313 78.3008 64.6394 78.6269 64.5115C78.953 64.3837 79.301 64.3225 79.6507 64.3315C80.0004 64.3405 80.3449 64.4195 80.6641 64.564C80.9834 64.7084 81.2712 64.9155 81.5107 65.1731L94.1901 78.5089L127.339 48.4556C127.596 48.2173 127.899 48.0329 128.227 47.9133C128.556 47.7936 128.905 47.7409 129.254 47.7584C129.603 47.7758 129.945 47.863 130.261 48.0149C130.576 48.1667 130.859 48.3802 131.092 48.6431C131.326 48.906 131.505 49.213 131.62 49.5464C131.736 49.8798 131.784 50.233 131.764 50.5856C131.743 50.9381 131.654 51.2831 131.501 51.6005C131.347 51.9179 131.133 52.2014 130.871 52.4348L95.8201 84.2133C95.3355 84.6544 94.7063 84.8983 94.0541 84.898Z"
                                                    fill="white"/>
                                            </svg>
                                            <span>
                                                Nous avons bien reçu votre signalement. Il sera traité par
                                                notre équipe dans les meilleurs délais.
                                            </span>
                                            <span className="secondary">
                                                Les signalements sont anonymes et nous aident à améliorer la
                                                sécurité de Trouvare.
                                            </span>
                                        </div>
                                        <div className="error" style={{display: "none"}}>
                                            <svg width="229" height="222" viewBox="0 0 229 222" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M102.825 8.9596C122.595 14.4384 134.423 32.8883 152.64 42.2761C175.973 54.3011 211.131 48.4487 223.807 71.3081C236.217 93.6883 224.369 123.89 209.431 144.694C196.191 163.133 168.182 161.071 149.761 174.43C131.253 187.851 125.748 221.889 102.825 222C79.934 222.111 71.4974 191.189 55.5556 174.886C43.1249 162.173 28.5598 152.525 19.7998 137.091C9.15462 118.335 -1.19198 98.6859 0.111445 77.1985C1.65775 51.7071 6.26871 21.8442 27.6524 7.63779C49.0006 -6.54508 78.0818 2.10228 102.825 8.9596Z"
                                                      fill="#EBF2FF"/>
                                                <path
                                                    d="M114.604 168.775L67.5332 142.069L103.96 78.3818L136.508 96.8484L142.758 119.552L114.604 168.775Z"
                                                    fill="white"/>
                                                <path opacity="0.3"
                                                      d="M122.239 137.053C123.788 137.053 125.043 135.802 125.043 134.26C125.043 132.718 123.788 131.468 122.239 131.468C120.691 131.468 119.436 132.718 119.436 134.26C119.436 135.802 120.691 137.053 122.239 137.053Z"
                                                      fill="#367CFF"/>
                                                <path opacity="0.3"
                                                      d="M92.6514 122.345C94.1999 122.345 95.4552 121.094 95.4552 119.552C95.4552 118.01 94.1999 116.76 92.6514 116.76C91.103 116.76 89.8477 118.01 89.8477 119.552C89.8477 121.094 91.103 122.345 92.6514 122.345Z"
                                                      fill="#367CFF"/>
                                                <path
                                                    d="M114.742 169.274L67.0332 142.206L103.823 77.8833L136.822 96.6073L143.152 119.603L114.742 169.274ZM68.0344 141.932L114.467 168.277L142.365 119.503L136.196 97.0913L104.098 78.8808L68.0344 141.932Z"
                                                    fill="#262626"/>
                                                <path opacity="0.3"
                                                      d="M136.507 96.8484L128.234 111.313L142.757 119.552L136.507 96.8484Z"
                                                      fill="#367CFF"/>
                                                <path
                                                    d="M142.759 119.917C142.695 119.917 142.632 119.901 142.577 119.87L128.054 111.63C127.97 111.582 127.908 111.503 127.882 111.409C127.856 111.315 127.869 111.216 127.917 111.131L136.19 96.6665C136.226 96.6036 136.28 96.5528 136.345 96.5204C136.41 96.488 136.483 96.4755 136.555 96.4845C136.627 96.4936 136.695 96.5237 136.749 96.5711C136.804 96.6185 136.844 96.681 136.863 96.7508L143.113 119.453C143.128 119.507 143.13 119.564 143.119 119.62C143.108 119.675 143.085 119.727 143.05 119.771C143.016 119.816 142.972 119.852 142.921 119.877C142.871 119.902 142.815 119.915 142.759 119.915V119.917ZM128.737 111.176L142.17 118.797L136.389 97.7962L128.737 111.176Z"
                                                    fill="#292A2E"/>
                                                <path
                                                    d="M102.074 115.878C103.296 115.878 104.287 114.891 104.287 113.674C104.287 112.457 103.296 111.47 102.074 111.47C100.852 111.47 99.8613 112.457 99.8613 113.674C99.8613 114.891 100.852 115.878 102.074 115.878Z"
                                                    fill="#292A2E"/>
                                                <path
                                                    d="M122.83 125.277C124.052 125.277 125.043 124.291 125.043 123.074C125.043 121.856 124.052 120.87 122.83 120.87C121.608 120.87 120.617 121.856 120.617 123.074C120.617 124.291 121.608 125.277 122.83 125.277Z"
                                                    fill="#292A2E"/>
                                                <path
                                                    d="M113.425 138.795C113.33 138.795 113.238 138.758 113.169 138.692C113.101 138.625 113.061 138.535 113.058 138.44C113.012 136.677 112.515 134.956 111.614 133.438C110.713 131.921 109.438 130.657 107.909 129.768C106.381 128.878 104.65 128.392 102.88 128.356C101.11 128.319 99.3605 128.733 97.7962 129.558C97.7535 129.582 97.7064 129.597 97.6578 129.602C97.6092 129.608 97.5599 129.603 97.513 129.589C97.4661 129.576 97.4224 129.553 97.3846 129.522C97.3467 129.491 97.3154 129.453 97.2925 129.41C97.2697 129.367 97.2557 129.319 97.2514 129.271C97.2471 129.222 97.2526 129.173 97.2676 129.127C97.2826 129.081 97.3067 129.038 97.3386 129.001C97.3704 128.964 97.4094 128.933 97.4532 128.912C99.1279 128.029 101.001 127.586 102.896 127.626C104.79 127.665 106.643 128.186 108.279 129.138C109.915 130.09 111.28 131.442 112.245 133.066C113.21 134.691 113.742 136.533 113.792 138.42C113.795 138.517 113.759 138.611 113.692 138.681C113.625 138.751 113.533 138.792 113.435 138.795H113.425Z"
                                                    fill="#292A2E"/>
                                                <path
                                                    d="M104.002 111.419C102.81 111.404 101.647 111.051 100.649 110.402C100.57 110.345 100.517 110.261 100.501 110.165C100.485 110.07 100.508 109.973 100.563 109.894C100.619 109.815 100.704 109.762 100.8 109.745C100.895 109.728 100.993 109.75 101.073 109.805C101.102 109.825 103.255 111.297 105.661 110.382C105.752 110.35 105.851 110.355 105.938 110.395C106.025 110.435 106.093 110.507 106.127 110.596C106.161 110.685 106.159 110.784 106.121 110.872C106.083 110.959 106.012 111.029 105.923 111.065C105.31 111.299 104.659 111.42 104.002 111.419Z"
                                                    fill="#292A2E"/>
                                                <path
                                                    d="M126.666 121.927C126.625 121.926 126.584 121.92 126.545 121.906C123.451 120.833 123.099 118.456 123.085 118.355C123.072 118.258 123.098 118.161 123.157 118.084C123.217 118.006 123.304 117.956 123.401 117.943C123.497 117.93 123.595 117.956 123.673 118.014C123.751 118.073 123.801 118.161 123.814 118.257C123.827 118.343 124.14 120.296 126.789 121.216C126.87 121.244 126.939 121.301 126.983 121.375C127.027 121.449 127.044 121.537 127.029 121.622C127.015 121.707 126.971 121.784 126.904 121.84C126.838 121.896 126.754 121.926 126.668 121.927H126.666Z"
                                                    fill="#292A2E"/>
                                                <path
                                                    d="M4.74586 36.9005C4.69767 36.9005 4.64995 36.8911 4.60542 36.8727C4.5609 36.8543 4.52045 36.8274 4.48639 36.7934C4.45232 36.7594 4.42531 36.7191 4.40689 36.6748C4.38848 36.6304 4.37902 36.5829 4.37906 36.5349V26.2196C4.37762 26.1707 4.38605 26.1221 4.40384 26.0765C4.42163 26.0309 4.44843 25.9893 4.48265 25.9542C4.51686 25.9191 4.55781 25.8912 4.60305 25.8721C4.64829 25.8531 4.6969 25.8433 4.74602 25.8433C4.79514 25.8433 4.84376 25.8531 4.889 25.8721C4.93424 25.8912 4.97518 25.9191 5.0094 25.9542C5.04362 25.9893 5.07042 26.0309 5.08821 26.0765C5.106 26.1221 5.11442 26.1707 5.11298 26.2196V36.5349C5.11302 36.5829 5.10355 36.6305 5.08512 36.6749C5.06669 36.7192 5.03965 36.7596 5.00555 36.7935C4.97145 36.8275 4.93096 36.8544 4.8864 36.8728C4.84184 36.8911 4.79409 36.9006 4.74586 36.9005Z"
                                                    fill="#262626"/>
                                                <path
                                                    d="M8.1258 31.743H1.36712C1.26975 31.743 1.17637 31.7045 1.10753 31.6359C1.03868 31.5673 1 31.4743 1 31.3774C1 31.2804 1.03868 31.1874 1.10753 31.1188C1.17637 31.0502 1.26975 31.0117 1.36712 31.0117H8.1258C8.22317 31.0117 8.31654 31.0502 8.38539 31.1188C8.45424 31.1874 8.49292 31.2804 8.49292 31.3774C8.49292 31.4743 8.45424 31.5673 8.38539 31.6359C8.31654 31.7045 8.22317 31.743 8.1258 31.743Z"
                                                    fill="#262626"/>
                                                <path
                                                    d="M110 60.8351C109.952 60.8351 109.904 60.8256 109.859 60.8073C109.815 60.7889 109.774 60.7619 109.74 60.728C109.706 60.694 109.679 60.6537 109.661 60.6094C109.642 60.565 109.633 60.5175 109.633 60.4695V50.1542C109.632 50.1053 109.64 50.0566 109.658 50.011C109.676 49.9654 109.702 49.9239 109.737 49.8888C109.771 49.8537 109.812 49.8258 109.857 49.8067C109.902 49.7877 109.951 49.7778 110 49.7778C110.049 49.7778 110.098 49.7877 110.143 49.8067C110.188 49.8258 110.229 49.8537 110.263 49.8888C110.298 49.9239 110.324 49.9654 110.342 50.011C110.36 50.0566 110.368 50.1053 110.367 50.1542V60.4695C110.367 60.5175 110.357 60.565 110.339 60.6094C110.321 60.6538 110.294 60.6941 110.259 60.7281C110.225 60.762 110.185 60.789 110.14 60.8073C110.096 60.8257 110.048 60.8351 110 60.8351Z"
                                                    fill="#262626"/>
                                                <path
                                                    d="M113.38 55.6776H106.621C106.524 55.6776 106.43 55.639 106.361 55.5705C106.293 55.5019 106.254 55.4089 106.254 55.3119C106.254 55.215 106.293 55.122 106.361 55.0534C106.43 54.9848 106.524 54.9463 106.621 54.9463H113.38C113.477 54.9463 113.57 54.9848 113.639 55.0534C113.708 55.122 113.747 55.215 113.747 55.3119C113.747 55.4089 113.708 55.5019 113.639 55.5705C113.57 55.639 113.477 55.6776 113.38 55.6776Z"
                                                    fill="#262626"/>
                                                <path
                                                    d="M181.039 94.3521C180.942 94.3521 180.848 94.3136 180.779 94.245C180.711 94.1765 180.672 94.0834 180.672 93.9865V78.2956C180.672 78.1986 180.711 78.1056 180.779 78.037C180.848 77.9685 180.942 77.9299 181.039 77.9299C181.136 77.9299 181.23 77.9685 181.299 78.037C181.367 78.1056 181.406 78.1986 181.406 78.2956V93.9865C181.406 94.0834 181.367 94.1764 181.299 94.2449C181.23 94.3135 181.136 94.352 181.039 94.3521Z"
                                                    fill="#262626"/>
                                                <path
                                                    d="M186.18 86.5067H175.898C175.801 86.5067 175.708 86.4681 175.639 86.3996C175.57 86.331 175.531 86.238 175.531 86.141C175.531 86.0441 175.57 85.9511 175.639 85.8825C175.708 85.8139 175.801 85.7754 175.898 85.7754H186.18C186.277 85.7754 186.37 85.8139 186.439 85.8825C186.508 85.9511 186.547 86.0441 186.547 86.141C186.547 86.238 186.508 86.331 186.439 86.3996C186.37 86.4681 186.277 86.5067 186.18 86.5067Z"
                                                    fill="#262626"/>
                                                <path
                                                    d="M206.062 44.7459C206.014 44.7459 205.966 44.7365 205.922 44.7182C205.877 44.6998 205.837 44.6729 205.803 44.6389C205.769 44.6049 205.742 44.5646 205.723 44.5202C205.705 44.4759 205.695 44.4283 205.695 44.3803V36.5348C205.695 36.4379 205.734 36.3449 205.803 36.2763C205.872 36.2077 205.965 36.1692 206.062 36.1692C206.16 36.1692 206.253 36.2077 206.322 36.2763C206.391 36.3449 206.43 36.4379 206.43 36.5348V44.3803C206.43 44.4283 206.42 44.4759 206.402 44.5202C206.383 44.5646 206.356 44.6049 206.322 44.6389C206.288 44.6729 206.248 44.6998 206.203 44.7182C206.158 44.7365 206.111 44.7459 206.062 44.7459Z"
                                                    fill="#262626"/>
                                                <path
                                                    d="M208.633 40.8233H203.492C203.444 40.8233 203.396 40.8139 203.352 40.7955C203.307 40.7771 203.267 40.7502 203.233 40.7162C203.198 40.6823 203.171 40.642 203.153 40.5976C203.134 40.5532 203.125 40.5057 203.125 40.4577C203.125 40.4097 203.134 40.3621 203.153 40.3178C203.171 40.2734 203.198 40.2331 203.233 40.1991C203.267 40.1652 203.307 40.1382 203.352 40.1199C203.396 40.1015 203.444 40.092 203.492 40.092H208.633C208.73 40.092 208.823 40.1306 208.892 40.1991C208.961 40.2677 209 40.3607 209 40.4577C209 40.5546 208.961 40.6476 208.892 40.7162C208.823 40.7848 208.73 40.8233 208.633 40.8233Z"
                                                    fill="#262626"/>
                                                <path
                                                    d="M35.9569 110.769C35.9087 110.769 35.861 110.759 35.8164 110.741C35.7718 110.723 35.7314 110.696 35.6973 110.662C35.6632 110.628 35.6361 110.588 35.6177 110.543C35.5993 110.499 35.5898 110.451 35.5898 110.403V102.558C35.5898 102.461 35.6285 102.368 35.6974 102.299C35.7662 102.231 35.8596 102.192 35.9569 102.192C36.0543 102.192 36.1477 102.231 36.2165 102.299C36.2854 102.368 36.3241 102.461 36.3241 102.558V110.403C36.3241 110.451 36.3146 110.499 36.2962 110.543C36.2778 110.588 36.2507 110.628 36.2166 110.662C36.1825 110.696 36.1421 110.723 36.0975 110.741C36.0529 110.759 36.0052 110.769 35.9569 110.769Z"
                                                    fill="#262626"/>
                                                <path
                                                    d="M38.5272 106.846H33.3866C33.3384 106.846 33.2907 106.837 33.2462 106.818C33.2016 106.8 33.1611 106.773 33.1271 106.739C33.093 106.705 33.0659 106.665 33.0475 106.621C33.029 106.576 33.0195 106.529 33.0195 106.481C33.0195 106.433 33.029 106.385 33.0475 106.341C33.0659 106.296 33.093 106.256 33.1271 106.222C33.1611 106.188 33.2016 106.161 33.2462 106.143C33.2907 106.124 33.3384 106.115 33.3866 106.115H38.5272C38.6246 106.115 38.718 106.154 38.7868 106.222C38.8557 106.291 38.8943 106.384 38.8943 106.481C38.8943 106.578 38.8557 106.671 38.7868 106.739C38.718 106.808 38.6246 106.846 38.5272 106.846Z"
                                                    fill="#262626"/>
                                            </svg>
                                            <span>Oups ! Une erreur est survenue ! </span>
                                            <span className="secondary">Malheureusement, une erreur s’est produite. Vérifiez votre
                                                réseau et essayez à nouveau. <br/>
                                                <br/>
                                                Si l'erreur persiste, merci de nous
                                                <Link to="/contact">contacter </Link>   .</span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="description-right col-12 col-lg-5">
                            <hr/>


                            <div className="specifications">
                                {Object.keys(this.state.details).map((key, ind) => (
                                    <div key={key}>
                                        {getElementData(this.state.item.elements, key, this.state.details[key])}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
                {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                    <section className="seller row">
                        <div className="user col-12 col-lg-8">
                            <div className="name">
                                <img src={logo} alt="" style={{borderRadius: "unset", objectFit: "contain"}}/>
                                {this.state.item?.account && <span>{this.state.item.account.username}</span>}
                                {/*<span className="pro-label">PRO</span>*/}
                            </div>
                            <div className="infos">
                                <div className="signup-date">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                         fill="none"
                                         stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round">
                                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                        <line x1="16" y1="2" x2="16" y2="6"></line>
                                        <line x1="8" y1="2" x2="8" y2="6"></line>
                                        <line x1="3" y1="10" x2="21" y2="10"></line>
                                    </svg>
                                    Membre depuis 1 an
                                </div>
                                <div className="activity">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                         fill="none"
                                         stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round">
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <polyline points="12 6 12 12 16 14"></polyline>
                                    </svg>
                                    Réponds généralement en 3 heures
                                </div>
                            </div>
                            <button className="btn btn-primary contact-btn">Envoyer un message</button>
                            <div className="stars">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                     className="completed">
                                    <polygon
                                        points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                     className="completed">
                                    <polygon
                                        points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                     className="completed">
                                    <polygon
                                        points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                     className="completed">
                                    <polygon
                                        points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polygon
                                        points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
                                </svg>
                            </div>
                        </div>
                    </section>}
                <section className="map">

                    <div ref={this.mapContainer} id="map" className="col-12 col-lg-8">
                    </div>

                </section>
                <section className="same-notice">
                    <div className="title">
                        <hr/>
                        <h1>Annonces similaires</h1>
                    </div>
                    <div className="cards row row-cols-md-4 row-cols-1">
                        {this.state.similarItems?.map((item, index) => <Card key={index} item={item}
                                                                             changeState={this.changeState}/>)}
                    </div>
                </section>
            </main>

            <Footer/>
            <ScrollTop/>
        </Fragment>;
    }
}

function mapStateToProps(state) {
    const {account} = state.auth;
    return {
        account
    };
}

export default connect(mapStateToProps)(ItemDetail);

