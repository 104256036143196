import {PrivateRoute} from "./components/PrivateRoute";
import {Role} from "./helpers/role";
import React from "react";
import Home from "./pages/home/Home";
import {Route} from "react-router-dom";
import PlaceAd from "./pages/place-ad/PlaceAd";
import Search from "./pages/search/Search";
import ProfilePro from "./pages/pro-profile/ProfilePro";
import Message from "./pages/message/Message";
import ContactUS from "./pages/contact-us/ContactUS";
import Cgu from "./pages/cgu/Cgu";
import Cookies from "./pages/cgu/Coukies";
import ItemDetail from "./pages/item-details/ItemDetail";
import ForgotPasswordRequest from "./pages/auth/ForgotPasswordRequest";
import PlaceEd from "./pages/place-ad/PlaceEd";
import Professional from "./components/professional/Professional";
import Particular from "./components/particular/Particular";
import Page from "./components/page/Page";
import Profile from "./pages/profile/Profile";
import {login} from "./actions/auth";
import Login from "./pages/auth/Login";

const AppRoute = () => {
    return (
        <>
            <Route exact path={["/", "/home"]} component={Home}/>
            <PrivateRoute exact path="/place-ad" roles={[Role.ROLE_USER]} component={PlaceAd}/>
            <PrivateRoute exact path="/place-ed/:slug" roles={[Role.ROLE_USER]} component={PlaceEd}/>
            <Route exact path="/search" component={Search}/>
            <PrivateRoute exact path="/profile" roles={[Role.ROLE_USER]} component={Profile}/>
            <PrivateRoute exact path="/pro-profile" roles={[Role.ROLE_USER]} component={ProfilePro}/>
            <PrivateRoute exact path="/messages" roles={[Role.ROLE_USER]} component={Message}/>
            <PrivateRoute exact path="/page" roles={[Role.ROLE_USER]} component={Page}/>

            <Route exact path="/contact" component={ContactUS}/>
            <Route exact path="/cgu" component={Cgu}/>
            <Route exact path="/cookies" component={Cookies}/>
            <Route exact path="/items/:slug" component={ItemDetail}/>
            <Route exact path="/professional" component={Professional}/>
            <Route exact path="/particular" component={Particular}/>
        </>
    );

}

export default AppRoute;
