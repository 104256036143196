import React, { Fragment } from 'react';
import "./cgu.css"
import Footer from '../../components/footer/Footer';
import {Link} from "react-router-dom"
import ScrollTop from '../../components/scroll-top/ScrollTop';
import Navbar from '../../components/navbar/Navbar';
import DefaultMeta from "../../components/seo/DefaultMeta";
import {Helmet} from "react-helmet";


class Cookies extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        return <Fragment>
            <DefaultMeta/>
            <Helmet>
                <title>Politique des cookies | Trouvare</title>
                <meta name="description" content="Politique des cookies"/>
                <meta property="og:url" content="https://trouvare.com/cookies"/>
                <link rel="canonical" href="https://trouvare.com/cookies"/>
            </Helmet>
            <Navbar />
            <main class="container cgu">
            <section className="navigation">
                    <Link to="/" className="back"> {"<"} Retour</Link>
                    <span id="notice_number"></span>
                </section>
                <h1>Politique des cookies</h1>
                <div>
                <p>Nous sommes susceptibles, avec votre consentement:</p>
                <ul>
                    <li>* de vous permettre d'&ecirc;tre recontact&eacute; par un Annonceur ;</li>
                    <li>* de transmettre votre demande d&rsquo;accompagnement dans votre projet immobilier ou automobile et, le cas &eacute;ch&eacute;ant, les donn&eacute;es de contexte sur le bien en question, aux professionnels s&eacute;lectionn&eacute;s ;</li>
                    <li>* de vous permettre de faire des recherches d'Annonces autour de vous en fonction de votre donn&eacute;e de localisation ;</li>
                    <li>* d&rsquo;afficher votre Avis et votre commentaire sur le profil de l&rsquo;Utilisateur ayant r&eacute;alis&eacute; une Transaction ou une R&eacute;servation ;</li>
                    <li>* de mettre &agrave; disposition votre Profil candidat &agrave; des Annonceurs Pro ;</li>
                    <li>* de mettre &agrave; disposition votre dossier locataire aux Annonceurs bailleurs. Cette transmission est r&eacute;alis&eacute;e uniquement sur instruction de votre part : &agrave; chaque demande de contact, vous avez la possibilit&eacute; de partager ou non votre profil locataire et, avec certains Annonceurs bailleurs, vous aurez &eacute;galement la possibilit&eacute; de partager ou non vos justificatifs. Depuis votre espace locataire, vous pouvez &eacute;galement consulter &agrave; tout moment la liste des Annonceurs ayant acc&egrave;s &agrave; votre dossier locataire, v&eacute;rifier s&rsquo;ils ont acc&egrave;s &agrave; votre profil locataire et/ou &agrave; vos justificatifs, et retirer l&rsquo;acc&egrave;s &agrave; un Annonceur donn&eacute; ;</li>
                    <li>* de personnaliser les publicit&eacute;s qui s&rsquo;affichent sur notre Site ou des sites tiers, afin d&rsquo;&eacute;viter que vous soyez expos&eacute;s &agrave; des publicit&eacute;s non pertinentes pour vous. Nous sommes susceptibles de les personnaliser avec les donn&eacute;es suivantes, utilis&eacute;es de mani&egrave;re combin&eacute;e ou individuellement :</li>
                </ul>
                <p>- les donn&eacute;es issues de technologies publicitaires comme les balises web, les pixels, les tags publicitaires, les cookies et les identifiants d&rsquo;appareils ;</p>
                <p>- les informations que vous nous avez communiqu&eacute;es ;</p>
                <p>- les donn&eacute;es obtenues via l&rsquo;utilisation de notre service (fr&eacute;quence de visites, cat&eacute;gories d&rsquo;Annonces visit&eacute;es, mots-cl&eacute;s saisis...) et les identifiants de connexion et donn&eacute;es techniques vis&eacute;s &agrave; l&rsquo;article pr&eacute;c&eacute;dent ;</p>
                <p>- les informations &eacute;manant de tiers (ex. partenaires publicitaires, agr&eacute;gateurs de donn&eacute;es...) ;</p>
                <p>- les informations que nous pouvons d&eacute;duire des donn&eacute;es d&eacute;crites ci-dessus.</p>
                <ul>
                    <li>de partager vos donn&eacute;es &agrave; nos partenaires publicitaires, responsables de traitement, aux fins d&rsquo;am&eacute;liorer les performances des campagnes de nos partenaires sur notre site.</li>
                    <li>de personnaliser les publicit&eacute;s qui s'affichent sur notre site en fonction de votre environnement proche.</li>
                </ul>
                </div>
            </main>

            <Footer />
            <ScrollTop/>
        </Fragment>;
    }
}


export default Cookies;
