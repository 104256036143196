import React, {Fragment} from 'react';

import './contact.css'
import Footer from '../../components/footer/Footer';
import {Link} from "react-router-dom"
import ScrollTop from '../../components/scroll-top/ScrollTop';
import Navbar from '../../components/navbar/Navbar';
import DefaultMeta from "../../components/seo/DefaultMeta";
import {Helmet} from "react-helmet";
import * as Yup from "yup";
import {I18n} from "react-redux-i18n";
import {login} from "../../actions/auth";
import {Formik} from 'formik';
import {getFormErrorMessage} from "../../helpers/utils";
import {Button} from "primereact/button";
import ContactService from "../../services/ContactService";
import ItemService from "../../services/ItemService";
import {Toast} from "primereact/toast";

class ContactUS extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

        this.formikProps = {
            initialValues: this.emptyElement,
            validationSchema: Yup.object().shape({
                address: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')).email(I18n.t('INVALID_EMAIL')),
                message: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                subject: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                name: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
            })
        };

        this.handleContact = this.handleContact.bind(this);
        this.elementService = new ContactService();
    }

    emptyElement = {
        address: '',
        message: '',
        subject: '',
        name: ''
    };

    handleContact = (element, {resetForm}) => {
        this.setState({loading: true});
        this.elementService.contactUs(element)
            .then((response) => {
                this.setState({
                    element: this.emptyElement,
                    images: [],
                    loading: false
                });
                resetForm();
                //this.imageRef.current.load()
                this.toast.show({
                    severity: 'success',
                    summary: I18n.t('ADD_AD'),
                    detail: I18n.t('ADD_AD_SUCCESS'),
                    life: 6000
                });
            })
            .catch(error => {
                this.toast.show({
                    severity: 'error',
                    summary: I18n.t('ADD_AD'),
                    detail: I18n.t('ADD_AD_ERROR'),
                    life: 6000
                });
                this.setState({loading: false});
            });
    }

    render() {
        return <Fragment>
            <DefaultMeta/>
            <Helmet>
                <title>Nous contacter | Trouvare</title>
                <meta name="description" content="Nous contacter"/>
                <meta property="og:url" content="https://trouvare.com/contact"/>
                <link rel="canonical" href="https://trouvare.com/contact"/>
            </Helmet>
            <Navbar/>
            <main class="container contact">
                <Toast ref={(el) => this.toast = el}/>
                <section className="navigation">
                    <Link to="/" className="back"> {"<"} Retour</Link>
                    <span id="notice_number"></span>
                </section>
                <h1>Contactez-nous</h1>
                <Formik
                    enableReinitialize
                    initialValues={this.formikProps.initialValues}
                    validationSchema={this.formikProps.validationSchema}
                    onSubmit={this.handleContact}
                >
                    {props => {
                        console.log(props)
                        return <form onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                     onSubmit={props.handleSubmit} className="contact-container">
                            Vous souhaitez obtenir plus d'informations sur le site ? Vous rencontrez une
                            difficulté ? N’hésitez pas à nous contacter !
                            <div class="fields">
                                <div class="input">
                                    <label for="name">
                                        Nom* :
                                        <div class="btn btn-secondary">
                                            <input
                                                name="name"
                                                value={props.values.name}
                                                onChange={props.handleChange}
                                                id="name"
                                                type="text"
                                            />
                                        </div>
                                        {getFormErrorMessage(props, 'name')}
                                    </label>
                                    <label for="mail">
                                        Email* :
                                        <div class="btn btn-secondary">
                                            <input
                                                name="address"
                                                value={props.values.address}
                                                onChange={props.handleChange}
                                                id="address"
                                                type="text"
                                            />
                                        </div>
                                        {getFormErrorMessage(props, 'address')}
                                    </label>
                                    <label htmlFor="subject">
                                        Objet* :
                                        <div className="btn btn-secondary">
                                            <input
                                                name="subject"
                                                value={props.values.subject}
                                                onChange={props.handleChange}
                                                id="subject"
                                                type="text"
                                            />
                                        </div>
                                        {getFormErrorMessage(props, 'subject')}
                                    </label>
                                </div>
                                <textarea
                                    name="message"
                                    value={props.values.message}
                                    onChange={props.handleChange}
                                    id="message"
                                    placeholder="Votre message*"
                                />
                            </div>
                            <Button loading={this.state.loading} type="submit" label="Envoyer votre message" className="tButton" />
                        </form>
                    }}
                </Formik>
            </main>
            <Footer/>
            <ScrollTop/>
        </Fragment>;
    }
}


export default ContactUS;
