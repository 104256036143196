import {SET_SETTING, SET_MESSAGE} from "./types";
import SettingService from "../services/SettingService";
import {Constant} from "../helpers/const";

export const getSettings = () => (dispatch) => {
    const account = JSON.parse(localStorage.getItem(Constant.ACCOUNT));
    if (account) {
        return new SettingService().getAccountAndDefault().then(
            (response) => {
                dispatch({
                    type: SET_SETTING,
                    payload: response.data.content
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: response.data.code,
                });

                return Promise.resolve();
            },
            (error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.code) ||
                    error.message ||
                    error.toString();

                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });

                return Promise.reject();
            }
        );
    }

    return new SettingService().getDefault().then(
        (response) => {
            dispatch({
                type: SET_SETTING,
                payload: response.data.content
            });

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.code,
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.code) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};

