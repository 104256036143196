import React, {useEffect} from 'react';
import {Route, useLocation, withRouter} from 'react-router-dom';
import App from "./App";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import ForgotPasswordRequest from "./pages/auth/ForgotPasswordRequest";
import Home from "./pages/home/Home";
import PasswordReset from "./pages/auth/PasswordReset";

const AppWrapper = (props) => {
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);

    if (props.location.pathname.startsWith('/passwordreset')) {
        return <Route exact path="/passwordreset/:key" component={PasswordReset}/>
    }

    switch (props.location.pathname) {
        case "/login":
            return <Route path="/login" component={Login}/>
        case "/signup":
            return <Route path="/signup" component={SignUp}/>
        case "/notfound":
            return <Route path="/notfound" component={Home}/>
        case "/forgot":
            return <Route path="/forgot" component={Login}/>
        case "/forgotpasswordrequest":
            return <Route path="/forgotpasswordrequest" component={ForgotPasswordRequest}/>
        default:
            return <App/>;
    }
}

export default withRouter(AppWrapper);
