import HttpService from "./HttpService";

export default class CategoryService extends HttpService {
    endPoint = "/api/v1/gamma/categories"

    getAllWithSubcategories() {
        return this.getAuthTokenRequest().get(this.endPoint + "/allWithSubcategories");
    }

    getAllWithSubCategoriesPresent() {
        return this.getAuthTokenRequest().get(this.endPoint + "/allWithSubcategoriesPresent");
    }
}
