import HttpService from "./HttpService";

export default class ItemService extends HttpService {
    endPoint = "/api/v1/gamma/items"

    findBySlug(slug) {
        return this.getAuthTokenRequest().get(this.endPoint + '/' + slug);
    }

    getLatest() {
        return this.getAuthTokenRequest().get(this.endPoint + '/latest');
    }

}
