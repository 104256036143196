import React, {Component} from 'react';
import {Helmet} from "react-helmet";

class DefaultMeta extends Component {

    constructor(props) {
        super(props)
    }


    render() {
        return (
            <Helmet>
                <title>trouvare, le site d'annonces gratuites</title>
                <meta name="viewport"
                      content="initial-scale=1.0, width=device-width, maximum-scale=1.0, user-scalable=0"/>
                <meta name="theme-color" content="#ffffff"/>
                <meta name="description"
                      content="Avec trouvare, trouvez la bonne affaire, réalisez-la bonne vente pour votre voiture, immobilier, emploi, vêtements, mode, maison, meubles, jeux vidéo, etc., sur le site référent de petites annonces de particulier à particulier et professionnels"/>
                />
                <meta charSet="UTF-8"/>
                <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
                <meta property="og:locale" content="fr_FR"/>
                <meta property="og:site_name" content="trouvare"/>
                <meta name="twitter:site" content="trouvare"/>
                <meta http-equiv="P3P" content="CP=&quot;This is not a P3P policy&quot;"/>
                {/*<meta name="google-site-verification" content="uyio912RR__InwCKsO90G9r8UeP6ia3EeZwY6KaBRrk"/>
                <meta name="facebook-domain-verification" content="hbda5pvr14cpxa63ojjexw311iyf7c"/>*/}
                <meta property="og:url" content="https://www.trouvare.com"/>
                <link rel="canonical" href="https://trouvare.com"/>
            </Helmet>
        );
    }
}

export default DefaultMeta;
