import React, { Component } from 'react';
import "./footer.css"
import logotype from "../../assets/img/logotype.svg"
import { Link } from 'react-router-dom'
import lottie from "lottie-web"
import {connect} from "react-redux";

class Footer extends Component {

    componentDidMount() {

        //instagramAnimation
        instagramAnimation()
        function instagramAnimation() {
            const instagramContainer = document.querySelector("#instagramIcon")
            const instagram = lottie.loadAnimation({
                container: instagramContainer, // Required
                path: "https://assets5.lottiefiles.com/packages/lf20_ukaoysdz.json", // Required
                renderer: "svg", // Required
                loop: false, // Optional
                autoplay: false, // Optional
            })
            instagramContainer.addEventListener("mouseenter", () =>
                instagram.playSegments([0, 10], true)
            )
            instagramContainer.addEventListener("mouseleave", () =>
                instagram.playSegments([10, 0], true)
            )
        }

        //facebookAnimation
        facebookAnimation()
        function facebookAnimation() {
            const facebookContainer = document.querySelector("#facebookIcon")
            const facebook = lottie.loadAnimation({
                container: facebookContainer, // Required
                path: "https://assets7.lottiefiles.com/packages/lf20_lhd1bdi4.json", // Required
                renderer: "svg", // Required
                loop: false, // Optional
                autoplay: false, // Optional
            })
            facebookContainer.addEventListener("mouseenter", () =>
                facebook.playSegments([0, 60], true)
            )
        }

        //twitterAnimation
        twitterAnimation()
        function twitterAnimation() {
            const twitterContainer = document.querySelector("#twitterIcon")
            const twitter = lottie.loadAnimation({
                container: twitterContainer, // Required
                path: "https://assets5.lottiefiles.com/packages/lf20_tchviu1i.json", // Required
                renderer: "svg", // Required
                loop: true, // Optional
                autoplay: false, // Optional
            })
            twitterContainer.addEventListener("mouseenter", () =>
                twitter.playSegments([0, 120], true)
            )
            twitterContainer.addEventListener("mouseleave", () => twitter.stop())
        }
    }


    render() {
        return (
            <>
                <footer className="{{class}}">
                    <div className="footer container">
                        <div className="col-sm-2 col-3 footer-left">
                            <img
                                src={logotype}
                                alt="logotype"
                                className="logotype"
                            />
                            <div className="copyright">
                                Copyright © 2024
                            </div>
                        </div>
                        <div className="col-sm-9 col-12 footer-right">
                            <div className="links">
                                {!this.props.isLoggedIn &&
                                <div className="link">
                                    <Link to="/signup">
                                        <div className="line"></div>
                                        S'inscrire
                                    </Link>
                                    <Link to="/login">
                                        <div className="line"></div>
                                        Se connecter
                                    </Link>
                                </div>}
                                <div className="link">
                                    <Link to="/cgu">
                                        <div className="line"></div>
                                        Conditions générales d'utilisation
                                   </Link>
                                    <Link to="/cookies">
                                        <div className="line"></div>
                                        Politique des cookies
                                   </Link>
                                </div>
                                <div className="link">
                                    <Link to="/contact">
                                       <div className="line"></div>
                                            Nous contacter
                                       </Link>
                                </div>
                            </div>
                            <hr />
                            <div className="social">
                                <a href="https://www.instagram.com" id="instagramIcon"></a>
                                <a href="https://www.facebook.com" id="facebookIcon"></a>
                                <a href="https://www.twitter.com" id="twitterIcon"></a>
                            </div>

                        </div>
                    </div>
                </footer>

            </>
        );
    }
}

function mapStateToProps(state) {
    const {isLoggedIn} = state.auth;

    return {
        isLoggedIn
    };
}

export default connect(mapStateToProps)(Footer);
