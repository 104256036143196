import {UAParser} from "ua-parser-js";
import * as  arch from "arch";

export const inArray = (array, values) => {
    for (const element of values) {
        if (array.indexOf(element) !== -1) {
            return true;
        }
    }
    return false;
}

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const getBrowser = () => {
    let browser, cpu, device, engine, os, parser;
    parser = new UAParser();
    browser = parser.getBrowser();
    device = parser.getDevice();
    engine = parser.getEngine();
    os = parser.getOS();
    cpu = parser.getCPU();
    return {
        browserName: browser.name,
        browserVersion: browser.version,
        deviceModel: device.model,
        deviceType: device.type,
        deviceVendor: device.vendor,
        engineName: engine.name,
        engineVersion: engine.version,
        osName: os.name,
        osVersion: os.version,
        cpuArchitecture: !!cpu.architecture ? cpu.architecture : arch(),
        language: navigator.language,
        languages: navigator.languages,
        userAgent: navigator.userAgent,
        referrer: document.referrer || 'N/A',
        online: navigator.onLine,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        screenResolution: window.screen.width + ' x ' + window.screen.height,
        cookieEnabled: navigator.cookieEnabled
    };
}

export const formatPhoneNumber = (number) => {
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
}

export const manageApiErrors = (errors, phoneNumberAttribute) => {
    if (!!phoneNumberAttribute) {
        Object.keys(errors).forEach(function (key) {
            if (errors[key] && errors[key][0]) {
                if (key === phoneNumberAttribute) {
                    errors[key] = {
                        "formattedNumber": errors[key][0]
                    }
                } else {
                    errors[key] = errors[key][0]
                }
            }
        });
    } else {
        Object.keys(errors).forEach(function (key) {
            if (errors[key] && errors[key][0]) {
                errors[key] = errors[key][0]
            }
        });
    }
    return errors;
}

export const address = {
    fullStreetNumber: '',
    streetAddress: '',
    addressLine2: '',
    city: '',
    postalOrZipCode: '',
    stateOrProvinceOrRegion: '',
    country: '',
    countryCode: '',
    label: '',
    value: {
        place_id: '',
    },
}

export const idData = {
    label: '',
    id: '',
}

export const getProfileUrl = (userProfile) => {
    if (userProfile == null) {
        return "/assets/images/user/studentm.svg";
    }

    if (userProfile.account.photo) {
        return userProfile.account.photo.url;
    } else {
        if (userProfile.memberTypeEnum === "STUDENT") {
            if (userProfile.account.genderTypeEnum === "MALE") {
                return "/assets/images/user/studentm.svg";
            } else {
                return "/assets/images/user/studentf.svg";
            }
        } else if (userProfile.memberTypeEnum === "ENGINEER") {
            if (userProfile.account.genderTypeEnum === "MALE") {
                return "/assets/images/user/graduatem.svg";
            } else {
                return "/assets/images/user/graduatef.svg";
            }
        } else if (userProfile.memberTypeEnum === "TEACHER") {
            if (userProfile.account.genderTypeEnum === "MALE") {
                return "/assets/images/user/teacherm.png";
            } else {
                return "/assets/images/user/teacherf.png";
            }
        } else {
            return "/assets/images/user/studentm.svg";
        }
    }
}

export const isFormFieldValid = (props, name, attribute) => {
    if (!!attribute && Object.keys(props.touched).length !== 0 && Object.keys(props.errors).length !== 0) {
        return !!(props.touched[name] && props.errors[name] && props.errors[name][attribute]);
    }
    return !!(props.touched[name] && props.errors[name]);
}

export const getFormErrorMessage = (props, name, attribute) => {
    if (!!attribute && Object.keys(props.touched).length !== 0 && Object.keys(props.errors).length !== 0) {
        return isFormFieldValid(props, name, attribute) &&
            <small className="p-error">{props.errors[name][attribute]}</small>;
    }
    return isFormFieldValid(props, name) && <small className="p-error">{props.errors[name]}</small>;
}


export const getPosition = (position) => {
    return {
        latitude: position.lat(),
        longitude: position.lng()
    };
}

export const getAddressObject = (address_components) => {
    const ShouldBeComponent = {
        fullStreetNumber: ["street_number"],
        postalOrZipCode: ["postal_code"],
        streetAddress: ["street_address", "route"],
        stateOrProvinceOrRegion: [
            "administrative_area_level_1",
            "administrative_area_level_2",
            "administrative_area_level_3",
            "administrative_area_level_4",
            "administrative_area_level_5"
        ],
        city: [
            "locality",
            "sublocality",
            "sublocality_level_1",
            "sublocality_level_2",
            "sublocality_level_3",
            "sublocality_level_4"
        ],
        country: ["country"]
    };

    const address = {
        fullStreetNumber: "",
        postalOrZipCode: "",
        streetAddress: "",
        stateOrProvinceOrRegion: "",
        city: "",
        country: "",
        countryCode: "",
        label: "",
        value: {
            place_id: "ChIJL8_Gmv7q9EcRWDQQLqRzAwo"
        }
    };
    address_components.forEach(component => {
        for (let shouldBe in ShouldBeComponent) {
            if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                if (shouldBe === "country") {
                    address['countryCode'] = component.short_name;
                    address['country'] = component.long_name;
                } else {
                    address[shouldBe] = component.long_name;
                }
            }
        }
    });
    return address;

}

export const phone = {
    dialCode: '',
    countryCode: '',
    name: '',
    formattedNumber: '',
    valid: true,
}

export const getFormattedPhone = (value, country) => {
    country['formattedNumber'] = value
    return country;
}

