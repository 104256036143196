import React, { Fragment } from 'react';
import Footer from '../../components/footer/Footer';
import lottie from "lottie-web"
import { Link, Redirect } from 'react-router-dom'
import "./login.css"
import Navbar from '../../components/navbar/Navbar';
import * as Yup from "yup";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { getFormErrorMessage } from "../../helpers/utils";
import { Formik } from 'formik';
import { login } from "../../actions/auth";
import { Alert } from "react-bootstrap";
import DefaultMeta from "../../components/seo/DefaultMeta";
import { Helmet } from "react-helmet";
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';

class Login extends React.Component {

    emptyElement = {
        email: '',
        password: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            showError: false,
            loading: false,
            errorMessage: "",
            mdp: false
        };

        this.formikProps = {
            initialValues: this.emptyElement,
            validationSchema: Yup.object().shape({
                email: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')).email(I18n.t('INVALID_EMAIL')),
                password: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
            })
        };

        this.handleLogin = this.handleLogin.bind(this);
    }

    componentDidMount() {
        eyeAnimation()

        function eyeAnimation() {
            document.querySelectorAll(".eye").forEach((element) => {
                const eye = lottie.loadAnimation({
                    container: element, // Required
                    path: "https://assets9.lottiefiles.com/packages/lf20_fev8g3sf.json", // Required
                    renderer: "svg", // Required
                    loop: false, // Optional
                    autoplay: true, // Optional
                })

                eye.setSpeed(2)
                element.addEventListener("click", () => {
                    eye.play()
                    eye.playDirection === 1 ? eye.setDirection(-1) : eye.setDirection(1)
                    element.parentNode.childNodes[1].type === "password"
                        ? (element.parentNode.childNodes[1].type = "text")
                        : (element.parentNode.childNodes[1].type = "password")
                })
            })
        }

        //checkboxAnimation
        checkboxAnimation()

        function checkboxAnimation() {
            document.querySelectorAll(".checkbox").forEach((element) => {
                const checkbox = lottie.loadAnimation({
                    container: element, // Required
                    path: "https://assets3.lottiefiles.com/packages/lf20_uqehydj7.json", // Required
                    renderer: "svg", // Required
                    loop: false, // Optional
                    autoplay: false, // Optional
                })
                checkbox.setDirection(-1)
                checkbox.setSpeed(2)
                element.addEventListener("click", (e) => {
                    checkbox.play()
                    checkbox.playDirection === 1
                        ? checkbox.setDirection(-1)
                        : checkbox.setDirection(1)
                    element.parentElement.querySelector("input").checked =
                        !element.parentElement.querySelector("input").checked
                })
            })
        }

        const togglePasswordButton = document.getElementById('togglePassword');
        const passwordInput = document.getElementById('password');

        togglePasswordButton.addEventListener('click', function () {
            if (passwordInput.type === 'password') {
                passwordInput.type = 'text';
                this.setState({ mdp: true })
            } else {
                passwordInput.type = 'password';
                this.setState({ mdp: false })
            }
        });
    }

    handleLogin = (element, { setStatus, setErrors }) => {
        const { dispatch, history } = this.props;
        this.setState({ loading: true });
        dispatch(login(element.email, element.password))
            .then((objectLogin) => {
                if (objectLogin.code === "ACTIVATION_CODE_SUCCESS") {
                    history.push(`/password/${element.email}/${element.password}`)
                } else {
                    //to refresh  session data
                    window.location.reload();
                }
            })
            .catch(() => {
                this.setState({
                    loading: false,
                });

                console.log("authme     ", this.props.message)

                let errorMessage = this.props.message.code;
                if (errorMessage === 'USER_STATUS_PENDING_PASSWORD_ACTIVATION') {
                    errorMessage = 'USER_STATUS_PENDING_PASSWORD_ACTIVATION_MESSAGE'
                }

                this.setState({
                    showError: true,
                    errorMessage: errorMessage
                });
            });
    }

    render() {
        const { isLoggedIn } = this.props;
        if (isLoggedIn) {
            return <Redirect to="/" />;
        }
        return <Fragment>
            <DefaultMeta />
            <Helmet>
                <title>Se connecter à Trouvare</title>
                <meta name="description" content="connectez-vous à Trouvare pour commencer à publier vos offres." />
                <meta property="og:url" content="https://trouvare.com/login" />
                <link rel="canonical" href="https://trouvare.com/login" />
            </Helmet>
            <Navbar />
            <main className="container login">
                <section className="navigation">
                    <Link to="/" className="back"> {"<"} Retour</Link>
                    <span id="notice_number"></span>
                </section>


                <div className="login-containe col-md-8 col-lg-6">

                    <div className='longin-containe-header mb-4 col-10'>
                        <h1 className='mb-1'>Connexion</h1>
                        <p>Connectez-vous pour découvrir toutes nos fonctionnalités.</p>
                        {!!this.state.errorMessage && <Alert className="font-xsss fw-600 p-error"
                               variant='danger'>
                            {I18n.t(this.state.errorMessage)}
                        </Alert>}
                    </div>

                    <Formik
                        enableReinitialize
                        initialValues={this.formikProps.initialValues}
                        validationSchema={this.formikProps.validationSchema}
                        onSubmit={this.handleLogin}
                    >
                        {props => {
                            console.log(props)
                            return <form onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                onSubmit={props.handleSubmit} className="col-10">

                                <div className='mb-4'>
                                    <label htmlFor="password">Adresse mail<b>*</b></label>
                                    <div class="input-group  mb-3">

                                        <input
                                            class="form-control"
                                            name="email"
                                            value={props.values.email}
                                            onChange={props.handleChange}
                                            id="email"
                                            type="text" />

                                    </div>
                                    {getFormErrorMessage(props, 'email')}

                                </div>
                                <div>
                                    <label htmlFor="password">Mot de passe<b>*</b></label>
                                    <div class="input-group  mb-1">

                                        <input
                                            class="form-control"
                                            id="password"
                                            type="password"
                                            name="password"
                                            value={props.values.password}
                                            onChange={(e) => props.handleChange(e)}
                                        />
                                        <span class="input-group-text" id="togglePassword" onClick={() => this.setState({ mdp: !this.state.mdp })}>{this.state.mdp ? <span className='pi pi-eye'></span> : <span className='pi pi-eye-slash'></span>}</span>
                                    </div>
                                    {getFormErrorMessage(props, 'password')}

                                </div>
                                <div className='mb-4 '>

                                    <Link to="/forgotpasswordrequest" className="forgot-password">Mot de passe oublié
                                        ?</Link>
                                </div>
                                <div className="remember mb-4">
                                    <div className="checkbox"></div>
                                    <input hidden name="remember" type="checkbox" />
                                    <span>Se souvenir de moi</span>
                                </div>
                                <Button loading={this.state.loading} type="submit" label="Se connecter" className="tButton form-control mb-4" />
                                <div className='redirection'>
                                    <p >envie de nous rejoindre ?  <a href="/src/pages/signup.html" className='forgot-password'>

                                        <Link to="/signup" className='forgot-password-link'> 
                                        <span style={{color:'#ed6d5f'}}><b>Creer un compte</b></span></Link>
                                    </a></p>
                                </div>
                            </form>
                        }}
                    </Formik>
                </div>
            </main>

            <Footer />
        </Fragment>;
    }
}


function mapStateToProps(state) {
    const { isLoggedIn } = state.auth;
    const message = state.message;
    return {
        isLoggedIn,
        message
    };
}

export default connect(mapStateToProps)(Login);

