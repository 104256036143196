import React from 'react';
import {ErrorMessage, Field} from 'formik';
import {getFormErrorMessage} from "../../helpers/utils";
import * as Yup from "yup";
import {I18n} from "react-redux-i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";

export const FormTypes = {
    INTEGER: 'INTEGER',
    REAL: 'REAL',
    STRING: 'STRING',
    TEXT: 'TEXT',
    LIST_STRING: 'LIST_STRING',
    LIST_INTEGER: 'LIST_INTEGER',
    LIST_REAL: 'LIST_REAL',
    DATE: 'DATE',
    INTEGER_INTERVAL: 'INTEGER_INTERVAL',
    REAL_INTERVAL: 'REAL_INTERVAL',
    DATE_INTERVAL: 'DATE_INTERVAL'
}

export const initForm = (formSchema, initialFormData, initialValidationSchema) => {
    let _validation = {};
    initialFormData.details = {}

    for (const key of Object.keys(formSchema)) {
        let code = formSchema[key].code
        initialFormData.details[code] = '';

        if (formSchema[key].type === FormTypes.STRING) {
            _validation[code] = Yup.string();
        } else if (formSchema[key].type === FormTypes.TEXT) {
            _validation[code] = Yup.string()
        } else if (formSchema[key].type === FormTypes.DATE) {
            _validation[code] = Yup.date()
        } else if (formSchema[key].type === FormTypes.INTEGER) {
            _validation[code] = Yup.number().integer(I18n.t('FIELD_MUST_BE_INTEGER'))
        } else if (formSchema[key].type === FormTypes.REAL) {
            _validation[code] = Yup.number()
        } else if (formSchema[key].type === FormTypes.LIST_STRING) {
            _validation[code] = Yup.string()
        } else if (formSchema[key].type === FormTypes.LIST_INTEGER) {
            _validation[code] = Yup.number().integer(I18n.t('FIELD_MUST_BE_INTEGER'))
        } else if (formSchema[key].type === FormTypes.LIST_REAL) {
            _validation[code] = Yup.number()
        } else if (formSchema[key].type === "select") {
            _validation[code] = Yup.string().oneOf(formSchema[key].options.map(o => o.value));
        }

        if (formSchema[key].required) {
            _validation[code] = _validation[code].required(I18n.t('FIELD_IS_REQUIRED'))
        }
    }
    const validation = Yup.object().shape({
        details: Yup.object().shape(_validation)
    })
    const validationSchema = initialValidationSchema.concat(validation);
    return {initialFormData, validationSchema};
}

export const getElementData = (structures, key, value) => {
    let structure = structures.find(x => x.code === key);
    let icon = structure.icon

    if (!!icon) {
        icon = icon.replace("fas ", "");
        icon = icon.replace("fa-", "");
    } else {
        icon = "asterisk"
    }

    return <div className="specification">
        <FontAwesomeIcon icon={faCircle} size="4x" style={{color: "#ed6d5f"}}/>
        <FontAwesomeIcon
            icon={["fas",icon]}
            size="2x"
            inverse
        />
        <span className="name">{structure.label}</span>
        <span className="content">{value} {structure.unit}</span>
    </div>
};

export const getFormElement = (props, elementSchema) => {
    const attributes = {
        name: elementSchema.code,
        unit: elementSchema.unit,
        label: elementSchema.label,
        required: elementSchema.required,
        options: elementSchema.values
    };

    if (elementSchema.type === FormTypes.STRING) {
        return <TextField attributes={attributes} props={props}/>
    } else if (elementSchema.type === FormTypes.REAL) {
        return <NumberField attributes={attributes} props={props}/>
    } else if (elementSchema.type === FormTypes.INTEGER) {
        return <IntegerField attributes={attributes} props={props}/>
    } else if (elementSchema.type === FormTypes.TEXT) {
        return <TextAreaField attributes={attributes} props={props}/>
    } else if (elementSchema.type === FormTypes.DATE) {
        return <DateField attributes={attributes} props={props}/>
    } else if (elementSchema.type === FormTypes.LIST_INTEGER) {
        return <ListStringField attributes={attributes} props={props}/>
    } else if (elementSchema.type === FormTypes.LIST_REAL) {
        return <ListStringField attributes={attributes} props={props}/>
    } else if (elementSchema.type === FormTypes.LIST_STRING) {
        return <ListStringField attributes={attributes} props={props}/>
    } else if (elementSchema.type === FormTypes.INTEGER_INTERVAL) {
        return <ListStringField attributes={attributes} props={props}/>
    } else if (elementSchema.type === FormTypes.REAL_INTERVAL) {
        return <ListStringField attributes={attributes} props={props}/>
    } else if (elementSchema.type === FormTypes.DATE_INTERVAL) {
        return <ListStringField attributes={attributes} props={props}/>
    }
}

export function TextField(parameters) {
    const {name, unit, required, label, placeholder, ...rest} = parameters.attributes
    return (
        <>
            {label && <label htmlFor={name}>{label} {!!unit && <i>({unit})</i>} {required && <b>*</b>}</label>}
            <div className="btn btn-secondary">
                <input
                    name={name}
                    id={name}
                    className="inputs"
                    type="text"
                    placeholder={placeholder || ""}
                    value={parameters.props.values.details[name]}
                    onChange={(event) => parameters.props.setFieldValue('details.' + name, event.target.value)}
                    {...rest}
                />
            </div>
            {getFormErrorMessage(parameters.props, 'details', name)}
        </>
    )
}

export function DateField(parameters) {
    const {name, unit, required, label, placeholder, ...rest} = parameters.attributes
    return (
        <>
            {label && <label htmlFor={name}>{label} {!!unit && <i>({unit})</i>} {required && <b>*</b>}</label>}
            <div className="btn btn-secondary">
                <input
                    name={name}
                    id={name}
                    className="inputs"
                    type="date"
                    placeholder={placeholder || ""}
                    value={parameters.props.values.details[name]}
                    onChange={(event) => parameters.props.setFieldValue('details.' + name, event.target.value)}
                    {...rest}
                />
            </div>
            {getFormErrorMessage(parameters.props, 'details', name)}
        </>
    )
}

export function TextAreaField(parameters) {
    const {name, unit, required, label, placeholder, ...rest} = parameters.attributes
    return (
        <>
            {label && <label htmlFor={name}>{label} {!!unit && <i>({unit})</i>} {required && <b>*</b>}</label>}
            <div className="btn btn-secondary">
                <textarea
                    onKeyDown={(event) => event.keyCode === 13 && event.stopPropagation()}
                    name={name}
                    id={name}
                    className="inputs"
                    rows="4"
                    cols="50"
                    placeholder={placeholder || ""}
                    value={parameters.props.values.details[name]}
                    onChange={(event) => parameters.props.setFieldValue('details.' + name, event.target.value)}
                    {...rest}
                />
            </div>
            {getFormErrorMessage(parameters.props, 'details', name)}
        </>
    )
}

export function NumberField(parameters) {
    const {name, unit, required, label, placeholder, ...rest} = parameters.attributes
    return (
        <>
            {label && <label htmlFor={name}>{label} {!!unit && <i>({unit})</i>} {required && <b>*</b>}</label>}
            <div className="btn btn-secondary">
                <input
                    name={name}
                    id={name}
                    className="inputs"
                    type="number"
                    placeholder={placeholder || ""}
                    value={parameters.props.values.details[name]}
                    onChange={(event) => parameters.props.setFieldValue('details.' + name, event.target.value)}
                    {...rest}
                />
            </div>
            {getFormErrorMessage(parameters.props, 'details', name)}
        </>
    )
}

export function IntegerField(parameters) {
    const {name, unit, required, label, placeholder, ...rest} = parameters.attributes
    return (
        <>
            {label && <label htmlFor={name}>{label} {!!unit && <i>({unit})</i>} {required && <b>*</b>}</label>}
            <div className="btn btn-secondary">
                <input
                    name={name}
                    id={name}
                    className="inputs"
                    type="number"
                    placeholder={placeholder || ""}
                    value={parameters.props.values.details[name]}
                    onChange={(event) => parameters.props.setFieldValue('details.' + name, event.target.value)}
                    {...rest}
                />
            </div>
            {getFormErrorMessage(parameters.props, 'details', name)}
        </>
    )
}

export function ListStringField(parameters) {
    const {name, unit, required, label, options} = parameters.attributes
    return (
        <>
            {label && <label htmlFor={name}>{label} {!!unit && <i>({unit})</i>} {required && <b>*</b>}</label>}
            <div className="btn btn-secondary">
                <select
                    className="inputs"
                    name={name}
                    id={name}
                    value={parameters.props.values.details[name]}
                    onChange={(event) => parameters.props.setFieldValue('details.' + name, event.target.value)}
                >
                    <option value="">{I18n.t('CHOOSE')}...</option>
                    {options.map((optn, index) => <option key={index} value={optn} label={optn}/>)}
                </select>
            </div>
            {getFormErrorMessage(parameters.props, 'details', name)}
        </>
    )
}
