import React, { Fragment } from 'react';

import Footer from '../../components/footer/Footer';
import lottie from "lottie-web"
import { Link, Redirect } from 'react-router-dom'
import "./signup.css"
import Navbar from '../../components/navbar/Navbar';
import { getFormattedPhone, getFormErrorMessage, manageApiErrors, phone } from "../../helpers/utils";
import fr from 'react-phone-input-2/lang/fr.json'
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import { I18n } from "react-redux-i18n";
import UserService from "../../services/UserService";
import { Formik } from 'formik';
import DefaultMeta from "../../components/seo/DefaultMeta";
import { Helmet } from "react-helmet";
import { Password } from "primereact/password";
import { Checkbox } from 'primereact/checkbox';
import { Button } from "primereact/button";
import { RadioButton } from 'primereact/radiobutton';

class SignUp extends React.Component {

    emptyElement = {
        userName: '',
        firstName: '',
        lastName: '',
        genderTypeEnum: '',
        email: '',
        password: '',
        password2: '',
        memberTypeEnum: '',
        mobilePhone: phone,
        cgu: false,
        companyName: '',
        companyRccmNumber: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            isRegistered: false,
            loading: false,
            viewPassword1: false,
            viewPassword2: false,
            accountType: "UNKNOWN"
        };

        this.formikProps = {
            initialValues: this.emptyElement,
            validationSchema: Yup.object().shape({
                userName: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                lastName: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                genderTypeEnum: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                email: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')).email(I18n.t('INVALID_EMAIL')),
                password: Yup
                    .string()
                    .required(I18n.t('FIELD_IS_REQUIRED'))
                    .min(6, I18n.t('PASSWORD_SHORT')),
                password2: Yup
                    .string()
                    .required(I18n.t('FIELD_IS_REQUIRED'))
                    .oneOf([Yup.ref('password'), null], I18n.t('PASSWORD_MUST_MATCH')),
                cgu: Yup.bool().oneOf([true], 'Veuillez accepter les CGU pour continuer'),
                mobilePhone: Yup.object().shape({
                    formattedNumber: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
                }),
            })
        };

        this.formikCompanyProps = {
            initialValues: this.emptyElement,
            validationSchema: Yup.object().shape({
                companyName: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                userName: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                lastName: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                genderTypeEnum: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                email: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')).email(I18n.t('INVALID_EMAIL')),
                password: Yup
                    .string()
                    .required(I18n.t('FIELD_IS_REQUIRED'))
                    .min(6, I18n.t('PASSWORD_SHORT')),
                password2: Yup
                    .string()
                    .required(I18n.t('FIELD_IS_REQUIRED'))
                    .oneOf([Yup.ref('password'), null], I18n.t('PASSWORD_MUST_MATCH')),
                cgu: Yup.bool().oneOf([true], 'Veuillez accepter les CGU pour continuer'),
                mobilePhone: Yup.object().shape({
                    formattedNumber: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
                }),
            })
        };
        this.elementService = new UserService();
        this.saveElement = this.saveElement.bind(this);
    }

    componentDidMount() {
        eyeAnimation()

        function eyeAnimation() {
            document.querySelectorAll(".eye").forEach((element) => {
                const eye = lottie.loadAnimation({
                    container: element, // Required
                    path: "https://assets9.lottiefiles.com/packages/lf20_fev8g3sf.json", // Required
                    renderer: "svg", // Required
                    loop: false, // Optional
                    autoplay: true, // Optional
                })

                eye.setSpeed(2)
                element.addEventListener("click", () => {
                    eye.play()
                    eye.playDirection === 1 ? eye.setDirection(-1) : eye.setDirection(1)
                    element.parentNode.childNodes[1].type === "password"
                        ? (element.parentNode.childNodes[1].type = "text")
                        : (element.parentNode.childNodes[1].type = "password")
                })
            })
        }


        //checkboxAnimation
        checkboxAnimation()

        function checkboxAnimation() {
            document.querySelectorAll(".checkbox").forEach((element) => {
                const checkbox = lottie.loadAnimation({
                    container: element, // Required
                    path: "https://assets3.lottiefiles.com/packages/lf20_uqehydj7.json", // Required
                    renderer: "svg", // Required
                    loop: false, // Optional
                    autoplay: false, // Optional
                })
                checkbox.setDirection(-1)
                checkbox.setSpeed(2)
                element.addEventListener("click", (e) => {
                    checkbox.play()
                    checkbox.playDirection === 1
                        ? checkbox.setDirection(-1)
                        : checkbox.setDirection(1)
                    element.parentElement.querySelector("input").checked =
                        !element.parentElement.querySelector("input").checked
                })
            })
        }

    }
    eyePassword1() {
        const passwordInput = document.getElementById('password');
        if (passwordInput.type === 'password') {
            passwordInput.type = 'text';
            this.setState({ viewPassword1: true })
        } else {
            passwordInput.type = 'password';
            this.setState({ viewPassword1: false })
        }
    }
    eyePassword2() {
        const passwordInput2 = document.getElementById('password2');
        if (passwordInput2.type === 'password') {
            passwordInput2.type = 'text';
            this.setState({ viewPassword2: true })
        } else {
            passwordInput2.type = 'password';
            this.setState({ viewPassword2: false })
        }
    }
    saveElement = (element, { setStatus, setErrors }) => {
        element.memberTypeEnum = "USER"
        if(this.state.accountType === "PRO"){
            element.memberTypeEnum = "PROFESSIONAL"
        }
        this.setState({ loading: true });
        this.elementService.add(element)
            .then((response) => {
                this.setState({
                    element: this.emptyElement,
                    isRegistered: true
                });
                this.formikProps.initialValues = this.emptyElement;
                this.props.history.push("/login");
            })
            .catch(error => {
                this.setState({
                    loading: false,
                });
                let errors = error.response.data.errors;
                setErrors(manageApiErrors(errors, "mobilePhone"));
            });
    }

    render() {
        if (this.state.isRegistered) {
            return <Redirect to="/login" />
        }
        return <Fragment>
            <DefaultMeta />
            <Helmet>
                <title>S’inscrire à Trouvare | Trouvare</title>
                <meta name="description"
                    content="Inscrivez-vous sur Trouvare et sauvegardez vos annonces, vos conversations et restez à jour sur les dernières annonces" />
                <meta property="og:url" content="https://trouvare.com/signup" />
                <link rel="canonical" href="https://trouvare.com/signup" />
            </Helmet>
            <Navbar />
            <main className="container signup-container">
                <section className="navigation">
                    <Link to="/login" className="back"> {"<"} Retour</Link>
                    <span id="notice_number"></span>
                </section>
                {this.state.accountType === "UNKNOWN" ?
                    <div className="login-containe col-md-8 col-lg-6">
                        <div className='longin-containe-header mb-4 col-10'>
                            <h1 className='mb-3'>Créez un compte</h1>
                            <p style={{ textAlign: 'justify' }}>Bénéficiez d’une expérience personnalisée avec du contenu en lien avec votre activité et vos centres d’intérêt sur notre service.</p>
                        </div>

                        <div className='col-10'>
                            <div className="field-radiobutton">
                                <RadioButton inputId="city1" name="city" value="1" onChange={(e) => this.setState({ accountType: "USER" })} checked={this.state.city === '1'} />
                                <label htmlFor="city1">Pour vous</label>
                            </div>
                            <div className="field-radiobutton">
                                <RadioButton inputId="city2" name="city" value="2" onChange={(e) => this.setState({ accountType: "PRO"})} checked={this.state.city === '2'} />
                                <label htmlFor="city2">Pour votre entreprise</label>
                            </div>
                            <div className='my-3'>
                                <p >vous avez deja un compte ?  <a href="/src/pages/signup.html" className='forgot-password'>

                                    <Link to="/login" className='forgot-password-link'>
                             <span style={{color:'#ed6d5f'}}><b>se connecter</b></span></Link>
                                </a></p>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        {this.state.accountType === "USER" ?
                            <div className="login-containe col-md-8 col-lg-6">

                                <div className='longin-containe-header mb-4 col-10'>
                                    <h1>Inscription</h1>
                                </div>
                                <Formik
                                    enableReinitialize
                                    initialValues={this.formikProps.initialValues}
                                    validationSchema={this.formikProps.validationSchema}
                                    onSubmit={this.saveElement}
                                >
                                    {props => {
                                        console.log(props)
                                        return <form onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                            onSubmit={props.handleSubmit} className="col-10">

                                            <div className='mb-3'>
                                                <label htmlFor="userName">Nom d'utilisateur<b>*</b></label>
                                                <input
                                                    name="userName"
                                                    value={props.values.userName}
                                                    onChange={props.handleChange}
                                                    id="userName"
                                                    type="text"
                                                    placeholder="Nom d'utilisateur"
                                                    className="form-control"
                                                />
                                                {getFormErrorMessage(props, 'userName')}
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor="userName">Prénom<b></b></label>
                                                <input
                                                    name="firstName"
                                                    value={props.values.firstName}
                                                    onChange={props.handleChange}
                                                    id="firstName"
                                                    type="text"
                                                    placeholder="Prénom"
                                                    className="form-control"
                                                />
                                                {getFormErrorMessage(props, 'firstName')}
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor="userName">Nom<b>*</b></label>
                                                <input
                                                    name="lastName"
                                                    value={props.values.lastName}
                                                    onChange={props.handleChange}
                                                    id="lastName"
                                                    type="text"
                                                    placeholder="Nom"
                                                    className="form-control"
                                                />
                                                {getFormErrorMessage(props, 'lastName')}
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor="userName">sexe<b>*</b></label>
                                                <div className="form-control">
                                                    <select
                                                        name="genderTypeEnum"
                                                        value={props.values.genderTypeEnum}
                                                        onChange={props.handleChange}
                                                        id="genderTypeEnum">
                                                        <option value>Choisir votre sexe</option>
                                                        <option value="MALE">{I18n.t("MALE")}</option>
                                                        <option value="FEMALE">{I18n.t("FEMALE")}</option>
                                                    </select>
                                                </div>
                                                {getFormErrorMessage(props, 'genderTypeEnum')}
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor="userName">Email<b>*</b></label>
                                                <input
                                                    name="email"
                                                    value={props.values.email}
                                                    onChange={props.handleChange}
                                                    id="email"
                                                    type="text"
                                                    placeholder="Email"
                                                    className="form-control"
                                                />
                                                {getFormErrorMessage(props, 'email')}
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor="mobilePhone">Numéro de téléphone</label>
                                                <PhoneInput
                                                    isValid={props.values.mobilePhone.valid}
                                                    localization={fr}
                                                    inputStyle={{ width: '100%', borderRadius: 14 }}
                                                    country={'cm'}
                                                    name="mobilePhone"
                                                    value={props.values.mobilePhone.formattedNumber}
                                                    onChange={(value, country) => props.setFieldValue('mobilePhone', getFormattedPhone(value, country))}
                                                    buttonStyle={{ borderTopLeftRadius: 14, borderBottomLeftRadius: 14 }}
                                                />
                                                {getFormErrorMessage(props, 'mobilePhone', 'formattedNumber')}
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor="password">Mot de passe<b>*</b></label>
                                                <div class="input-group  mb-1">
                                                    <input
                                                        className="form-control"
                                                        id="password"
                                                        type="password"
                                                        name="password"
                                                        value={props.values.password}
                                                        onChange={(e) => props.handleChange(e)}
                                                        placeholder='Mot de passe'
                                                    />
                                                    <span className="input-group-text" id="togglePassword" onClick={() => this.eyePassword1()}>{this.state.viewPassword1 ? <span className='pi pi-eye'></span> : <span className='pi pi-eye-slash'></span>}</span>
                                                </div>
                                                {getFormErrorMessage(props, 'password')}
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor="password">Confirmer Mot de passe<b>*</b></label>
                                                <div class="input-group  mb-1">
                                                    <input
                                                        class="form-control"
                                                        name="password2"
                                                        value={props.values.password2}
                                                        onChange={props.handleChange}
                                                        id="password2"
                                                        placeholder='Confirmer Mot de passe'
                                                    />
                                                    <span class="input-group-text" id="togglePassword2" onClick={() => this.eyePassword2()}>{this.state.viewPassword2 ? <span className='pi pi-eye'></span> : <span className='pi pi-eye-slash'></span>}</span>
                                                </div>
                                                {getFormErrorMessage(props, 'password2')}
                                            </div>
                                            <div className="field-checkbox mb-5">
                                                <Checkbox
                                                    name="cgu"
                                                    checked={props.values.cgu}
                                                    id="cgu"
                                                    onChange={(event) => props.setFieldValue('cgu', event.checked)}
                                                />
                                                &nbsp; Accepter les <Link style={{ color: "#ed6d5f" }} to="/cgu"
                                                    target="_blank">CGU </Link>
                                                <br />
                                                {getFormErrorMessage(props, 'cgu')}
                                            </div>
                                            <Button loading={this.state.loading} type="submit" label="S'inscrire" className="tButton form-control mb-4" />
                                            <div className='redirection'>
                                                <p >vous avez deja un compte ?  <a href="/src/pages/signup.html" className='forgot-password'>

                                                    <Link to="/login" className='forgot-password-link'> <span
                                                    style={{color:'#ed6d5f'}}
                                                    ><b>se connecter</b></span></Link>
                                                </a></p>
                                            </div>
                                        </form>
                                    }}
                                </Formik>
                            </div>
                            :
                            <div className="login-containe col-md-8 col-lg-6">

                                <div className='longin-containe-header mb-4 col-10'>
                                    <h1>Inscription</h1>

                                </div>

                                <Formik
                                    enableReinitialize
                                    initialValues={this.formikCompanyProps.initialValues}
                                    validationSchema={this.formikCompanyProps.validationSchema}
                                    onSubmit={this.saveElement}
                                >
                                    {props => {
                                        console.log(props)
                                        return <form onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                            onSubmit={props.handleSubmit} className="col-10">

                                            <div className='mb-3'>
                                                <label htmlFor="entreprise">Nom de l'entreprise<b>*</b></label>
                                                <input
                                                    name="companyName"
                                                    value={props.values.companyName}
                                                    onChange={props.handleChange}
                                                    id="companyName"
                                                    type="text"
                                                    placeholder="Ex: NAIL CONSULTING"
                                                    className="form-control"
                                                />
                                                {getFormErrorMessage(props, 'companyName')}

                                            </div>

                                            <div className='mb-3'>
                                                <label htmlFor="numeroC">Numero de registre de commerce (optionnel)<b></b></label>
                                                <input
                                                    name="companyRccmNumber"
                                                    value={props.values.companyRccmNumber}
                                                    onChange={props.handleChange}
                                                    id="companyRccmNumber"
                                                    type="text"
                                                    placeholder="Ex: RCCM/DLA/2022/A/12345"
                                                    className="form-control"
                                                />
                                                {getFormErrorMessage(props, 'companyRccmNumber')}

                                            </div>

                                            <div className='mb-3'>
                                                <label htmlFor="userName">Nom d'utilisateur<b>*</b></label>
                                                <input
                                                    name="userName"
                                                    value={props.values.userName}
                                                    onChange={props.handleChange}
                                                    id="userName"
                                                    type="text"
                                                    placeholder="Nom d'utilisateur"
                                                    className="form-control"
                                                />
                                                {getFormErrorMessage(props, 'userName')}

                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor="userName">Prénom<b></b></label>
                                                <input
                                                    name="firstName"
                                                    value={props.values.firstName}
                                                    onChange={props.handleChange}
                                                    id="firstName"
                                                    type="text"
                                                    placeholder="Prénom"
                                                    class="form-control"
                                                />
                                                {getFormErrorMessage(props, 'firstName')}

                                            </div>

                                            <div className='mb-3'>
                                                <label htmlFor="userName">Nom<b>*</b></label>
                                                <input
                                                    name="lastName"
                                                    value={props.values.lastName}
                                                    onChange={props.handleChange}
                                                    id="lastName"
                                                    type="text"
                                                    placeholder="Nom"
                                                    class="form-control"
                                                />
                                                {getFormErrorMessage(props, 'lastName')}

                                            </div>

                                            <div className='mb-3'>
                                                <label htmlFor="userName">sexe<b>*</b></label>
                                                <div className="form-control">

                                                    <select
                                                        name="genderTypeEnum"
                                                        value={props.values.genderTypeEnum}
                                                        onChange={props.handleChange}
                                                        id="genderTypeEnum">
                                                        <option value>Choisir votre sexe</option>
                                                        <option value="MALE">{I18n.t("MALE")}</option>
                                                        <option value="FEMALE">{I18n.t("FEMALE")}</option>
                                                    </select>
                                                </div>
                                                {getFormErrorMessage(props, 'genderTypeEnum')}
                                            </div>

                                            <div className='mb-3'>
                                                <label htmlFor="userName">Email<b>*</b></label>
                                                <input
                                                    name="email"
                                                    value={props.values.email}
                                                    onChange={props.handleChange}
                                                    id="email"
                                                    type="text"
                                                    placeholder="Email"
                                                    class="form-control"
                                                />
                                                {getFormErrorMessage(props, 'email')}

                                            </div>

                                            <div className='mb-3'>
                                                <label htmlFor="mobilePhone">Numéro de téléphone</label>
                                                <PhoneInput
                                                    isValid={props.values.mobilePhone.valid}
                                                    localization={fr}
                                                    inputStyle={{ width: '100%', borderRadius: 14 }}
                                                    country={'cm'}
                                                    name="mobilePhone"
                                                    value={props.values.mobilePhone.formattedNumber}
                                                    onChange={(value, country) => props.setFieldValue('mobilePhone', getFormattedPhone(value, country))}
                                                    buttonStyle={{ borderTopLeftRadius: 14, borderBottomLeftRadius: 14 }}


                                                />
                                                {getFormErrorMessage(props, 'mobilePhone', 'formattedNumber')}
                                            </div>


                                            <div className='mb-3'>
                                                <label htmlFor="password">Mot de passe<b>*</b></label>
                                                <div class="input-group  mb-1">

                                                    <input
                                                        class="form-control"
                                                        id="password"
                                                        type="password"
                                                        name="password"
                                                        value={props.values.password}
                                                        onChange={(e) => props.handleChange(e)}
                                                        placeholder='Mot de passe'
                                                    />
                                                    <span class="input-group-text" id="togglePassword" onClick={() => this.eyePassword1()}>{this.state.viewPassword1 ? <span className='pi pi-eye'></span> : <span className='pi pi-eye-slash'></span>}</span>
                                                </div>
                                                {getFormErrorMessage(props, 'password')}

                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor="password">Confirmer Mot de passe<b>*</b></label>
                                                <div className="input-group  mb-1">

                                                    <input
                                                        className="form-control"
                                                        name="password2"
                                                        value={props.values.password2}
                                                        onChange={props.handleChange}
                                                        id="password2"
                                                        placeholder='Confirmer Mot de passe'
                                                    />
                                                    <span className="input-group-text" id="togglePassword2" onClick={() => this.eyePassword2()}>{this.state.viewPassword2 ? <span className='pi pi-eye'></span> : <span className='pi pi-eye-slash'></span>}</span>
                                                </div>
                                                {getFormErrorMessage(props, 'password2')}

                                            </div>



                                            <div className="field-checkbox mb-5">
                                                <Checkbox
                                                    name="cgu"
                                                    checked={props.values.cgu}
                                                    id="cgu"
                                                    onChange={(event) => props.setFieldValue('cgu', event.checked)}
                                                />
                                                &nbsp; Accepter les <Link style={{ color: "#ed6d5f" }} to="/cgu"
                                                    target="_blank">CGU </Link>
                                                <br />
                                                {getFormErrorMessage(props, 'cgu')}
                                            </div>

                                            <Button loading={this.state.loading} type="submit" label="S'inscrire" className="tButton form-control mb-4" />

                                            <div className='redirection'>
                                         <p >vous avez deja un compte ?  <a href="/src/pages/signup.html" className='forgot-password'>
                                         <Link to="/login" className='forgot-password-link'>
                                             <span style={{color:'#ed6d5f'}}><b>se connecter</b></span></Link>
                                                </a></p>
                                            </div>
                                        </form>

                                    }}
                                </Formik>
                            </div>
                        }
                    </>
                }


            </main>
        </Fragment>;
    }
}

export default SignUp;
