import React, {Component} from 'react';
import "./navbar.css"
import logo from '../../assets/img/logo.svg'
import {Link} from 'react-router-dom'
import {logout} from "../../actions/auth";
import {connect} from "react-redux";
import {history} from "../../helpers/history";
import {Role} from "../../helpers/role";

class Navbar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: null
        }

        this.logOut = this.logOut.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        //open burger
        document.querySelector("#burger-icon").addEventListener("click", () => {
            document.querySelector(".burger-filter").style.display = null
        })

        //hover burger
        document
            .querySelector(".burger-menu")
            .querySelectorAll("a")
            .forEach((item) => {
                item.addEventListener("mouseenter", () => {
                    item.style.color = "#ed6d5f"
                })
                item.addEventListener("mouseleave", () => {
                    item.style.color = null
                })
            })

        //close burger
        document.querySelector("#close-burger-menu").addEventListener("click", () => {
            document.querySelector(".burger-filter").style.display = "none"
        })
        document.querySelector(".burger-filter").addEventListener("click", (e) => {
            if (e.target === document.querySelector(".burger-filter")) {
                document.querySelector(".burger-filter").style.display = "none"
            }
        })
    }

    handleSearch(e) {
        e.preventDefault();
        if (!!this.state.title) {
            window.location.assign("/search?" + new URLSearchParams({
                title: this.state.title,
                sortFilter: "creationDateDesc",
                start: 0,
                size: 8,
            }).toString())
        }
    }

    HandleFile(event) {
        console.log(event.target.files)
        preview(document.querySelector(".photo"))

        function preview(el) {
            let deleted = false
            const reader = new FileReader()
            reader.addEventListener("load", () => {
                const div = document.createElement("div")
                div.classList.add("col-lg-3")
                div.classList.add("photo")
                const img = document.createElement("div")
                img.classList.add("img-container")
                img.innerHTML = `<img src="${reader.result}">`
                div.appendChild(img)
                console.log("parent img", event.target.parentElement)
                // event.target.parentElement.appendChild(img)
                document.getElementById("global").appendChild(div)
                img.addEventListener("click", () => {
                    div.remove()
                    event.target.value = null
                    deleted = true
                })
            })
            reader.readAsDataURL(event.target.files[0])
        }
    }

    logOut() {
        this.props.dispatch(logout());
    }

    render() {
        return (
            <>
                <nav>
                    <div className="container navbar">
                        <div className="nav-left">
                            <Link to="/"> <img src={logo} alt="logo"/></Link>
                            <Link className="submit btn btn-primary" to="/place-ad">Déposer une annonce</Link>
                        </div>
                        <div className="nav-right">
                            <form onSubmit={this.handleSearch} className="search btn btn-secondary">
                                <button className="search-icon" type="submit">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentcolor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    </svg>
                                </button>
                                <input type="search" value={this.state.title} onChange={(e) => {
                                    this.setState({title: e.target.value})
                                }} placeholder="Rechercher..."/>
                            </form>
                            {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                            <div className="language">
                                <a href="" className="active">FR</a>
                                <a href="">EN</a>
                            </div>}
                            {this.props.isLoggedIn &&
                            <div>
                                Salut {this.props.account && this.props.account.data.username}
                            </div>}
                            <svg
                                id="burger-icon"
                                viewBox="0 0 24 24"
                                width="48"
                                height="48"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <line x1="21" y1="10" x2="7" y2="10"></line>
                                <line x1="21" y1="6" x2="3" y2="6"></line>
                                <line x1="21" y1="14" x2="3" y2="14"></line>
                                <line x1="21" y1="18" x2="7" y2="18"></line>
                            </svg>
                        </div>
                    </div>
                </nav>
                <div className="cookies-filter" id="flex-fallback" style={{display: "none"}}>
                    <div className="container-cookies" style={{height: "500px", justifyContent: "center"}}>
                        <div className="cookies-head">
                            <span className="cookies-title"> Problème de rendu </span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                //onClick="document.cookie = this.parentNode.parentNode.parentNode.style.display = 'none'"
                                style={{cursor: "pointer"}}
                            >
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </div>
                        <p style={{fontSize: "1.5rem"}}>
                            Veuillez mettre à jour votre appareil Apple pour une meilleure
                            navigation ou utiliser un autre navigateur via ce
                            <a
                                style={{textDecoration: "underline"}}
                                href="https://www.google.fr/chrome/?brand=ONGR&ds_kid=43700053082282945&utm_source=bing&utm_medium=cpc&utm_campaign=1009936%20%7C%20Chrome%20Win10%20%7C%20DR%20%7C%20ESS01%20%7C%20EMEA%20%7C%20FR%20%7C%20fr%20%7C%20Desk%20%7C%20SEM%20%7C%20BKWS%20-%20EXA%20%7C%20Txt%20~%20Top%20KWDS&utm_term=chrome&utm_content=Desk%20%7C%20BKWS%20-%20EXA%20%7C%20Txt%20~%20Chrome%20~%20Top%20KWDS%20-%20NEW&gclsrc=ds&gclid=COGzvNSS6_MCFQYuGwodWjsCRQ&gclsrc=ds"
                            >lien</a
                            >
                        </p>
                    </div>
                </div>
                <div className="burger-filter" style={{display: "none"}}>
                    <div className="burger-menu">
                        <svg
                            id="close-burger-menu"
                            viewBox="0 0 24 24"
                            width="32"
                            height="32"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                        <ul>
                            <li>
                                <Link to="/place-ad">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                        <polyline points="17 8 12 3 7 8"></polyline>
                                        <line x1="12" y1="3" x2="12" y2="15"></line>
                                    </svg>
                                    Déposer une annonce</Link>
                            </li>
                            <li>
                                <Link to="/search">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    </svg>
                                    Rechercher </Link>
                            </li>
                            <hr/>
                            <li>
                                <Link to="/">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                    </svg>
                                    Accueil
                                </Link>
                            </li>
                            {!this.props.isLoggedIn &&
                            <li>
                                <Link to="/login">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                                        <polyline points="10 17 15 12 10 7"></polyline>
                                        <line x1="15" y1="12" x2="3" y2="12"></line>
                                    </svg>
                                    Connexion/Inscription
                                </Link>
                            </li>}
                            {this.props.isLoggedIn &&
                            <li>
                                <Link to="/profile">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    Mon profil
                                </Link>
                            </li>}
                            {this.props.isLoggedIn &&
                                <li>
                                    <Link to="/page">
                                        <svg
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                        Ma page
                                    </Link>
                                </li>}
                            {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                            <li>
                                <Link className="new-msg" to="/messages">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="22" y1="2" x2="11" y2="13"></line>
                                        <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                    </svg>
                                    Messagerie
                                </Link>
                            </li>}
                            <li>
                                <Link to="/contact">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <circle cx="12" cy="12" r="4"></circle>
                                        <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                                    </svg>
                                    Contactez-nous
                                </Link>
                            </li>
                            {this.props.isLoggedIn &&
                            <li>
                                <Link onClick={this.logOut}>
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                        <polyline points="16 17 21 12 16 7"></polyline>
                                        <line x1="21" y1="12" x2="9" y2="12"></line>
                                    </svg>
                                    Deconnexion
                                </Link>
                            </li>}
                        </ul>
                    </div>
                </div>

            </>
        );
    }
}

function mapStateToProps(state) {
    const {isLoggedIn, account} = state.auth;

    return {
        isLoggedIn, account
    };
}

export default connect(mapStateToProps)(Navbar);