import React, {Component} from 'react'
import Footer from '../../components/footer/Footer'
import Navbar from '../../components/navbar/Navbar'
import logoType from '../../assets/img/logotype.svg'
import illustration from '../../assets/img/search-illustration.svg'
import './home.css'
import {Link} from 'react-router-dom'
import ScrollTop from '../../components/scroll-top/ScrollTop';
import CategoryService from "../../services/CategoryService";
import ItemService from "../../services/ItemService";
import {Paginator} from "primereact/paginator";
import {Carousel} from 'primereact/carousel';
import DefaultMeta from "../../components/seo/DefaultMeta";
import AdSkeleton from "../../components/skeleton/AdSkeleton";
import GooglePlacesAutocomplete, {geocodeByPlaceId} from "react-google-places-autocomplete";
import {getAddressObject, getPosition} from "../../helpers/utils";
import {CascadeSelect} from "primereact/cascadeselect";
import {Constant} from "../../helpers/const";
import {I18n} from "react-redux-i18n";
import CardH from '../../components/card/CardH'
import {Toast} from "primereact/toast";
import {connect} from "react-redux";
import {clearMessage} from "../../actions/message";

class Home extends Component {

    constructor(props) {
        super(props);

        this.pageSize = 8; //elements to load
        this.state = {
            title: null,
            address: null,
            position: null,
            subcategory: null,
            category: null,
            searchCategories: [],
            categories: [],
            first: 0,
            page: 0,
            totalRecords: 0,
            sortFilter: "creationDateDesc",
            elements: [],
            loading: false
        }
        this.handleSearch = this.handleSearch.bind(this);
        this.handleMapAddressChange = this.handleMapAddressChange.bind(this);
        this.onPage = this.onPage.bind(this);
        this.categoryService = new CategoryService();
        this.itemService = new ItemService();

        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '600px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '480px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }

    componentDidMount() {
        if (!!this.props.message && !!this.props.message.code) {
            if (this.props.message.code === "ITEM_SUCCESSFULLY_DELETED" || this.props.message.code === "ADD_AD_SUCCESS") {
                this.toast.show({
                    severity: 'success',
                    detail: I18n.t(this.props.message.code),
                    life: 6000
                });
                this.props.dispatch(clearMessage())
            }
        }

        this.loadItems();

        function input_colored() {
            document.querySelectorAll(".inputs").forEach((input) => {
                //console.log('input_colored',input.parentNode);

                if (input.value !== "") {
                    input.parentNode.style.color = " #ed6d5f"
                } else {
                    input.parentNode.style.color = null
                }
                input.addEventListener("input", (e) => {
                    if (input.value !== "") {
                        e.path[1].style.color = "#ed6d5f"
                    } else {
                        e.path[1].style.color = null
                    }
                })
            })
        }

        input_colored()
        this.loadCategories()
        this.loadSearchCategories()

    }

    handleMapAddressChange = (initialAddress) => {
        if (initialAddress) {
            let placeId = initialAddress["value"]["place_id"];
            geocodeByPlaceId(placeId)
                .then(results => {
                    if (results[0]) {
                        let address = getAddressObject(results[0].address_components);
                        address.label = initialAddress.label
                        address.value.place_id = initialAddress.value.place_id
                        let position = getPosition(results[0].geometry.location);
                        this.setState({position})
                        this.setState({address})
                    }
                })
                .catch(error => console.error("error found " + error));
        }
    }

    loadItems() {
        this.setState({loading: true});
        let page = new URLSearchParams(this.props.location.search).get("page");
        let p = this.state.page;
        if (page != null && page > 0) {
            this.setState({
                first: (page - 1) * this.pageSize,
                page: (page - 1),
            });
            p = page - 1;
        }

        const params = new URLSearchParams({
            start: p,
            size: this.pageSize,
            sortFilter: this.state.sortFilter
        });
        this.itemService.getPageParams(params)
            .then(response => {
                let elements = response.data.content.list;

                if (this.props.history.location.state?.type != null && !this.state.elements.isEmpty) {
                    let type = this.props.history.location.state.type;
                    let data = this.props.history.location.state.data;

                    if (type === "ITEM_DELETE") {
                        elements = elements.filter(val => val.id !== data);
                        this.setState({
                            elements,
                        });
                    }
                    if (type === "ITEM_ADD") {
                        elements.unshift(data);
                        this.setState({
                            elements,
                        });
                    }
                    this.props.history.push({pathname: '/', state: {type: null, data: null}});
                }
                this.setState({
                    elements: elements,
                    loading: false,
                    totalRecords: response.data.content.totalCount,
                })
            })
            .catch(error => {
                this.setState({loading: false});
            });

    }

    loadCategories() {
        this.categoryService.getAll().then(
            response => {
                console.log('categories ', response.data.content)
                let categories = response.data.content;
                this.setState({categories})
            }
        );
    }

    loadSearchCategories() {
        this.categoryService.getAllWithSubcategories().then(
            response => {
                let categories = []
                for (let i = 0; i < response.data.content.length; i++) {
                    let categoryData = response.data.content[i];
                    let subcategoriesData = categoryData.subcategories;
                    if (subcategoriesData === undefined) {
                        subcategoriesData = []
                    }
                    let subcategories = []
                    subcategories.push({
                        label: categoryData.nameFr,
                        id: categoryData.identifier,
                        category: true
                    })
                    for (let i = 0; i < subcategoriesData.length; i++) {
                        let subcategoryData = subcategoriesData[i];
                        subcategories.push({
                            label: subcategoryData.nameFr,
                            id: subcategoryData.identifier,
                            minImages: subcategoryData.minImages,
                            maxImages: subcategoryData.maxImages,
                            elements: subcategoryData.elements
                        })
                    }
                    categories.push({label: categoryData.nameFr, id: categoryData.identifier, subcategories})
                }
                this.setState({searchCategories: categories})
            }
        );
    }

    handleSearch(e) {
        e.preventDefault();
        const params = new URLSearchParams({
            title: this.state.title,
            start: this.state.page,
            size: this.pageSize,
            sortFilter: this.state.sortFilter,
            lat: this.state.position?.latitude,
            lon: this.state.position?.longitude,
            subcategory: !this.state.subcategory?.category ? this.state.subcategory?.id : undefined,
            category: this.state.subcategory?.category ? this.state.subcategory?.id : undefined,
        });

        [...params.entries()].forEach(([key, value]) => {
            if (!!!value || value === "undefined" || value === "null") {
                params.delete(key);
            }
        });

        window.location.assign("/search?" + params.toString())
    }

    onPage(event) {
        let start = event.first;
        let page = event.page;
        this.setState({loading: true});
        const params = new URLSearchParams({
            start: page,
            size: this.pageSize,
            sortFilter: this.state.sortFilter
        });
        this.itemService.getPageParams(params)
            .then(response => {
                this.setState({
                    first: start,
                    elements: response.data.content.list,
                    loading: false,
                    totalRecords: response.data.content.totalCount,
                })

                this.props.history.push({
                    pathname: '/',
                    search: "?" + new URLSearchParams({page: page + 1}).toString()
                })
            })
            .catch(error => {
                this.setState({loading: false});
            });
    }

    categoryTemplate(category) {
        let params = new URLSearchParams({
            category: category.identifier,
            sortFilter: "creationDateDesc",
            start: 0,
            size: 8,
        }).toString()
        return (
            <div className="categorieTemplate-content">
                <Link to={`/search?${params}`} name="category" value={category.value}
                      className="col">
                    <img
                        src={category.image.url}
                        alt={category.nameFr}
                    />
                    <div>
                        <h2 className="title">{category.nameFr}</h2>
                        <p><b id="category-number">{category.itemCount}</b> offre(s)</p>
                    </div>
                </Link>
            </div>
        );
    }

    render() {
        const paginatorTemplate = {
            layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
            'CurrentPageReport': (options) => {
                return (
                    <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} sur {options.totalRecords}
                    </span>
                )
            }
        }

        const skeletonList = [];
        for (let i = 0; i < this.pageSize; i++) {
            skeletonList.push(<AdSkeleton key={i}/>);
        }

        return (
            <>
                <DefaultMeta/>
                <Navbar/>
                <Toast style={{zIndex: 1000}} ref={(el) => this.toast = el}/>
                <main className="container home">
                    <div className="home-display">
                        <svg
                            width="100%"
                            height="461"
                            viewBox="0 0 1440 461"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M-3.05176e-05 0H1440V460.5C1152.08 342.417 -3.05176e-05 374 -3.05176e-05 374V0Z"
                                fill="url(#paint0_linear)"
                            />
                            <defs>
                                <linearGradient
                                    id="paint0_linear"
                                    x1="-772.5"
                                    y1="-184.413"
                                    x2="2020.89"
                                    y2="1277.1"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stop-color="#3366FF"/>
                                    <stop offset="1" stop-color="#0EA5E9"/>
                                </linearGradient>
                            </defs>
                        </svg>
                        <img src={logoType} alt="logotype"/>
                        Les bons plans commencent ici !
                    </div>


                    <section className="search">
                        <div className="col-11 search-container">
                            <form className="col-md-5 col-12" onSubmit={this.handleSearch}>
                                <CascadeSelect
                                    options={this.state.searchCategories}
                                    name="subcategory"
                                    value={this.state.subcategory}
                                    id="subcategory"
                                    onChange={(event) => this.setState({subcategory: event.value})}
                                    optionLabel="label"
                                    optionGroupLabel="label"
                                    optionGroupChildren={['subcategories']}
                                    className={this.state.subcategory ? "p-cascadeselectRed" : "r"}
                                    style={{width: '100%'}} placeholder={"Catégories"}/>

                                <GooglePlacesAutocomplete
                                    name="address"
                                    apiKey={Constant.GMAPS_KEY}
                                    apiOptions={{language: 'fr', region: 'fr'}}
                                    autocompletionRequest={{
                                        componentRestrictions: {
                                            country: ['cm', 'fr', 'us'],
                                        }
                                    }}
                                    selectProps={{
                                        onChange: (data) => {
                                            this.handleMapAddressChange(data)
                                        },
                                        value: this.state.address,
                                        placeholder: I18n.t('ENTER_CITY_OR_NEIGHBORHOOD'),
                                        styles: {
                                            input: (provided) => ({
                                                ...provided,
                                                color: "#222222",
                                            }),
                                            option: (provided) => ({
                                                ...provided,
                                                color: "#222222",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: "#222222",
                                            }),
                                        },
                                    }}
                                />
                                <div className="btn btn-secondary">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    </svg>
                                    <input
                                        placeholder="Que recherchez-vous ?"
                                        type="search"
                                        className="inputs"
                                        name="query"
                                        value={this.state.title}
                                        onChange={(e) => {
                                            this.setState({title: e.target.value})
                                        }}
                                    />
                                </div>
                                <div className="type">
                                    <div className="individual">
                                        <input type="checkbox" value="checked" name="individual" id="individual"/>
                                        <label for="individual">Annonces particulier</label>
                                    </div>
                                    <div className="pro">
                                        <input type="checkbox" value="checked" name="pro" id="pro"/>
                                        <label for="pro">Annonces pro</label>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary" style={{color: "white"}}>
                                    Lancer la recherche
                                </button>
                            </form>
                            <img
                                src={illustration}
                                alt="un Homme sur son ordinateur"
                                className="col-md-6 col-8"
                            />
                        </div>
                    </section>

                    <section className="categories">
                        <div className="title">
                            <h1>Catégories</h1>
                            <hr/>
                        </div>
                        <div className="card">
                            <Carousel value={this.state.categories} numVisible={4} numScroll={1}
                                      responsiveOptions={this.responsiveOptions} className="custom-carousel" circular
                                      autoplayInterval={3000} itemTemplate={this.categoryTemplate}/>
                        </div>
                    </section>


                    <section className="recent">
                        <div className="title">
                            <h1>Les dernières offres & opportunités</h1>
                            <hr/>
                        </div>
                        <div className="cards row row-cols-md-4 row-cols-1">
                            {this.state.loading ?
                                skeletonList :
                                this.state.elements?.map((item, index) => <CardH key={index} item={item}/>)
                            }
                        </div>

                        <Paginator template={paginatorTemplate} first={this.state.first} rows={this.pageSize}
                                   totalRecords={this.state.totalRecords} onPageChange={this.onPage}
                                   className="justify-content-start my-3"/>
                    </section>

                </main>
                <Footer/>
                <ScrollTop/>
            </>
        )
    }
}

function mapStateToProps(state) {
    const message = state.message;

    return {
        message
    };
}

export default connect(mapStateToProps)(Home);
