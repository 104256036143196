import React, { Fragment } from 'react';

import './profile.css'
import {Link} from 'react-router-dom'
import Footer from '../../components/footer/Footer';
import ScrollTop from '../../components/scroll-top/ScrollTop';
import Navbar from '../../components/navbar/Navbar';


class ProfilePro extends React.Component {

    constructor(props) {
        super(props);
        this.state = { data: [] };


    }




    render() {
        return <Fragment>
            <Navbar />

            <main class="container pro-container">
            <section className="navigation">
                    <Link to="/" className="back"> {"<"} Retour</Link>
                    <span id="notice_number"></span>
                </section>
                <div class="title">
                    <h1>Créer mon compte pro</h1>
                    <br/>
                    <span class="subtitle "
                    >Les champs marqués d’un <b>*</b> sont obligatoires
                    </span>
                </div>
                <form >
                    <div class="step1">
                        <div class="step-title">
                            <span class="number">1</span>
                            <span>Je renseigne mes informations :</span>
                        </div>
                        <div class="civility">
                            Civilité*
                            <div class="radios">
                                <label for="mr">
                                    <input required type="radio" name="civility" value="mr" id="mr" />
                                    Monsieur
                                </label>
                                <label for="ms">
                                    <input type="radio" name="civility" value="ms" id="ms" />
                                    Madame
                                </label>
                            </div>
                        </div>
                        <div class="fields">
                            <label for="name">
                                Nom*
                                <div class="btn btn-secondary">
                                    <input required id="name" name="name" type="text" />
                                </div>
                            </label>
                            <label for="surname">
                                Prénom*
                                <div class="btn btn-secondary">
                                    <input id="surname" name="surname" required type="text" />
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="step2">
                        <div class="step-title">
                            <span class="number">2</span>
                            <span>Je renseigne les informations de mon entreprise :</span>
                        </div>
                        <div class="fields">
                            <label for="company-name">
                                Nom de l'entreprise*
                                <div class="btn btn-secondary">
                                    <input required type="text" name="company-name" id="company-name" />
                                </div>
                            </label>
                            <label for="siret">
                                Numéro SIRET*
                                <div class="btn btn-secondary">
                                    <input
                                        required
                                        name="siret"
                                        id="siret"
                                        type="number"
                                        onKeyPress="if(this.value.length==14) return false"
                                    />
                                </div>
                            </label>
                            <label for="category">
                                Quelle est la catégorie associé à votre activité ?*
                                <div class="btn btn-secondary">
                                    <input required type="text" id="category" name="category" />
                                </div>
                            </label>
                            <label for="address">
                                Adresse postale*
                                <div class="btn btn-secondary">
                                    <input required type="text" name="address" id="address" />
                                </div>
                            </label>
                            <label for="postal-code">
                                Code postal*
                                <div class="btn btn-secondary">
                                    <input
                                        required
                                        name="postal-code"
                                        id="postal-code"
                                        onKeyPress="if(this.value.length==5) return false"
                                        type="number"
                                    />
                                </div>
                            </label>
                            <label for="phone">
                                Numéro de téléphone*
                                <div class="btn btn-secondary">
                                    <input
                                        required
                                        type="tel"
                                        name="phone"
                                        onKeyPress="if(this.value.length==10) return false"
                                        id="phone"
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary">
                        Valider les informations
                    </button>
                </form>
            </main>

            <Footer />
            <ScrollTop/>
        </Fragment>
    }
}


export default ProfilePro;
