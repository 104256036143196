import React, { Component } from "react"
import Navbar from "../navbar/Navbar"
import DefaultMeta from "../seo/DefaultMeta"
import '../../pages/search/search.css'
import './page.css'
import AdSkeleton from "../skeleton/AdSkeleton";
import { Paginator } from "primereact/paginator"
import Card from "../card/Card"
import '../../pages/item-details/item.css'
import { Rating } from "primereact/rating"

class Page extends Component {
    constructor(props) {
        super(props)
        this.pageSize = 8;
        this.state = {
            title: null,
            address: null,
            position: null,
            subcategory: null,
            category: null,
            sortFilter: "creationDateDesc",
            categories: [],
            first: 0,
            page: 0,
            totalRecords: 0,
            elements: [],
            loading: false,
            value: '1',
            valeur: '1',
            val: 3,
            showOption:false


        };

        this.onPage = this.onPage.bind(this);
    }
    elemnt = [
        {
            slug: 'adf',
            images: [
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' }
            ],
            address: {
                city: 'maroua'
            },
            title: 'xcv',
            createdOn: new Date('12-01-2020')
        },
        {
            slug: 'ravii',
            images: [
                { url: 'https://picsum.photos/500/351' },
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' }
            ],
            address: {
                city: 'maroua'
            },
            title: 'rcv',
            createdOn: new Date('12-01-2010')
        },
        {
            slug: 'adf',
            images: [
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' }
            ],
            address: {
                city: 'maroua'
            },
            title: 'tcv',
            createdOn: new Date('12-01-2023')
        }
        , {
            slug: 'adf',
            images: [
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' }
            ],
            address: {
                city: 'maroua'
            },
            title: 'xrv',
            createdOn: new Date('12-01-2003')
        },
        {
            slug: 'adf',
            images: [
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' }
            ],
            address: {
                city: 'maroua'
            },
            title: 'gcv',
            createdOn: new Date('12-01-2004')
        },
        {
            slug: 'adf',
            images: [
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' }
            ],
            address: {
                city: 'maroua'
            },
            title: 'hcv',
            createdOn: new Date('12-01-2005')
        },
        {
            slug: 'adf',
            images: [
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' }
            ],
            address: {
                city: 'maroua'
            },
            title: 'jcv',
            createdOn: new Date('12-01-2011')
        },
        {
            slug: 'adf',
            images: [
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' },
                { url: 'https://picsum.photos/500/350' }
            ],
            address: {
                city: 'maroua'
            },
            title: 'bcv',
            createdOn: new Date('11-01-2010')
        }
    ]

    componentDidMount() {
        this.setState({ loading: true });
        let page = new URLSearchParams(this.props.location.search).get("page");
        let p = this.state.page;
        if (page != null && page > 0) {
            this.setState({
                first: (page - 1) * this.pageSize,
                page: (page - 1),
            });
            p = page - 1;
        }
        const params = new URLSearchParams({
            start: p,
            size: this.pageSize,
            sortFilter: this.state.sortFilter
        });
        this.setState({
            elements: this.elemnt,
            loading: false,
            totalRecords: this.elemnt.length,
        })
    }

    onPage(event) {
        let start = event.first;
        let page = event.page;
        this.setState({ loading: true });
        const params = new URLSearchParams({
            start: page,
            size: this.pageSize,
            sortFilter: this.state.sortFilter
        });
        this.itemService.getPageParams(params)
            .then(response => {
                this.setState({
                    first: start,
                    elements: this.elemnt,
                    loading: false,
                    totalRecords: this.elemnt.length,
                })

                this.props.history.push({
                    pathname: '/',
                    search: "?" + new URLSearchParams({ page: page + 1 }).toString()
                })
            })
            .catch(error => {
                this.setState({ loading: false });
            });
    }


    render() {
        const skeletonList = [];
        for (let i = 0; i < this.pageSize; i++) {
            skeletonList.push(<AdSkeleton key={i} />);
        }
        const paginatorTemplate = {
            layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
            'CurrentPageReport': (options) => {
                return (
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {options.first} - {options.last} sur {options.totalRecords}
                    </span>
                )
            }
        }
        const ban = 'https://picsum.photos/1500/1350'
        const logo = 'https://picsum.photos/500/350'

        return (
            <>
                <DefaultMeta />
                <Navbar />

                <main className="container pro search ">

                    <div className="par__banniere">

                        <div className="par__banniere__h">
                            <div className="par__banniere__h_logo">
                                <img src={logo} />
                                <div>
                                    <h3>Ha shopping</h3>
                                    <div id="star" className="pavis-responsive">
                                        <Rating value={this.state.val} onChange={(e) => this.setState({ val: e.target.value })} cancel={false} stars={5} style={{ color: ' #FFD700' }} />

                                    </div>
                                    <p>Suivi par 3 membres.</p>
                                </div>
                            </div>
                            <div className="par__banniere_btn">
                                <button className="par__follow">suivre</button>
                                <button className="par__option"
                                onClick={()=>this.setState({
                                    showOption:true
                                })}
                                >
                                    <i className="pi pi-ellipsis-v"></i>
                                </button>
{                              
this.state.showOption?
<div className="par__plus">
                                    <div className="par__plus__btn">
                                        <button
                                        onClick={()=>this.setState({showOption:false})}><i className="pi pi-times"></i></button>
                                    </div>

                                    <h3>Plus d'options</h3>

                                    <div className="par__plus__option">
                                        <div className="d-flex row align-items-center">
                                            <i className="pi pi-flag mr-2"></i>
                                            <p>Signaler cet utilisateur</p>
                                        </div>
                                        <div className="d-flex row align-items-center">
                                            <i className="pi pi-share-alt mr-2"></i>
                                            <p>Partager</p>
                                        </div>
                                    </div>
                                </div>:<div></div>}
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className="par__banniere__tx">
                            <i className="pi pi-mobile"></i>
                            <p>Numéro de téléphone vérifié
                                Membre depuis avril 2020</p>


                        </div>
                        <div className="par__banniere__b">
                            <div className="par__banniere__b_l ">
                                <div className="d-flex row align-items-center">
                                    <i className="pi pi-bookmark"></i>
                                    <p>Membre depuis avril 2020</p>
                                </div>
                                <div className="d-flex row align-items-center">
                                    <i className="pi pi-clock"></i>
                                    <p>
                                        Répond généralement en une journée
                                    </p>
                                </div>
                                <div className="d-flex row align-items-center">
                                    <i className="pi pi-map-marker"></i>
                                    <p>Hauts-de-Seine</p>
                                </div>
                            </div>
                            <div id="star" className="pavis">
                                <Rating value={this.state.val} onChange={(e) => this.setState({ val: e.target.value })} cancel={false} stars={5} style={{ color: ' #FFD700' }} />
                                <p>16 avis</p>
                            </div>
                        </div>
                    </div>




                    <section id="annonces" class="result">
                        <div class="title">
                            <h2> annonce(s)</h2>
                            <hr />
                            <div class="filter-input btn btn-secondary17"
                                style={{ boxShadow: "inset 0 0 0 2px #ed6d5f" }}>
                                <select name="filter">
                                    <option value="creationDateDesc">Plus récentes</option>
                                    <option value="creationDateAsc">Plus anciennes</option>
                                    <option value="priceAsc">Prix croissant</option>
                                    <option value="priceDec">Prix décroissant</option>
                                    <option value="distanceAsc">Plus proche</option>
                                    <option value="distanceDesc">Plus loin</option>
                                </select>
                            </div>
                        </div>
                        <div className="cards row row-cols-md-4 row-cols-1">
                            {this.state.loading ?
                                skeletonList :
                                this.state.elements?.map((item, index) => <Card key={index} item={item} />)
                            }
                        </div>


                        <Paginator template={paginatorTemplate} first={this.state.first} rows={this.pageSize}
                            totalRecords={this.state.totalRecords} onPageChange={this.onPage}
                            className="justify-content-start my-3" />
                    </section>


                </main>
            </>
        )
    }
}

export default Page