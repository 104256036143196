import React, { Component, Fragment } from 'react';

import Footer from '../../components/footer/Footer';
import { Link } from 'react-router-dom'
import "./place-ad.css"
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { CascadeSelect } from 'primereact/cascadeselect';
import ScrollTop from '../../components/scroll-top/ScrollTop';
import Navbar from '../../components/navbar/Navbar';
import CategoryService from "../../services/CategoryService";
import ItemService from "../../services/ItemService";
import * as Yup from 'yup';
import { I18n } from "react-redux-i18n";
import { Formik } from 'formik';
import { address, getAddressObject, getFormErrorMessage, getPosition, idData } from "../../helpers/utils";
import { Constant } from "../../helpers/const";
import { InputTextarea } from 'primereact/inputtextarea';
import { getFormElement, initForm } from "../../components/form/FormElements";
import { Button } from 'primereact/button';
import { Toast } from "primereact/toast";
import DefaultMeta from "../../components/seo/DefaultMeta";
import { Helmet } from "react-helmet";

class PlaceEd extends Component {

    emptyElement = {
        title: '',
        status: '',
        description: '',
        price: {
            priceCurrencyEnum: 'XAF',
            etPrice: 0,
        },
        address,
        subcategory: idData,
        position: '',
        details: '',
        images: [],
        elements: []
    };

    constructor(props) {
        super(props);
        this.slug = this.props.match.params.slug;
        this.state = {
            images: [ ],
            loading: false,
            formSchema: [],
            similarItems: [],
            item: null,
            details: [],
        };

        this.imageRef = React.createRef();

        this.formikProps = {
            initialValues: this.emptyElement,
            validationSchema: Yup.object().shape({
                title: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                description: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                subcategory: Yup.object().shape({
                    label: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
                }),
                status: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                price: Yup.object().shape({
                    etPrice: Yup.number().required(I18n.t('FIELD_IS_REQUIRED')).nullable().min(1, I18n.t('FIELD_IS_REQUIRED'))
                }),
                address: Yup.object().shape({
                    label: Yup.string().required(I18n.t('ADDRESS_IS_REQUIRED'))
                }),
            }
            )
        };

        this
            .elementService = new ItemService();
        this
            .categoryService = new CategoryService();
        this
            .handleMapAddressChange = this.handleMapAddressChange.bind(this);
        this
            .handleCategoryChange = this.handleCategoryChange.bind(this);
        this
            .saveElement = this.saveElement.bind(this);
        this
            .handleFile = this.handleFile.bind(this);
            }
    Copier
    handleFileImage(images, props) {
      // Vérifier si images a une valeur et les traiter
      if (images && Array.isArray(images)) {
        images.forEach((image) => {
          if (!this.state.images.some((existingImage) => existingImage.url === image.url)) {
            let updatedImages = [...this.state.images]
            updatedImages.push({ url: image.url })
            props.setFieldValue('images', updatedImages)
            this.setState({ images: updatedImages })
           
          }console.log('le state',this.state.item)
      
          const div = document.createElement("div")
          div.classList.add("col-lg-3")
          div.classList.add("photo")
          const img = document.createElement("div")
          img.classList.add("img-container")
          img.innerHTML = `<img src="${image.url}">`
          div.appendChild(img)
          document.getElementById("global").appendChild(div)
    
          img.addEventListener("click", () => {
            let updatedImages = [...this.state.images]
            updatedImages = updatedImages.filter((existingImage) => existingImage.url !== image.url)
            props.setFieldValue('images', updatedImages)
            this.setState({ images: updatedImages })
            div.remove()
          })
        })
      }
    }
    async componentDidMount() {
        await this.elementService.findBySlug(this.slug).then(
            response => {
                let item = response.data.content
              
                this.setState({
                    item: item,
                    details: JSON.parse(response.data.content.details)
                })
             
            }
        );

        const {props}=this.props
        this.loadCategories();
        this.emptyElement.title = this.state.item?.title
        this.emptyElement.status = this.state.item?.status
        this.emptyElement.address = this.state.item?.address
        this.emptyElement.description = this.state.item?.description
        this.emptyElement.price.etPrice = this.state.item?.price.etPrice
        this.emptyElement.price.priceCurrencyEnum = this.state.item?.price.priceCurrencyEnum
        this.emptyElement.subcategory = this.state.item?.subcategory
        this.emptyElement.position = this.state.item?.position
        this.emptyElement.details = this.state.item?.details
        this.emptyElement.images = this.state.item?.images
        this.emptyElement.elements = this.state.item?.elements
        this.setState({ images: this.state.item?.images })
           
    }

    loadCategories() {
        this.categoryService.getAllWithSubCategoriesPresent().then(
            response => {
                let categories = []
                for (let i = 0; i < response.data.content.length; i++) {
                    let categoryData = response.data.content[i];
                    let subcategoriesData = categoryData.subcategories;
                    let subcategories = []
                    for (let i = 0; i < subcategoriesData.length; i++) {
                        let subcategoryData = subcategoriesData[i];
                        subcategories.push({
                            label: subcategoryData.nameFr,
                            id: subcategoryData.identifier,
                            catlabel: categoryData.nameFr,
                            catid: categoryData.identifier,
                            minImages: subcategoryData.minImages,
                            maxImages: subcategoryData.maxImages,
                            elements: subcategoryData.elements,
                            statusRequired: subcategoryData.statusRequired,
                            priceRequired: subcategoryData.priceRequired
                        })
                    }
                    categories.push({ label: categoryData.nameFr, id: categoryData.identifier, subcategories })
                }
                this.setState({ categories })
            }
        );
    }

    handleMapAddressChange = (props, initialAddress) => {
        if (initialAddress) {
            let placeId = initialAddress["value"]["place_id"];
            geocodeByPlaceId(placeId)
                .then(results => {
                    if (results[0]) {
                        let address = getAddressObject(results[0].address_components);
                        address.label = initialAddress.label
                        address.value.place_id = initialAddress.value.place_id
                        let position = getPosition(results[0].geometry.location);
                        props.setFieldValue('address', address)
                        props.setFieldValue('position', position)
                    }
                })
                .catch(error => console.error("erreur found " + error));
        }
    }

  
    handleCategoryChange = (props, subcategory) => {
        //find category
        let category = { label: subcategory?.label, id: subcategory?.id }
        console.log('categ',category)
        props.setFieldValue('category', category)
        props.setFieldValue('subcategory', subcategory)
        props.setFieldValue('statusRequired', subcategory?.statusRequired === true)
        props.setFieldValue('priceRequired', subcategory?.priceRequired === true)
        if (subcategory.elements) {
            this.setState({ formSchema: subcategory.elements })
        }
    
        this.formikProps.validationSchema = this.formikProps.validationSchema.shape({
            status: Yup.string().when('statusRequired', {
                is: false,
                then: Yup.string().notRequired()
            }),
            price: Yup.object().when('priceRequired', {
                is: false,
                then: Yup.object().shape({
                    etPrice: Yup.number().notRequired()
                })
            }),
            images: Yup.array()
                .min(subcategory.minImages, I18n.t('MINIMUM_PHOTOS_ERROR', { count: subcategory.minImages }))
                .max(subcategory.maxImages, I18n.t('MAXIMUM_PHOTOS_ERROR', { count: subcategory.maxImages }))
        })
        if (subcategory.elements) {
            let values = initForm(subcategory.elements, this.formikProps.initialValues, this.formikProps.validationSchema)
            let initialFormData = values.initialFormData
            let validationSchema = values.validationSchema
            this.formikProps.initialValues = initialFormData
            this.formikProps.validationSchema = validationSchema
        }
    }
    saveElement = (element, { resetForm }) => {
        this.setState({ loading: true });
        const data = new FormData();
        let files = this.state.images;
        for (let i = 0; i < files.length; i++) {
            data.append("images", files[i], files[i].name);
        }
        let copyElement = { ...element };
        copyElement.details = JSON.stringify(element.details)
        data.append("data", JSON.stringify(copyElement));
        console.log('data add elements  ', element)
        console.log('data add  ', data)
        this.elementService.add(data)
            .then((response) => {
                this.setState({
                    element: this.emptyElement,
                    images: [],
                    loading: false
                });
                resetForm();
                //this.imageRef.current.load()
                this.toast.show({
                    severity: 'success',
                    summary: I18n.t('ADD_AD'),
                    detail: I18n.t('ADD_AD_SUCCESS'),
                    life: 6000
                });
            })
            .catch(error => {
                this.toast.show({
                    severity: 'error',
                    summary: I18n.t('ADD_AD'),
                    detail: I18n.t('ADD_AD_ERROR'),
                    life: 6000
                });
                this.setState({ loading: false });
            });
    }

    handleFile(event, images, props) {
        // Vérifier si event a une valeur et traiter le fichier correspondant
        if (event) {
          const file = event.target.files[0]
          const imageURL = URL.createObjectURL(file)
      
          if (!this.state.images.some((image) => image.url === imageURL)) {
            let updatedImages = [...this.state.images]
            updatedImages.push({ url: imageURL })
            props.setFieldValue('images', updatedImages)
            this.setState({ images: updatedImages })
          }
      
          const div = document.createElement("div")
          div.classList.add("col-lg-3")
          div.classList.add("photo")
          const img = document.createElement("div")
          img.classList.add("img-container")
          img.innerHTML = `<img src="${imageURL}">`
          div.appendChild(img)
          document.getElementById("global").appendChild(div)
      
          img.addEventListener("click", () => {
            let updatedImages = [...this.state.images]
            updatedImages = updatedImages.filter((image) => image.url !== imageURL)
            props.setFieldValue('images', updatedImages)
            this.setState({ images: updatedImages })
            URL.revokeObjectURL(imageURL)
            div.remove()
          })
        }
      
        // Vérifier si images a une valeur et les traiter
        if (images && Array.isArray(images)) {
          images.forEach((image) => {
            if (!this.state.images.some((existingImage) => existingImage.url === image.url)) {
              let updatedImages = [...this.state.images]
              updatedImages.push({ url: image.url })
              props.setFieldValue('images', updatedImages)
              this.setState({ images: updatedImages })
            }
      
            const div = document.createElement("div")
            div.classList.add("col-lg-3")
            div.classList.add("photo")
            const img = document.createElement("div")
            img.classList.add("img-container")
            img.innerHTML = `<img src="${image.url}">`
            div.appendChild(img)
            document.getElementById("global").appendChild(div)
      
            img.addEventListener("click", () => {
              let updatedImages = [...this.state.images]
              updatedImages = updatedImages.filter((existingImage) => existingImage.url !== image.url)
              props.setFieldValue('images', updatedImages)
              this.setState({ images: updatedImages })
              div.remove()
            })
          })
        }
      }
    render() {
        {console.log('le state',this.state.item)}
        return <Fragment>
            <DefaultMeta />
            <Helmet>
                <title>Déposer une petite annonce sur Trouvare | Trouvare</title>
                <meta name="description"
                    content="Envie de vendre un bien personnel ou proposer un service ? Déposez votre annonce gratuitement sur trouvare, site spécialisé dans les petites annonces !" />
                <meta property="og:url" content="https://trouvare.com/place-ad" />
                <link rel="canonical" href="https://trouvare.com/place-ad" />
            </Helmet>
            <Navbar />
            <main className="container">
                <Toast ref={(el) => this.toast = el} />
                <section className="navigation">
                    <Link to={`/items/${this.props.match.params.slug}`} className="back"> {"<"} Retour</Link>
                </section>

                <Formik
                    enableReinitialize
                    initialValues={this.formikProps.initialValues}
                    validationSchema={this.formikProps.validationSchema}
                    onSubmit={this.saveElement}
                >
                    {props => {
                        return <form onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                            onSubmit={props.handleSubmit} className="submit">

                            <section className="fields">
                                <div className="title-section">
                                    <h1>Modifier une annonce</h1>
                                    <hr />
                                </div>

                                <div className="submit col-10">

                                    <div className="__submit_item">
                                        <div className="title">
                                            <label htmlFor="title">Quel est le titre de l'annonce ?<b>*</b></label>
                                            <div className="btn btn-secondary">
                                                {/* {console.log('autre',JSON.parse(this.state.item?.title))} */}
                                                <input
                                                    name="title"
                                                    value={props.values.title}
                                                    onChange={props.handleChange}
                                                    id="title"
                                                    className="inputs"
                                                    type="text"
                                                    placeholder="Titre de l'annonce"
                                                />
                                            </div>
                                            {getFormErrorMessage(props, 'title')}
                                        </div>
                                        {this.state.item?.subcategoryData.statusRequired && <div className="field-2">
                                            <label htmlFor="field-2">Quel est l'état du bien ?<b>*</b></label>
                                            <div className="btn btn-secondary">
                                                <select
                                                    className="inputs"
                                                    name="status"
                                                    value={props.values.status}
                                                    onChange={props.handleChange}
                                                    id="status">
                                                    <option value>Choisir l'état</option>
                                                    <option value="NEW">{I18n.t("NEW")}</option>
                                                    <option value="LIKE_NEW">{I18n.t("LIKE_NEW")}</option>
                                                    <option value="VERY_GOOD_STATE">{I18n.t("VERY_GOOD_STATE")}</option>
                                                    <option value="GOOD_CONDITION">{I18n.t("GOOD_CONDITION")}</option>
                                                    <option value="AS_IS">{I18n.t("AS_IS")}</option>
                                                </select>
                                            </div>
                                            {getFormErrorMessage(props, 'status')}
                                        </div>}

                                        {this.state.item?.subcategoryData.statusRequired && <div className="price">
                                            <label htmlFor="price">Quel est le prix ?<b>*</b> <sub>(Franc
                                                CFA)</sub></label>
                                            <div className="unit-input btn btn-secondary">
                                                <input
                                                    min="0"
                                                    className="inputs"
                                                    type="number"
                                                    name="price"
                                                    placeholder="Prix"
                                                    value={props.values.price.etPrice}
                                                    onChange={(event) => props.setFieldValue('price.etPrice', event.target.value)}
                                                    id="price" />
                                                <b className="unit">FCFA</b>
                                            </div>
                                            {getFormErrorMessage(props, 'price', 'etPrice')}
                                        </div>}

                                        <div className="categories __categorie_edit_gap">
                                            <label htmlFor="categories">Quel est la catégorie ?<b>*</b></label>
                                            <CascadeSelect
                                                options={this.state.categories}
                                                name="subcategory"
                                                value={props.values.subcategory.label}
                                                id="subcategory"
                                               
                                                onChange={(event) => this.handleCategoryChange(props, event.value)}
                                                optionLabel="label"
                                                optionGroupLabel="label" optionGroupChildren={['subcategories']}
                                                className={props.values.subcategoryIdentifier ? "p-cascadeselectRed" : "r"}
                                                style={{ minWidth: '14rem' }} placeholder={"Catégories"} />
                                            {getFormErrorMessage(props, 'subcategory', 'label')}
                                        </div>

                                        <div className="categories uniqueclass __categorie_edit_gap">
                                            <label htmlFor="address">Où se trouve le bien ?<b>*</b></label>
                                            <GooglePlacesAutocomplete
                                                name="address"
                                                apiKey={Constant.GMAPS_KEY}
                                                apiOptions={{ language: 'fr', region: 'fr' }}
                                                autocompletionRequest={{
                                                    componentRestrictions: {
                                                        country: ['cm', 'fr', 'us'],
                                                    }
                                                }}
                                                selectProps={{
                                                    onChange: (data) => {
                                                        this.handleMapAddressChange(props, data)
                                                    },
                                                    value: props.values.address,
                                                    placeholder: I18n.t('ENTER_CITY_OR_NEIGHBORHOOD'),
                                                    styles: {
                                                        input: (provided) => ({
                                                            ...provided,
                                                            color: "#222222",
                                                        }),
                                                        option: (provided) => ({
                                                            ...provided,
                                                            color: "#222222",
                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            color: "#222222",
                                                        }),
                                                    },
                                                }}
                                            />
                                            {getFormErrorMessage(props, 'address', 'label')}
                                        </div>

                                        <div className="categories __categorie_edit_gap">
                                            <label htmlFor="description">Description de l'annonce<b>*</b></label>
                                            <InputTextarea
                                                onKeyDown={(event) => event.keyCode === 13 && event.stopPropagation()}
                                                name="description"
                                                value={props.values.description}
                                                onChange={props.handleChange}
                                                id="description"
                                                className="inputs btn btn-secondary __te_rows_"
                                                placeholder="Description"
                                                autoResize />
                                            {getFormErrorMessage(props, 'description')}
                                        </div>
                                    </div>

                                    <div className="__submit_item">
                                        {Object.keys(this.state.formSchema).map((key, ind) => (
                                            <div key={key}>
                                                {getFormElement(props, this.state.formSchema[key])}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <span className="required">
                                    Les champs marqués d'un <b>*</b> sont obligatoires.</span
                                >
                            </section>

                            <section ref={this.imageRef} className="photos">
                                <div className="title-section">
                                    <h1>Ajouter des photos</h1>
                                    <hr />
                                </div>
                                {getFormErrorMessage(props, 'images')}
                                <div className="row row-cols-md-4 row-cols-1" id="global">
                                    <div className="col-lg-3 photo" id="photo">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="6rem"
                                            height="6rem"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            <path
                                                d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"
                                            />
                                            <circle cx="12" cy="13" r="4" />
                                        </svg>
                                        <label htmlFor="photo">Ajouter une photo</label>
                                        <input
                                            type="file"
                                            name="photo"
                                            id="photo"
                                            onChange={(event) => this.handleFile(event,props.values.images, props)}
                                            accept="image/*" />
                                    </div>
                                </div>
                            </section>
                            <Button loading={this.state.loading} type="submit" label="Modifier l'annonce"
                                icon="pi pi-upload" iconPos="right" />
                            {props.children}
                        </form>
                    }}
                </Formik>

            </main>

            <Footer />
            <ScrollTop />
        </Fragment>;
    }
}


export default PlaceEd;
