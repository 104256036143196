import React, { Component } from 'react';


class ScrollTop extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.onscroll = () => {
            if (window.pageYOffset >= 200) {
                document.getElementById("scroll-top").classList.remove("hidden")
            } else {
                document.getElementById("scroll-top").classList.add("hidden")
            }
        }
    }

    render() {
        return (
            <>
                <div onClick={() => window.scroll(0, 0)} id="scroll-top" className="hidden">
                    <svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 14.25L14 2.25L26 14.25" stroke="#323232" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </div>
            </>
        );
    }
}

export default ScrollTop;
