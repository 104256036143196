import React, {Component} from 'react';

import {clearMessage} from "./actions/message";

import {history} from './helpers/history';
import AppRoute from "./AppRoute";
import {connect} from "react-redux";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fab, fas);

class App extends Component {

    constructor(props) {
        super(props);

        history.listen(() => {
            props.dispatch(clearMessage()) // clear message when changing location
        });
    }

    componentDidMount() {
        function input_colored() {
            document.querySelectorAll(".inputs").forEach((input) => {
                //console.log('input_colored',input.parentNode);

                if (input.value !== "") {
                    input.parentNode.style.color = " #ed6d5f"
                } else {
                    input.parentNode.style.color = null
                }
                input.addEventListener("input", (e) => {
                    if (input.value !== "") {
                        e.path[1].style.color = "#ed6d5f"
                    } else {
                        e.path[1].style.color = null
                    }
                })
            })
        }

        input_colored()
    }


    render() {
        return (
            <>
                <AppRoute/>
            </>
        );
    }
}


function mapStateToProps(state) {
    const {user} = state.auth;

    return {
        user
    };
}

export default connect(mapStateToProps)(App);

