import { combineReducers } from "redux";
import { i18nReducer } from "react-redux-i18n";
import auth from "./auth";
import message from "./message";
import setting from "./setting";

export default combineReducers({
  i18n: i18nReducer,
  auth,
  message,
  settings: setting
});
